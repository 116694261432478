import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { ProfileIcon } from "shared/icons"
import { FIRST_LAST, LAST_FIRST } from "./NameFormats"

const getForegroundClass = ((active) => {
  if (active === undefined) {
    return "text-red"
  } if (active) {
    return "text-dark"
  }
  return "text-gray-200"
})

const UserInfo = ({
  children = null,
  className = "",
  nameDisplayFormat = FIRST_LAST,
  onClickCapture = null,
  showContentIfInactive = false,
  user = null,
}) => {
  if (!user) {
    user = { fullName: "No User", active: undefined }
  }

  const userDisplayName = nameDisplayFormat === LAST_FIRST ? `${user.lastName}, ${user.firstName}` : user.fullName

  return (
    <div
      className={clsx("flex-grow", "flex", "items-center", getForegroundClass(user.active),
        "font-medium", className, { "cursor-pointer": !!onClickCapture })}
      onClickCapture={onClickCapture}
    >
      <ProfileIcon user={user} className="mr-3 w-8 h-8" />
      <div className="flex-grow">{userDisplayName}</div>
      { (user.active || showContentIfInactive) && children }
    </div>
  )
}

UserInfo.propTypes = {
  className: types.string,
  children: types.node,
  nameDisplayFormat: types.oneOf([FIRST_LAST, LAST_FIRST]),
  onClickCapture: types.func,
  showContentIfInactive: types.bool,
  user: types.shape({
    firstName: types.string,
    lastName: types.string,
    fullName: types.string,
    active: types.bool,
  }),
}

export default UserInfo
