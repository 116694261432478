import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { SCIM_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import { adminFacilityDepartmentShape } from "utils/propTypeShapes"
import EditDepartmentModal from "shared/modals/EditDepartmentModal"
import { updateDepartment, facilityId } from "reduxSlices/adminFacilitySlice"

const DepartmentHeader = ({ department }) => {
  const scimFeatureEnabled = useSelector(subscribedToAddOnFeature(SCIM_FEATURE_NAME))
  const id = useSelector(facilityId)
  const dispatch = useDispatch()
  const updateDepartmentFields = (dept) => dispatch(updateDepartment(dept))

  return (
    <div className="flex gap-2 items-start">
      <div className="flex flex-col grow break-all">
        <div className="text-base font-semibold">{department.name}</div>
        {
          scimFeatureEnabled
            && department.externalIdentifier
            && <div className="text-xs">{department.externalIdentifier}</div>
        }
      </div>
      <EditDepartmentModal
        facilityId={id}
        department={department}
        onDepartmentUpdated={updateDepartmentFields}
      />
    </div>
  )
}

DepartmentHeader.propTypes = {
  department: adminFacilityDepartmentShape.isRequired,
}

export default DepartmentHeader
