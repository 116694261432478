import React from "react"
import { adminFacilityDepartmentShape } from "utils/propTypeShapes"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { SCIM_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import { useSelector } from "react-redux"
import RemoveMemberDropzone from "./RemoveMemberDropzone"
import DepartmentSegmentList from "./DepartmentSegmentList"

const DepartmentMembers = ({ department }) => {
  const { id: departmentId, segments } = department
  const scimFeatureEnabled = useSelector(subscribedToAddOnFeature(SCIM_FEATURE_NAME))

  return (
    <div className="w-full">
      <span className="text-xs">Members</span>
      <div className="w-full flex items-start gap-2">
        <DepartmentSegmentList className="grow" departmentId={departmentId} segments={segments} />
        { !scimFeatureEnabled && <RemoveMemberDropzone department={department} /> }
      </div>
    </div>

  )
}

DepartmentMembers.propTypes = {
  department: adminFacilityDepartmentShape.isRequired,
}

export default DepartmentMembers
