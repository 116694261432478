import React from "react"
import { useSelector } from "react-redux"
import { formSubmissionData } from "reduxSlices/formSubmissionSlice"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import OverviewSection from "./OverviewSection"
import OverviewQuestion from "./OverviewQuestion"

const SubmissionOverview = () => {
  const { deletedQuestions, inFocusFormVersion } = useFormSubmissionFormContext()
  const { sections } = inFocusFormVersion

  const { answers } = useSelector(formSubmissionData)

  return (
    <div className="relative px-10 pt-12 pb-6">
      {
        sections.map((section, sectionIndex) => (
          <OverviewSection
            key={section.uuid}
            section={section}
            isFirstSection={sectionIndex === 0}
          />
        ))
      }
      {
        (deletedQuestions?.length !== 0) && (
          <>
            <div className="flex justify-center p-4 border-b border-lightgray-3">
              <span>The following questions were removed from the form after this submission was created.</span>
            </div>
            <ol className="px-8 py-4">
              {
                deletedQuestions?.map((question) => (
                  <OverviewQuestion
                    key={question.uuid}
                    question={question}
                    answers={answers}
                  />
                ))
              }
            </ol>
          </>
        )
      }
    </div>
  )
}

export default SubmissionOverview
