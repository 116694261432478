import React, { useState } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { store } from "store"
import * as API from "services/api"
import { useDropzone } from "react-dropzone"
import {
  formSubmissionSlug as getFormSubmissionSlug,
  setAttachments as setFormSubmissionAttachments,
} from "reduxSlices/formSubmissionSlice"
import {
  setUploadingStart,
  setUploadingComplete,
} from "reduxSlices/formSubmissionAttachmentsGroupingSlice"
import { errorToast } from "components/shared/toast"
import { convertMegabytesToBytes } from "utils/fileSizeHelpers"
import FormSubmissionAttachmentDropzoneContext from "../FormSubmissionAttachmentDropzoneContext"

// Max size for attachments is 35 MB
const MAX_ATTACHMENT_SIZE_IN_BYTES = convertMegabytesToBytes(35)

const ACCEPTED_FILE_CONTENT_TYPES = {
  "application/pdf": [],
  "application/msword": [],
  "application/vnd.apple.numbers": [],
  "application/vnd.apple.pages": [],
  "application/vnd.ms-excel": [],
  "application/vnd.ms-outlook": [],
  "application/vnd.ms-powerpoint": [],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
  "application/zip": [],
  "audio/vnd.wav": [],
  "audio/wave": [],
  "audio/wav": [],
  "audio/x-wav": [],
  "audio/x-pn-wav": [],
  "audio/mpeg": [],
  "audio/mpeg3": [],
  "audio/x-mpeg-3": [],
  "image/bmp": [],
  "image/gif": [],
  "image/jpeg": [],
  "image/png": [],
  "image/tiff": [],
  "message/rfc822": [], // .eml
  "text/csv": [],
  "video/mp4": [],
  "video/quicktime": [],
  "video/x-msvideo": [".avi"],
}

const buildErrorMessageString = (rejectedFile) => {
  const { name } = rejectedFile.file
  const nameToDisplay = name.length > 16 ? `${name.slice(0, 16)}...` : name
  const extension = name.split(".").pop()
  const errorMessageStrings = []
  rejectedFile.errors.forEach((error) => {
    if (error.code === "file-too-large") {
      errorMessageStrings.push(`${nameToDisplay} is too large`)
    } else if (error.code === "file-invalid-type") {
      errorMessageStrings.push(`${extension} is an invalid file type`)
    }
  })

  return `${errorMessageStrings.join(" and ")}.`
}

const FormSubmissionAttachmentDropzoneContextProvider = ({ children }) => {
  const [isDragOver, setIsDragOver] = useState(false)

  const formSubmissionSlug = useSelector(getFormSubmissionSlug)

  const saveAttachment = async (files) => {
    store.dispatch(setUploadingStart())

    // eslint-disable-next-line no-undef
    const formData = new FormData()

    Array.from(files).forEach((file) => { formData.append("attachments[]", file) })

    const response = await API.saveAttachments({ formSubmissionSlug, attachments: formData })
    store.dispatch(setUploadingComplete())

    if (response.ok) {
      store.dispatch(setFormSubmissionAttachments(response.data))
    } else {
      errorToast("Error saving attachments: ", response)
    }
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setIsDragOver(false)

    if (rejectedFiles.length > 0) {
      const errorMessageStrings = rejectedFiles.map((rejectedFile) => buildErrorMessageString(rejectedFile)).join(" ")

      if (acceptedFiles.length > 0) {
        errorToast(`Some file uploads failed: ${errorMessageStrings}`)
      } else {
        errorToast(`Upload failed: ${errorMessageStrings}`)
      }
    }

    if (acceptedFiles.length > 0) {
      saveAttachment(acceptedFiles)
    }
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ACCEPTED_FILE_CONTENT_TYPES,
    maxSize: MAX_ATTACHMENT_SIZE_IN_BYTES,
    onDragEnter: () => setIsDragOver(true),
    onDragLeave: () => setIsDragOver(false),
    onDrop,
    noClick: true,
  })

  const contextConsumerValue = {
    isOverAttachmentDropzone: isDragOver,
    getAttachmentDropzoneRootProps: getRootProps,
    getAttachmentDropzoneInputProps: getInputProps,
    openAttachmentFileDialog: open,
  }

  return (
    <FormSubmissionAttachmentDropzoneContext.Provider value={contextConsumerValue}>
      {children}
    </FormSubmissionAttachmentDropzoneContext.Provider>
  )
}

FormSubmissionAttachmentDropzoneContextProvider.propTypes = {
  children: types.node.isRequired,
}

export default FormSubmissionAttachmentDropzoneContextProvider
