import React from "react"
import UsersAssignedList from "./UsersAssignedList"

const DisplayOnlyMembers = () => (
  <>
    <p className="text-sm text-dark my-2">Current Department Members:</p>
    <UsersAssignedList />
    <div className="text-xs text-dark space-y-1">
      <p>Instructions:</p>
      <p>Your organization manages department memberships via the SCIM User Provisioning feature.</p>
      <p>If a user should be added to a department or removed from a department, contact your internal support.</p>
      <p>New department members are always placed into Segment A.</p>
      <p>You may move a user from one department segment to another by dragging the user&apos;s avatar to the desired segment.</p>
    </div>
  </>
)

export default DisplayOnlyMembers
