import React from "react"

const AttachmentUploadInstructions = () => (
  <div className="text-center my-16">
    <p className="my-4">
      Drag and drop files here or click the <strong>+ Upload File</strong> button above to browse.
    </p>
    <p className="my-4 text-sm">Attachment size limit: 35 MB</p>
  </div>

)

export default AttachmentUploadInstructions
