import React from "react"
import types from "prop-types"
import Sidebar from "shared/Sidebar"
import TasksDeletedFilter from "./TasksDeletedFilter"

const OptionsSidebar = ({ hiddenSidebar, showingDeletedTasks }) => (
  <Sidebar hiddenSidebar={hiddenSidebar} className="mt-20">
    <TasksDeletedFilter showingDeletedTasks={showingDeletedTasks} />
  </Sidebar>
)

OptionsSidebar.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  showingDeletedTasks: types.bool.isRequired,
}

export default OptionsSidebar
