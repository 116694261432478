import React from "react"
import types from "prop-types"
import Sidebar from "shared/Sidebar"
import AuditableFilters from "./AuditableFilters"

const OptionsSidebar = ({ hiddenSidebar }) => (
  <Sidebar hiddenSidebar={hiddenSidebar} className="pt-36">
    <AuditableFilters />
  </Sidebar>
)

OptionsSidebar.propTypes = {
  hiddenSidebar: types.bool.isRequired,
}

export default OptionsSidebar
