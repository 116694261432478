const AbstractPersistence = {
  clear({ key }) {
    this.storage.removeItem(key)
  },

  getValue({ key }) {
    const value = this.storage.getItem(key)
    if (!value) return {}
    return JSON.parse(value)
  },

  isPropertySet({ key, property }) {
    return Object.prototype.hasOwnProperty.call(this.getValue({ key }), property)
  },

  lookup({ key, property }) {
    return this.getValue({ key })[property]
  },

  persist({ key, property, value }) {
    if (!key || !property) return undefined

    const stored = this.getValue({ key })
    stored[property] = value

    try {
      return this.storage.setItem(key, JSON.stringify(stored))
    } catch (error) {
      console.error(error)
      return undefined
    }
  },
}

export default AbstractPersistence
