import React from "react"
import types from "prop-types"

const TaskDepartmentLabel = ({ labelText }) => (
  <span className="flex-grow mr-3">{labelText}</span>
)

TaskDepartmentLabel.propTypes = {
  labelText: types.string.isRequired,
}

export default TaskDepartmentLabel
