import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { ACTIVITY_HUB_SURVEYS_SURVEY_CONFIGURATIONS_ROOT } from "utils/routeHelpers"

const SurveyConfigurationHeader = ({ className = "" }) => {
  const { surveyConfiguration } = useSurveyConfigurationContext()
  const { name, description } = surveyConfiguration

  return (
    <div className={clsx("admin-header", className)}>
      <a className="header-link" href={ACTIVITY_HUB_SURVEYS_SURVEY_CONFIGURATIONS_ROOT}>Survey Configurations</a>
      <div className="header-title">{name}</div>
      <div className="header-description">{description}</div>
    </div>
  )
}

SurveyConfigurationHeader.propTypes = {
  className: types.string,
}

export default SurveyConfigurationHeader
