import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { MultilineTextInput } from "shared/inputs"

const TaskDescription = ({ className }) => {
  const { taskDescription, updateTaskDescription } = useTaskFormContext()

  return (
    <div className={className}>
      <span className="block mb-1 font-semibold">Notes (Optional)</span>
      <MultilineTextInput
        className="bordered-base-input text-sm"
        onChange={(value) => updateTaskDescription(value.trimStart())}
        placeholder="Add details or notes ..."
        value={taskDescription}
      />
    </div>
  )
}

TaskDescription.defaultProps = {
  className: "",
}

TaskDescription.propTypes = {
  className: types.string,
}

export default TaskDescription
