import React, { useEffect, useMemo, useState } from "react"
import types from "prop-types"
import LazyLoad, { forceCheck } from "react-lazyload"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import { ChevronDown, ChevronUp } from "shared/icons"
import StrikeAround from "shared/StrikeAround"
import { Provider, useSelector } from "react-redux"
import { store } from "store"
import activeBeforeInactive from "utils/activeBeforeInactive"
import { LAST_FIRST } from "shared/NameFormats"
import UserInfo from "shared/UserInfo"
import NameFilter from "shared/NameFilter"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { SCIM_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import UserActiveStatusFilter, {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
} from "./UserActiveStatusFilter"
import UserMenu from "./UserMenu"
import EmptyUsers from "./EmptyUsers"
import EmptyFilteredUsers from "./EmptyFilteredUsers"
import CreateUserButtons from "./CreateUserButtons"
import withReduxProviderHOC from "../../withReduxProvider"

const goToUserShow = (user) => () => {
  window.location.pathname = `/admin/users/${user.id}`
}

const checkIfActive = (user, activeStatusFilter) => {
  if (activeStatusFilter === ACTIVE_STATUS) {
    return user.active
  }

  if (activeStatusFilter === INACTIVE_STATUS) {
    return !user.active
  }

  return true
}

const filterBySearch = (user, nameFilter) => {
  const matcher = new RegExp(nameFilter, "i")

  if (nameFilter) {
    return matcher.test(user.fullName)
  }

  return true
}

const UserList = ({ users }) => {
  const scimFeatureEnabled = useSelector(subscribedToAddOnFeature(SCIM_FEATURE_NAME))
  const [nameFilter, setNameFilter] = useState("")
  const [activeStatusFilter, setActiveStatusFilter] = useState(ACTIVE_STATUS)
  const [isExpanded, toggleAccordion] = useAccordion(true)

  const filteredUsers = useMemo(() => {
    const filtered = users.filter((user) => (
      checkIfActive(user, activeStatusFilter) && filterBySearch(user, nameFilter)
    ))

    return filtered.sort(activeBeforeInactive)
  }, [activeStatusFilter, nameFilter, users])

  useEffect(() => {
    forceCheck()
  }, [filteredUsers])

  if (users.length === 0) return <EmptyUsers />

  const Chevron = isExpanded ? ChevronDown : ChevronUp

  return (
    <Provider store={store}>
      <CustomHeaderAccordion
        isExpanded={isExpanded}
        header={(
          <div className="flex-center">
            <StrikeAround className="text-2xl font-semibold pt-6 pb-3 flex-grow">
              Users
            </StrikeAround>
            <span className="pt-6 pb-3">
              <Chevron
                className="h-8 w-8 p-2 ml-2 cursor-pointer"
                onClick={toggleAccordion}
              />
            </span>
          </div>
        )}
        content={(
          <>
            { scimFeatureEnabled
              && (
                <div className="text-sm text-dark text-center space-y-1 mb-4 mx-16">
                  <p>Your organization manages users via the SCIM User Provisioning feature.</p>
                  <p>If you need to add a NEW user or modify an existing user&apos;s email, name, or active status, please contact your internal support.</p>
                </div>
              ) }
            <div className="flex items-center justify-center mb-4 gap-x-4">
              { !scimFeatureEnabled && <CreateUserButtons /> }
              <UserActiveStatusFilter
                onChange={setActiveStatusFilter}
                value={activeStatusFilter}
              />
              <NameFilter
                className={scimFeatureEnabled ? "w-3/4" : "w-1/4"}
                onChange={setNameFilter}
                value={nameFilter}
              />
            </div>
            <div className="min-h-screen">
              {
                filteredUsers.length > 0 ? filteredUsers.map((user) => (
                  <LazyLoad key={`user-row${user.id}`} height={58} offset={100} once resize>
                    <div className="list-row py-3 relative">
                      <UserInfo
                        user={user}
                        nameDisplayFormat={LAST_FIRST}
                        showContentIfInactive
                        onClickCapture={goToUserShow(user)}
                      >
                        { user.role }
                      </UserInfo>
                      <UserMenu user={user} />
                    </div>
                  </LazyLoad>
                )) : <EmptyFilteredUsers />
              }
            </div>
          </>
        )}
      />
    </Provider>
  )
}

UserList.propTypes = {
  users: types.arrayOf(types.object).isRequired,
}

export default withReduxProviderHOC(UserList)
