import React from "react"
import types from "prop-types"
import TaskNav from "./TaskNav"

const ActivitiesSidebar = ({ className }) => (
  <div className={className}>
    <TaskNav />
  </div>
)

ActivitiesSidebar.defaultProps = {
  className: "",
}

ActivitiesSidebar.propTypes = {
  className: types.string,
}

export default ActivitiesSidebar
