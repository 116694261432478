import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import {
  ACTIVITY_HUB_DASHBOARD_PATH,
  ACTIVITY_HUB_SUBMISSIONS_PATH,
  ACTIVITY_HUB_TASKS_PATH,
  ACTIVITY_HUB_FORMS_PATH,
  ACTIVITY_HUB_REPORTS_PATH,
  ACTIVITY_HUB_SURVEYS_PATH,
  ADMIN_PATH,
} from "utils/routeHelpers"

const DEFAULT_TABS = [{
  title: "Home",
  icon: "home",
  path: ACTIVITY_HUB_DASHBOARD_PATH,
}, {
  title: "Submissions",
  icon: "submissions",
  path: ACTIVITY_HUB_SUBMISSIONS_PATH,
}, {
  title: "Tasks",
  icon: "tasks",
  path: ACTIVITY_HUB_TASKS_PATH,
}, {
  title: "Forms",
  icon: "forms",
  path: ACTIVITY_HUB_FORMS_PATH,
}, {
  title: "Reports",
  icon: "reports",
  path: ACTIVITY_HUB_REPORTS_PATH,
},
]

const ADDITIONAL_TABS = {
  surveys: {
    title: "Surveys",
    icon: "surveys",
    path: ACTIVITY_HUB_SURVEYS_PATH,
  },
  admin: {
    title: "Admin",
    icon: "admin",
    path: ADMIN_PATH,
  },
}

const GlobalHeaderTabs = ({ currentTab = "", additionalTabs = [] }) => {
  const allTabs = [...DEFAULT_TABS]

  if (additionalTabs.includes("surveys")) {
    allTabs.splice(4, 0, ADDITIONAL_TABS.surveys)
  }

  if (additionalTabs.includes("admin")) {
    allTabs.push(ADDITIONAL_TABS.admin)
  }

  return (
    <div className="header-tabs">
      {
        allTabs.map(({ title, icon, path }) => (
          <a
            key={title}
            className={clsx("header-tab", currentTab === icon && "active")}
            href={path}
          >
            <SvgSpriteIcon
              iconName={icon}
              svgIdAttribute={currentTab === icon ? "active" : ""}
              className="header-icon"
            />
            <span>{ title }</span>
          </a>
        ))
      }
    </div>
  )
}

GlobalHeaderTabs.propTypes = {
  currentTab: types.string,
  additionalTabs: types.arrayOf(types.string),
}

export default GlobalHeaderTabs
