import React, { useState } from "react"
import types from "prop-types"
import withReduxProvider from "shared/withReduxProvider"
import { Wrap } from "shared/wraps"
import { ADMIN_USERS_AND_GROUPS_PATH } from "utils/routeHelpers"
import UserTabs from "./UserTabs"

const ShowUser = ({
  user: initialUser, accessOverviewDisplay, facilities, signInAudits, userWorkflows,
}) => {
  const [user, setUser] = useState(initialUser)
  const updateRole = (role) => setUser({ ...user, role })
  const updateLocked = (locked) => setUser({ ...user, locked })
  const updateOtpConfirmed = (otpConfirmed) => setUser({ ...user, otpConfirmed })
  const updateActive = (newActiveState) => setUser({ ...user, active: newActiveState })
  const removeDepartmentMembership = (departmentMembership) => {
    const updatedDepartmentMemberships = user.departmentMemberships.filter(
      (membership) => membership.id !== departmentMembership.id,
    )

    setUser({
      ...user,
      departmentMemberships: updatedDepartmentMemberships,
    })
  }
  const updateDepartmentMembershipSegment = (departmentMembership) => {
    const foundMembership = user.departmentMemberships.find((membership) => membership.id === departmentMembership.id)

    const updatedDepartmentMemberships = foundMembership
      ? user.departmentMemberships.map((membership) => {
        if (membership.id === departmentMembership.id) {
          return { ...membership, segment: departmentMembership.segment }
        }
        return membership
      })
      : user.departmentMemberships

    setUser({
      ...user,
      departmentMemberships: updatedDepartmentMemberships,
    })
  }

  const tabProps = {
    user,
    facilities,
    accessOverviewDisplay,
    setUser,
    removeDepartmentMembership,
    updateDepartmentMembershipSegment,
    updateRole,
    updateLocked,
    updateActive,
    updateOtpConfirmed,
    signInAudits,
    userWorkflows,
  }

  return (
    <Wrap>
      <div className="admin-header">
        <a className="header-link" href={ADMIN_USERS_AND_GROUPS_PATH}>Users & Groups</a>
        <h1 className="header-title">{`${user.first_name} ${user.last_name}`}</h1>
      </div>
      <UserTabs tabProps={tabProps} />
    </Wrap>
  )
}

ShowUser.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
  accessOverviewDisplay: types.arrayOf(types.object).isRequired,
  signInAudits: types.arrayOf(types.object).isRequired,
  facilities: types.arrayOf(types.object).isRequired,
  userWorkflows: types.arrayOf(types.object).isRequired,
}

export default withReduxProvider(ShowUser)
