import React from "react"
import types from "prop-types"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import GroupingAccordionHeader from "shared/headers/GroupingAccordionHeader"
import Attachments from "./Attachments"

const AttachmentGrouping = ({ groupName, attachments }) => {
  const [isExpanded, toggleAccordion] = useAccordion(true)

  if (!attachments.length) return null

  return (
    <CustomHeaderAccordion
      className="my-10"
      isExpanded={isExpanded}
      header={(
        <GroupingAccordionHeader
          numberOfSubmissions={attachments.length}
          groupName={groupName}
          isExpanded={isExpanded}
          toggleAccordion={toggleAccordion}
          className="mb-6"
        />
      )}
      content={<Attachments attachments={attachments} />}
    />
  )
}

AttachmentGrouping.propTypes = {
  groupName: types.string.isRequired,
  attachments: types.arrayOf(types.object).isRequired,
}

export default AttachmentGrouping
