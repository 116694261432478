/* eslint-disable camelcase */
import axios from "axios"
import Rails from "@rails/ujs"

const api = axios.create({
  baseURL: window.location.origin,
  headers: { Accept: "application/json" },
  params: { authenticity_token: Rails.csrfToken() },
})

const ok = (data) => {
  data.ok = true
  return data
}

const notOk = (data) => {
  data.ok = false
  return data
}

export const get = async (path, params = {}) => {
  try {
    const response = await api.get(path, { params })
    return ok(response)
  } catch (error) {
    return notOk(error.response)
  }
}
export const post = async (path, payload, params = {}) => {
  try {
    const response = await api.post(path, payload, { params })
    return ok(response)
  } catch (error) {
    return notOk(error.response)
  }
}
export const postFiles = async (path, payload, params = {}) => {
  const headers = { "Content-Type": "multipart/form-data" }
  try {
    const response = await api.post(path, payload, { params, headers })
    return ok(response)
  } catch (error) {
    return notOk(error.response)
  }
}
export const put = async (path, payload, params = {}) => {
  try {
    const response = await api.put(path, payload, { params })
    return ok(response)
  } catch (error) {
    return notOk(error.response)
  }
}
export const destroy = async (path, params = {}) => {
  try {
    const response = await api.delete(path, { params })
    return ok(response)
  } catch (error) {
    return notOk(error.response)
  }
}

export const refreshSession = async (refreshSessionPath) => post(refreshSessionPath)

export const createRegularForm = async ({ categoryId }) => post(
  "/api/v1/public-forms",
  { form: { category_id: categoryId } },
)

export const createTaskForm = async ({ categoryId }) => post(
  "/api/v1/task-forms",
  { form: { category_id: categoryId } },
)

export const createSftpRemoteServer = async ({ sftpRemoteServer }) => post(
  "/api/v1/sftp/remote-server",
  { remote_server: sftpRemoteServer },
)

export const updateSftpRemoteServer = async ({ sftpRemoteServer }) => put(
  "/api/v1/sftp/remote-server",
  { remote_server: sftpRemoteServer },
)

export const showSftpRemoteServer = async () => get(
  "/api/v1/sftp/remote-server",
)

export const testConnectionSftpRemoteServer = async () => get(
  "/api/v1/sftp/remote-server/test-connection",
)

export const createSurveyForm = async ({ categoryId }) => post(
  "/api/v1/survey-forms",
  { form: { category_id: categoryId } },
)

export const createSurveyConfiguration = async ({ surveyConfiguration }) => post(
  "/api/v1/surveys/survey-configurations",
  { survey_configuration: surveyConfiguration },
)

export const updateSurveyConfiguration = async ({ surveyConfigurationId, surveyConfiguration }) => put(
  `/api/v1/surveys/survey-configurations/${surveyConfigurationId}`,
  { survey_configuration: surveyConfiguration },
)

export const activateSurveyConfiguration = async (surveyConfigurationId) => post(
  `/api/v1/surveys/survey-configurations/${surveyConfigurationId}/activate`,
)

export const deactivateSurveyConfiguration = async (surveyConfigurationId) => post(
  `/api/v1/surveys/survey-configurations/${surveyConfigurationId}/deactivate`,
)

export const formSurveyBatches = async ({ formSlug }) => get(
  `/api/v1/surveys/forms/${formSlug}/survey-batches`,
)

export const formSurveyConfigurations = async ({ formSlug }) => get(
  `/api/v1/surveys/forms/${formSlug}/survey-configurations`,
)

export const createSurveyBatch = async ({ surveyConfigurationId }) => post(
  `api/v1/surveys/survey-configurations/${surveyConfigurationId}/survey-batches`,
)

export const createSurveyToken = async ({ surveyBatchId, surveyTokenWithContact }) => post(
  `api/v1/surveys/survey-batches/${surveyBatchId}/survey-tokens`,
  { survey_token: surveyTokenWithContact },
)

export const destroySurveyBatchContact = async ({ surveyBatchId, surveyTokenId }) => destroy(
  `api/v1/surveys/survey-batches/${surveyBatchId}/survey-tokens/${surveyTokenId}`,
)

export const updateSurveyBatch = async ({ surveyConfigurationId, surveyBatchId, surveyBatch }) => put(
  `api/v1/surveys/survey-configurations/${surveyConfigurationId}/survey-batches/${surveyBatchId}`,
  { survey_batch: surveyBatch },
)

export const getSurveyConfigurationContacts = async ({ surveyConfigurationId }) => get(
  `api/v1/surveys/survey-configurations/${surveyConfigurationId}/contact-inclusions`,
)

export const createSurveyConfigurationContact = async ({ surveyConfigurationId, contact }) => post(
  `api/v1/surveys/survey-configurations/${surveyConfigurationId}/contact-inclusions`,
  contact,
)

export const destroySurveyConfigurationContact = async ({ surveyConfigurationId, contactInclusionId }) => destroy(
  `api/v1/surveys/survey-configurations/${surveyConfigurationId}/contact-inclusions/${contactInclusionId}`,
)

export const createSurveyConfigurationContactsUpload = ({ surveyConfigurationId, formData }) => postFiles(
  `api/v1/surveys/survey-configurations/${surveyConfigurationId}/contacts-uploads`,
  formData,
)

export const surveyConfigurationContactsUpload = ({ surveyConfigurationId, contactsUploadId }) => get(
  `api/v1/surveys/survey-configurations/${surveyConfigurationId}/contacts-uploads/${contactsUploadId}`,
)

export const formSubmissionSurveyTokenPublicDetails = ({ formSubmissionSlug }) => get(
  `api/v1/surveys/form-submissions/${formSubmissionSlug}/survey-token-public-details`,
)

export const saveForm = async ({ form }) => post(
  `/api/v1/forms/${form.slug}/current-version`,
  { form },
)

export const updateFormIcon = async ({ formSlug, icon, iconColor }) => put(
  `/api/v1/forms/${formSlug}/icon`,
  {
    icon,
    icon_color: iconColor,
  },
)

export const updateFormTooltipDescription = async ({ formSlug, tooltipDescription }) => put(
  `/api/v1/forms/${formSlug}/tooltip-description`,
  { tooltip_description: tooltipDescription },
)

export const updateFormAcceptsFiles = async ({ formSlug, acceptsFiles }) => put(
  `/api/v1/forms/${formSlug}/accepts-files`,
  { accepts_submitted_files: acceptsFiles },
)

export const updateFormActive = async ({ formSlug, active }) => put(
  `/api/v1/forms/${formSlug}/active`,
  { active },
)

export const updateFormListed = async ({ formSlug, listed }) => put(
  `/api/v1/forms/${formSlug}/listed`,
  { listed },
)

export const updateFormPublicSubmissionStatus = async ({ formSlug, publicSubmissionStatus }) => put(
  `/api/v1/forms/${formSlug}/public-submission-status`,
  { public_submission_status: publicSubmissionStatus },
)

export const formQuestionHistory = async ({ formSlug }) => get(
  `/api/v1/forms/${formSlug}/question-history`,
)

export const getFormWorkflows = async ({ formSlug }) => get(
  `/api/v1/forms/${formSlug}/workflows`,
)

export const createFormWorkflow = async ({ formSlug, workflow }) => post(
  `/api/v1/forms/${formSlug}/workflows`,
  { workflow },
)

export const activateWorkflow = async ({ formSlug, workflowId }) => post(
  `/api/v1/forms/${formSlug}/workflows/${workflowId}/activate`,
)

export const deactivateWorkflow = async ({ formSlug, workflowId }) => post(
  `/api/v1/forms/${formSlug}/workflows/${workflowId}/deactivate`,
)

export const updateWorkflow = async ({ formSlug, workflowId, workflow }) => put(
  `/api/v1/forms/${formSlug}/workflows/${workflowId}`,
  { workflow },
)

export const deleteWorkflow = async ({ formSlug, workflowId }) => destroy(
  `/api/v1/forms/${formSlug}/workflows/${workflowId}`,
)

export const createFormSubmission = async ({ answers, formLoadedAt, slug }) => post(
  `/api/v1/public/forms/${slug}/form-submissions`,
  {
    form_submission: {
      answers,
      form_loaded_at: formLoadedAt,
    },
  },
)

export const createSurveyFormSubmission = async ({ answers, formLoadedAt, token }) => post(
  `/api/v1/public/surveys/${token}`,
  {
    form_submission: {
      answers,
      form_loaded_at: formLoadedAt,
    },
  },
)

export const deleteFormSubmission = async ({ slug }) => destroy(
  `/api/v1/form-submissions/${slug}`,
)

export const recoverFormSubmission = async ({ slug }) => post(
  `/api/v1/form-submissions/${slug}/recover`,
)

export const updateFormSubmissionAnswers = async ({ answers, formVersionId, slug }) => put(
  `/api/v1/form-submissions/${slug}`,
  {
    form_submission: {
      answers,
      form_version_id: formVersionId,
    },
  },
)

export const attachFormSubmissionFiles = async ({
  signedBlobId, formSubmissionSlug, uploadsToken,
}) => post(
  `/api/v1/public/form-submissions/${formSubmissionSlug}/submitted-files?uploads_token=${uploadsToken}`,
  {
    form_submission: { signed_blob_id: signedBlobId },
  },
)

export const removeFormSubmissionFile = async ({
  signedBlobId, formSubmissionSlug, uploadsToken,
}) => post(
  `/api/v1/public/form-submissions/${formSubmissionSlug}/submitted-files/destroy?uploads_token=${uploadsToken}`,
  {
    form_submission: { signed_blob_id: signedBlobId },
  },
)

export const changeFormSubmissionFormType = async ({ formSubmissionSlug, newAnswers, newFormSlug }) => put(
  `/api/v1/form-submissions/${formSubmissionSlug}/form-type`,
  {
    form_submission: {
      new_answers: newAnswers,
      new_form_slug: newFormSlug,
    },
  },
)

export const exportFormSubmissionData = async ({ slug, includeFormSubmission, includedTaskIds }) => post(
  `/api/v1/form-submissions/${slug}/export`,
  {
    include_form_submission: includeFormSubmission,
    included_task_ids: includedTaskIds,
  },
)

export const deleteFormSubmissionReport = async ({ id }) => destroy(
  `/api/v1/form-submission-reports/${id}`,
)

export const deleteSolutionLevelReport = async ({ id }) => destroy(
  `/api/v1/solution-level-reports/${id}`,
)

export const deleteSurveyReport = async ({ id }) => destroy(
  `/api/v1/surveys/survey-reports/${id}`,
)

export const updateStatuses = async ({ categorySlug, statuses, statusesToDiscard }) => put(
  `/api/v1/categories/${categorySlug}/status-list`,
  {
    status_list: {
      discardable_statuses: statusesToDiscard.map(({ id, name }) => ({ id, name })),
      submission_statuses_attributes: statuses.map(({ id, name }) => ({ id, name })),
    },
  },
)

export const closeTask = async ({ taskId }) => post(
  `/api/v1/tasks/${taskId}/close`,
)

export const reopenTask = async ({ taskId }) => post(
  `/api/v1/tasks/${taskId}/reopen`,
)

export const viewTask = async ({ taskId }) => post(
  `/api/v1/tasks/${taskId}/view`,
)

export const deleteTask = async ({ taskId }) => destroy(
  `/api/v1/tasks/${taskId}`,
)

export const recoverTask = async ({ taskId }) => post(
  `/api/v1/tasks/${taskId}/recover`,
)

export const statusList = async ({ categorySlug }) => get(
  `/api/v1/categories/${categorySlug}/status-list`,
)

export const createTask = async ({ formSubmissionSlug, model }) => post(
  `/api/v1/form-submissions/${formSubmissionSlug}/tasks`,
  model,
)

export const updateTask = async ({ formSubmissionSlug, taskData, taskId }) => put(
  `/api/v1/form-submissions/${formSubmissionSlug}/tasks/${taskId}`,
  taskData,
)

export const taskAnswer = async ({ taskId, questionUuid }) => get(
  `/api/v1/tasks/${taskId}/answers/${questionUuid}`,
)

export const updateTaskAnswer = async ({ taskId, answer }) => put(
  `/api/v1/tasks/${taskId}/answer`,
  { answer },
)

export const deleteTaskAnswer = async ({ taskId, answerData }) => destroy(
  `/api/v1/tasks/${taskId}/answer`,
  answerData,
)

export const beginAnswerEditingSession = async ({ taskId, questionUuid }) => post(
  `/api/v1/tasks/${taskId}/answers/${questionUuid}/editor`,
)

export const endAnswerEditingSession = async ({ taskId, questionUuid }) => destroy(
  `/api/v1/tasks/${taskId}/answers/${questionUuid}/editor`,
)

export const taskAnswerEditingSessions = async ({ taskId }) => get(
  `/api/v1/tasks/${taskId}/answer-editing-sessions`,
)

export const saveAttachments = async ({ formSubmissionSlug, attachments }) => postFiles(
  `/api/v1/form-submissions/${formSubmissionSlug}/attachments`,
  attachments,
)

export const deleteAttachment = async ({ formSubmissionSlug, attachmentId }) => destroy(
  `/api/v1/form-submissions/${formSubmissionSlug}/attachments/${attachmentId}`,
)

export const deleteSubmittedFile = async ({ formSubmissionSlug, attachmentId }) => destroy(
  `/api/v1/form-submissions/${formSubmissionSlug}/submitted-files/${attachmentId}`,
)

export const searchForRelatedFormSubmissionOptions = async ({ formSubmissionId, query }) => post(
  "/api/v1/related-form-submission-options/search",
  { form_submission_id: formSubmissionId, query },
)

export const createFormSubmissionRelationship = async ({ formSubmissionSlug, relatedFormSubmissionId }) => post(
  `/api/v1/form-submissions/${formSubmissionSlug}/related-form-submissions`,
  { related_form_submission_id: relatedFormSubmissionId },
)

export const deleteFormSubmissionRelationship = async ({ formSubmissionSlug, relatedFormSubmissionId }) => destroy(
  `/api/v1/form-submissions/${formSubmissionSlug}/related-form-submissions/${relatedFormSubmissionId}`,
)

export const getUsers = async (params) => get("/api/v1/users", params)

export const createUser = async ({ user }) => post("/api/v1/users", { user })

export const updateUser = async ({ userId, user }) => put(`/api/v1/users/${userId}`, { user })

export const updateUserRole = async ({ userId, role }) => put(`/api/v1/users/${userId}`, { user: { role } })

export const updateUserLocked = async ({ userId, locked }) => put(`/api/v1/users/${userId}`, { user: { locked } })

export const activateUserAccount = async ({ userId }) => post(`/api/v1/users/${userId}/activate`)

export const deactivateUserAccount = async ({ userId }) => post(`/api/v1/users/${userId}/deactivate`)

export const removeOtpConfirmation = async ({ userId }) => destroy(`/api/v1/users/${userId}/otp-confirmation`)

export const getGroups = async () => get("/api/v1/groups")

export const getDepartments = async () => get("/api/v1/departments")

export const activateDepartment = async (departmentId) => post(`/api/v1/departments/${departmentId}/activate`)

export const deactivateDepartment = async (departmentId) => post(`/api/v1/departments/${departmentId}/deactivate`)

export const getCategoryActiveSubformOptions = async ({ categorySlug }) => get(
  `/api/v1/categories/${categorySlug}/active-subform-options`,
)

export const getCategorySubforms = async ({ categorySlug }) => get(`/api/v1/categories/${categorySlug}/subforms`)

export const getCategoryStatuses = async ({ categorySlug }) => get(`/api/v1/categories/${categorySlug}/statuses`)

export const getFormSubmissionAccessOverview = ({ formSubmissionSlug }) => get(
  `/api/v1/form-submissions/${formSubmissionSlug}/access-overview`,
)

export const getFormSubmissionAccessGrantList = ({ formSubmissionSlug }) => get(
  `/api/v1/form-submissions/${formSubmissionSlug}/access-level-grants`,
)

export const createFormSubmissionAccessGrant = async ({ formSubmissionSlug, access_level_grant }) => post(
  `/api/v1/form-submissions/${formSubmissionSlug}/access-level-grants`,
  { access_level_grant },
)

export const manageFormSubmissionAccessGrant = async ({ formSubmissionSlug, access_level_grant }) => post(
  `/api/v1/form-submissions/${formSubmissionSlug}/access-level-grants/manage`,
  { access_level_grant },
)

export const deleteFormSubmissionAccessGrant = async ({ formSubmissionSlug, grantId }) => destroy(
  `/api/v1/form-submissions/${formSubmissionSlug}/access-level-grants/${grantId}`,
)

export const getFormSubmissionTasks = async (formSubmissionSlug) => get(
  `/api/v1/form-submissions/${formSubmissionSlug}/tasks`,
)

export const getFormSubmissionAudits = async ({ formSubmissionSlug, page }) => get(
  `/api/v1/form-submissions/${formSubmissionSlug}/audits?page=${page}`,
)

export const updateSubmissionStatus = async ({ formSubmissionSlug, statusName }) => put(
  `/api/v1/form-submissions/${formSubmissionSlug}/status`,
  { status_name: statusName },
)

export const closeSubmission = async ({ formSubmissionSlug }) => post(
  `/api/v1/form-submissions/${formSubmissionSlug}/close`,
)

export const reopenSubmission = async ({ formSubmissionSlug }) => post(
  `/api/v1/form-submissions/${formSubmissionSlug}/reopen`,
)

export const getFormSubmissionStatusList = async ({ formSubmissionSlug }) => get(
  `/api/v1/form-submissions/${formSubmissionSlug}/statuses`,
)

export const getFormAccessGrantList = async ({ formSlug }) => get(
  `/api/v1/forms/${formSlug}/access-level-grants`,
)

export const getFormQuestions = async ({ formSlug, type }) => get(
  `/api/v1/forms/${formSlug}/questions`,
  type ? { type } : {},
)

export const getFormBuildersList = async ({ categorySlug }) => get(
  `/api/v1/categories/${categorySlug}/abilities/form-builders`,
)

export const getPossibleFormBuilderUsers = async ({ categorySlug }) => get(
  `/api/v1/categories/${categorySlug}/abilities/possible-form-builder-users`,
)

export const manageFormAccessGrant = async ({ formSlug, access_level_grant }) => post(
  `/api/v1/forms/${formSlug}/access-level-grants/manage`,
  { access_level_grant },
)

export const deleteFormAccessGrant = async ({ formSlug, grantId }) => destroy(
  `/api/v1/forms/${formSlug}/access-level-grants/${grantId}`,
)

export const categories = async () => get("api/v1/categories")

export const createCategory = async ({ category }) => post(
  "/api/v1/categories",
  { category },
)

export const updateCategory = async ({ categorySlug, category }) => put(
  `/api/v1/categories/${categorySlug}`,
  { category },
)

export const resetCategory = async ({ categoryId, subdomain, otp }) => post(
  `/api/v1/organizations/${subdomain}/categories/${categoryId}/reset`,
  { otp },
)

export const getBuildableCategories = async () => get("/api/v1/buildable-categories")

export const getCategoryAccessGrantList = async ({ categorySlug }) => get(
  `/api/v1/categories/${categorySlug}/access-level-grants`,
)

export const createCategoryAccessGrant = async ({ categorySlug, access_level_grant }) => post(
  `/api/v1/categories/${categorySlug}/access-level-grants`,
  { access_level_grant },
)

export const updateCategoryAccessGrant = async ({ categorySlug, access_level_grant, grantId }) => put(
  `/api/v1/categories/${categorySlug}/access-level-grants/${grantId}`,
  { access_level_grant },
)

export const deleteCategoryAccessGrant = async ({ categorySlug, grantId }) => destroy(
  `/api/v1/categories/${categorySlug}/access-level-grants/${grantId}`,
)

export const updateCategoryAccessLevelRequirements = async ({ categorySlug, category }) => post(
  `/api/v1/categories/${categorySlug}/update-access-level-requirements`,
  { category },
)

export const getCategoryReportableFields = async ({ categorySlug }) => get(
  `/api/v1/categories/${categorySlug}/reportable-fields`,
)

export const createCategoryReportableField = async ({ categorySlug, reportableField }) => post(
  `/api/v1/categories/${categorySlug}/reportable-fields`,
  { reportable_field: reportableField },
)

export const updateCategoryReportableField = async ({ categorySlug, reportableFieldId, reportableField }) => put(
  `/api/v1/categories/${categorySlug}/reportable-fields/${reportableFieldId}`,
  { reportable_field: reportableField },
)

export const deleteCategoryReportableField = async ({ categorySlug, reportableFieldId }) => destroy(
  `/api/v1/categories/${categorySlug}/reportable-fields/${reportableFieldId}`,
)

export const createFormBuilder = async ({ categorySlug, category_ability }) => post(
  `/api/v1/categories/${categorySlug}/abilities/create-form-builder`,
  { category_ability },
)

export const deleteCategoryAbility = async ({ categorySlug, abilityId }) => destroy(
  `/api/v1/categories/${categorySlug}/abilities/${abilityId}`,
)

export const getCategoryFormSubmissions = async ({ categorySlug }) => get(
  `/api/v1/categories/${categorySlug}/form-submissions`,
)

export const getCategoryForms = async ({ categorySlug }) => get(
  `/api/v1/categories/${categorySlug}/forms`,
)

export const getCategoryFormsForSubmitting = async ({ categorySlug }) => get(
  `/api/v1/categories/${categorySlug}/forms-for-submitting`,
)

export const categoryPublicForms = async ({ categorySlug }) => get(
  `api/v1/categories/${categorySlug}/public-forms`,
)

export const categorySurveyForms = async ({ categorySlug }) => get(
  `/api/v1/surveys/categories/${categorySlug}/forms`,
)

export const formForFormSubmission = async ({ formSlug }) => get(
  `api/v1/forms/${formSlug}/for-form-submission`,
)

export const updateFormsOrder = async ({ categorySlug, formsOrder }) => put(
  `/api/v1/categories/${categorySlug}/form-order`,
  { forms_order: formsOrder },
)

export const createGroup = async ({ group }) => post(
  "/api/v1/groups",
  { group },
)

export const updateGroup = async ({ groupId, group }) => put(
  `/api/v1/groups/${groupId}`,
  { group },
)

export const createGroupMembership = async ({ groupId, group_membership }) => post(
  `/api/v1/groups/${groupId}/members`,
  { group_membership },
)

export const deleteGroupMembership = async ({ groupId, memberId }) => destroy(
  `/api/v1/groups/${groupId}/members/${memberId}`,
)

export const deleteGroup = async ({ groupId }) => destroy(
  `/api/v1/groups/${groupId}`,
)

export const getFacilities = async () => get("/api/v1/facilities")

export const getFacilityAudits = async ({ facilityId, page }) => get(
  `/api/v1/facilities/${facilityId}/audits?page=${page}`,
)

export const createFacility = async ({ facility }) => post(
  "/api/v1/facilities",
  { facility },
)

export const updateFacility = async ({ facilityId, facility }) => put(
  `/api/v1/facilities/${facilityId}`,
  { facility },
)

export const activateFacility = async ({ facilityId }) => post(`/api/v1/facilities/${facilityId}/activate`)

export const deactivateFacility = async ({ facilityId }) => post(`/api/v1/facilities/${facilityId}/deactivate`)

export const createFacilityDepartment = async ({ facilityId, department }) => post(
  `/api/v1/facilities/${facilityId}/departments`,
  { department },
)

export const updateFacilityDepartment = async ({ facilityId, departmentId, department }) => put(
  `/api/v1/facilities/${facilityId}/departments/${departmentId}`,
  { department },
)

export const createDepartmentMembership = async ({ facilityId, departmentId, departmentMembership }) => post(
  `/api/v1/facilities/${facilityId}/departments/${departmentId}/members`,
  {
    department_membership: {
      member_id: departmentMembership.memberId,
      segment: departmentMembership.segmentName,
    },
  },
)

export const deleteDepartmentMembership = async ({ facilityId, departmentId, memberId }) => destroy(
  `/api/v1/facilities/${facilityId}/departments/${departmentId}/members/${memberId}`,
)

export const updateDepartmentMemberSegment = async ({
  facilityId, departmentId, memberId, segmentName,
}) => put(
  `/api/v1/facilities/${facilityId}/departments/${departmentId}/members/${memberId}/department-segment`,
  { segment: segmentName },
)

export const updateTimezoneSetting = async ({ timezone }) => put(
  "/api/v1/timezone-setting",
  { timezone },
)

export const updateTaskReminders = async ({ task_reminders }) => put(
  "/api/v1/task-reminders",
  { task_reminders },
)

export const enableNotificationSetting = async ({ settingType }) => post(
  `/api/v1/notification-settings/${settingType}/enable`,
)

export const disableNotificationSetting = async ({ settingType }) => post(
  `/api/v1/notification-settings/${settingType}/disable`,
)

export const tasksAndSubmissionsSearch = async ({ query, pageSize }) => post(
  "/api/v1/tasks-and-submissions/search",
  { query, page_size: pageSize },
)

export const questionTags = async () => get(
  "/api/v1/question-tags",
)

export const createQuestionTag = async ({ questionTag }) => post(
  "/api/v1/question-tags",
  { question_tag: questionTag },
)

export const facilityGroupCodes = async () => get(
  "/api/v1/facility-group-codes",
)

export const createFacilityGroupCode = async ({ facilityGroupCode }) => post(
  "/api/v1/facility-group-codes",
  { facility_group_code: facilityGroupCode },
)

export const getFacilityGroupCodeAssignments = async ({ facilityId }) => get(
  `/api/v1/facilities/${facilityId}/facility-group-code-assignments`,
)

export const updateFacilityGroupCodeAssignments = async ({ facilityId, facilityGroupCodeIds }) => put(
  `/api/v1/facilities/${facilityId}/facility-group-code-assignments`,
  { facility_group_code_ids: facilityGroupCodeIds },
)

export const markFeatureAnnouncementAsRead = async ({ featureAnnouncementId }) => post(
  `/api/v1/feature-announcements/${featureAnnouncementId}/read`,
)

export const startImpersonation = async ({ impersonateeId }) => post(
  "/api/v1/impersonation",
  { user_id: impersonateeId },
)

export const endImpersonation = async () => destroy(
  "/api/v1/impersonation",
)

export const createFormSubmissionReportReportExport = ({
  dateAnswerFilters,
  earliestDate: earliestSubmissionDate,
  latestDate: latestSubmissionDate,
  locationAnswerFilters,
  reportFileType,
  reportId,
}) => post(
  `/api/v1/form-submission-reports/${reportId}/exports`,
  {
    form_submission_report_export: {
      date_answer_filters: dateAnswerFilters.map(({ earliestDate, latestDate, questionUuid }) => ({
        earliest_date: earliestDate,
        latest_date: latestDate,
        question_uuid: questionUuid,
      })),
      earliest_date: earliestSubmissionDate,
      latest_date: latestSubmissionDate,
      location_answer_filters: locationAnswerFilters.map(({ departmentIds, questionUuid }) => ({
        department_ids: departmentIds,
        question_uuid: questionUuid,
      })),
      export_file_type: reportFileType,
    },
  },
)

export const createSolutionLevelReportReportExport = ({
  earliestDate: earliestSubmissionDate,
  latestDate: latestSubmissionDate,
  reportableFieldDateAnswerFilters,
  reportableFieldLocationAnswerFilters,
  reportFileType,
  reportId,
}) => post(
  `/api/v1/solution-level-reports/${reportId}/exports`,
  {
    solution_level_report_export: {
      earliest_date: earliestSubmissionDate,
      latest_date: latestSubmissionDate,
      reportable_field_date_answer_filters: reportableFieldDateAnswerFilters.map(
        ({ earliestDate, latestDate, reportableFieldId }) => ({
          earliest_date: earliestDate,
          latest_date: latestDate,
          reportable_field_id: reportableFieldId,
        }),
      ),
      reportable_field_location_answer_filters: reportableFieldLocationAnswerFilters.map(
        ({ departmentIds, reportableFieldId }) => ({
          department_ids: departmentIds,
          reportable_field_id: reportableFieldId,
        }),
      ),
      export_file_type: reportFileType,
    },
  },
)

export const createFormSubmissionScheduledReportExport = ({
  activeStatus,
  dateAnswerFilters,
  frequency,
  locationAnswerFilters,
  recurrenceDayOfMonth,
  recurrenceDayOfWeek,
  scheduledReportExportName,
  startDate,
  submissionPreviousRangeLength,
  submissionPreviousRangeUnit,
  reportId,
}) => post(
  `/api/v1/form-submission-reports/${reportId}/scheduled-report-exports`,
  {
    form_submission_scheduled_report_export: {
      name: scheduledReportExportName,
      form_submission_previous_range_length: submissionPreviousRangeLength,
      form_submission_previous_range_unit: submissionPreviousRangeUnit,
      date_answer_filters: dateAnswerFilters,
      location_answer_filters: locationAnswerFilters.map(({ departmentIds, questionUuid }) => ({
        department_ids: departmentIds,
        question_uuid: questionUuid,
      })),
      scheduled_report_export_schedule_attributes: {
        frequency,
        active: activeStatus,
        recurrence_day_of_month: recurrenceDayOfMonth,
        recurrence_day_of_week: recurrenceDayOfWeek,
        start_date: startDate,
      },
    },
  },
)

export const updateFormSubmissionScheduledReportExport = ({
  activeStatus,
  dateAnswerFilters,
  locationAnswerFilters,
  recurrenceDayOfMonth,
  recurrenceDayOfWeek,
  scheduledReportExportName,
  startDate,
  submissionPreviousRangeLength,
  submissionPreviousRangeUnit,
  reportId,
  scheduledReportExportId,
}) => put(
  `/api/v1/form-submission-reports/${reportId}/scheduled-report-exports/${scheduledReportExportId}`,
  {
    form_submission_scheduled_report_export: {
      name: scheduledReportExportName,
      form_submission_previous_range_length: submissionPreviousRangeLength,
      form_submission_previous_range_unit: submissionPreviousRangeUnit,
      date_answer_filters: dateAnswerFilters,
      location_answer_filters: locationAnswerFilters.map(({ departmentIds, questionUuid }) => ({
        department_ids: departmentIds,
        question_uuid: questionUuid,
      })),
      scheduled_report_export_schedule_attributes: {
        active: activeStatus,
        recurrence_day_of_month: recurrenceDayOfMonth,
        recurrence_day_of_week: recurrenceDayOfWeek,
        start_date: startDate,
      },
    },
  },
)

export const createSolutionLevelScheduledReportExport = ({
  activeStatus,
  frequency,
  recurrenceDayOfMonth,
  recurrenceDayOfWeek,
  reportableFieldDateAnswerFilters,
  reportableFieldLocationAnswerFilters,
  scheduledReportExportName,
  startDate,
  submissionPreviousRangeLength,
  submissionPreviousRangeUnit,
  reportId,
}) => post(
  `/api/v1/solution-level-reports/${reportId}/scheduled-report-exports`,
  {
    solution_level_scheduled_report_export: {
      name: scheduledReportExportName,
      form_submission_previous_range_length: submissionPreviousRangeLength,
      form_submission_previous_range_unit: submissionPreviousRangeUnit,
      reportable_field_date_answer_filters: reportableFieldDateAnswerFilters,
      reportable_field_location_answer_filters: reportableFieldLocationAnswerFilters.map(({ departmentIds, reportableFieldId }) => ({
        department_ids: departmentIds,
        reportable_field_id: reportableFieldId,
      })),
      scheduled_report_export_schedule_attributes: {
        active: activeStatus,
        frequency,
        recurrence_day_of_month: recurrenceDayOfMonth,
        recurrence_day_of_week: recurrenceDayOfWeek,
        start_date: startDate,
      },
    },
  },
)

export const updateSolutionLevelScheduledReportExport = ({
  activeStatus,
  recurrenceDayOfMonth,
  recurrenceDayOfWeek,
  reportableFieldDateAnswerFilters,
  reportableFieldLocationAnswerFilters,
  scheduledReportExportName,
  startDate,
  submissionPreviousRangeLength,
  submissionPreviousRangeUnit,
  reportId,
  scheduledReportExportId,
}) => put(
  `/api/v1/solution-level-reports/${reportId}/scheduled-report-exports/${scheduledReportExportId}`,
  {
    solution_level_scheduled_report_export: {
      name: scheduledReportExportName,
      form_submission_previous_range_length: submissionPreviousRangeLength,
      form_submission_previous_range_unit: submissionPreviousRangeUnit,
      reportable_field_date_answer_filters: reportableFieldDateAnswerFilters,
      reportable_field_location_answer_filters: reportableFieldLocationAnswerFilters.map(({ departmentIds, reportableFieldId }) => ({
        department_ids: departmentIds,
        reportable_field_id: reportableFieldId,
      })),
      scheduled_report_export_schedule_attributes: {
        active: activeStatus,
        recurrence_day_of_month: recurrenceDayOfMonth,
        recurrence_day_of_week: recurrenceDayOfWeek,
        start_date: startDate,
      },
    },
  },
)

export const createSurveyScheduledReportExport = ({
  activeStatus,
  dateAnswerFilters,
  frequency,
  locationAnswerFilters,
  recurrenceDayOfWeek,
  recurrenceDayOfMonth,
  scheduledReportExportName,
  startDate,
  surveyFilters,
  reportId,
}) => post(
  `/api/v1/surveys/survey-reports/${reportId}/scheduled-report-exports`,
  {
    survey_scheduled_report_export: {
      name: scheduledReportExportName,
      date_answer_filters: dateAnswerFilters,
      location_answer_filters: locationAnswerFilters.map(({ departmentIds, questionUuid }) => ({
        department_ids: departmentIds,
        question_uuid: questionUuid,
      })),
      survey_filters: surveyFilters,
      scheduled_report_export_schedule_attributes: {
        active: activeStatus,
        frequency,
        recurrence_day_of_month: recurrenceDayOfMonth,
        recurrence_day_of_week: recurrenceDayOfWeek,
        start_date: startDate,
      },
    },
  },
)

export const updateSurveyScheduledReportExport = ({
  activeStatus,
  dateAnswerFilters,
  locationAnswerFilters,
  recurrenceDayOfWeek,
  recurrenceDayOfMonth,
  scheduledReportExportName,
  startDate,
  surveyFilters,
  reportId,
  scheduledReportExportId,
}) => put(
  `/api/v1/surveys/survey-reports/${reportId}/scheduled-report-exports/${scheduledReportExportId}`,
  {
    survey_scheduled_report_export: {
      name: scheduledReportExportName,
      date_answer_filters: dateAnswerFilters,
      location_answer_filters: locationAnswerFilters.map(({ departmentIds, questionUuid }) => ({
        department_ids: departmentIds,
        question_uuid: questionUuid,
      })),
      survey_filters: surveyFilters,
      scheduled_report_export_schedule_attributes: {
        active: activeStatus,
        recurrence_day_of_month: recurrenceDayOfMonth,
        recurrence_day_of_week: recurrenceDayOfWeek,
        start_date: startDate,
      },
    },
  },
)

export const createSurveyReportExport = ({
  dateAnswerFilters,
  surveyBatchIds,
  locationAnswerFilters,
  reportFileType,
  reportId,
}) => post(
  `/api/v1/surveys/survey-reports/${reportId}/exports`,
  {
    survey_report_export: {
      survey_filters: [{ survey_batch_ids: surveyBatchIds }],
      date_answer_filters: dateAnswerFilters.map(({ earliestDate, latestDate, questionUuid }) => ({
        earliest_date: earliestDate,
        latest_date: latestDate,
        question_uuid: questionUuid,
      })),
      location_answer_filters: locationAnswerFilters.map(({ departmentIds, questionUuid }) => ({
        department_ids: departmentIds,
        question_uuid: questionUuid,
      })),
      export_file_type: reportFileType,
    },
  },
)

export const reportExport = ({ reportExportId }) => get(`/api/v1/report-exports/${reportExportId}`)

export const activateScheduledReportExportSchedule = ({ scheduleId }) => post(`/api/v1/report-export-schedules/${scheduleId}/activate`)
export const deactivateScheduledReportExportSchedule = ({ scheduleId }) => post(`/api/v1/report-export-schedules/${scheduleId}/deactivate`)

export const createMetabaseDashboardImageCapture = ({ dashboardUrl }) => post(
  "/api/v1/metabase-dashboard-image-captures",
  {
    metabase_dashboard_image_capture: {
      dashboard_url: dashboardUrl,
    },
  },
)

export const metabaseDashboardImageCapture = ({ imageCaptureId }) => get(
  `/api/v1/metabase-dashboard-image-captures/${imageCaptureId}`,
)

export const reportError = async ({ errorDetails }) => post(
  "/api/v1/public/frontend-errors/",
  {
    frontend_error: errorDetails,
  },
)

export const historicalAccessToolAddAccessPreviews = ({
  accessLevel,
  earliestSubmissionDate,
  formsForSubmittingIds,
  latestSubmissionDate,
  reportableFieldLocationAnswerFilters,
  taskFormIds,
  userId,
}) => (
  post(
    "/api/v1/historical-access-tool/add-access/previews",
    {
      instructions: {
        access_level: accessLevel,
        earliest_submission_date: earliestSubmissionDate,
        forms_for_submitting_ids: formsForSubmittingIds,
        latest_submission_date: latestSubmissionDate,
        reportable_field_location_answer_filters: reportableFieldLocationAnswerFilters.map(({ reportableFieldId, departmentIds }) => (
          {
            reportable_field_id: reportableFieldId,
            department_ids: departmentIds,
          }
        )),
        task_form_ids: taskFormIds,
        user_id: userId,
      },
    },
  )
)

export const historicalAccessToolAddAccess = ({
  accessLevel,
  earliestSubmissionDate,
  formsForSubmittingIds,
  latestSubmissionDate,
  reportableFieldLocationAnswerFilters,
  taskFormIds,
  userId,
}) => (
  post(
    "/api/v1/historical-access-tool/add-access",
    {
      instructions: {
        access_level: accessLevel,
        earliest_submission_date: earliestSubmissionDate,
        forms_for_submitting_ids: formsForSubmittingIds,
        latest_submission_date: latestSubmissionDate,
        reportable_field_location_answer_filters: reportableFieldLocationAnswerFilters.map(({ reportableFieldId, departmentIds }) => (
          {
            reportable_field_id: reportableFieldId,
            department_ids: departmentIds,
          }
        )),
        task_form_ids: taskFormIds,
        user_id: userId,
      },
    },
  )
)

export const historicalAccessToolRemoveAccessPreviews = ({
  earliestSubmissionDate,
  formsForSubmittingIds,
  latestSubmissionDate,
  reportableFieldLocationAnswerFilters,
  userId,
}) => (
  post(
    "/api/v1/historical-access-tool/remove-access/previews",
    {
      instructions: {
        earliest_submission_date: earliestSubmissionDate,
        forms_for_submitting_ids: formsForSubmittingIds,
        latest_submission_date: latestSubmissionDate,
        reportable_field_location_answer_filters: reportableFieldLocationAnswerFilters.map(({ reportableFieldId, departmentIds }) => (
          {
            reportable_field_id: reportableFieldId,
            department_ids: departmentIds,
          }
        )),
        user_id: userId,
      },
    },
  )
)

export const historicalAccessToolRemoveAccess = ({
  earliestSubmissionDate,
  formsForSubmittingIds,
  latestSubmissionDate,
  reportableFieldLocationAnswerFilters,
  userId,
}) => (
  post(
    "/api/v1/historical-access-tool/remove-access",
    {
      instructions: {
        earliest_submission_date: earliestSubmissionDate,
        forms_for_submitting_ids: formsForSubmittingIds,
        latest_submission_date: latestSubmissionDate,
        reportable_field_location_answer_filters: reportableFieldLocationAnswerFilters.map(({ reportableFieldId, departmentIds }) => (
          {
            reportable_field_id: reportableFieldId,
            department_ids: departmentIds,
          }
        )),
        user_id: userId,
      },
    },
  )
)

// Endpoint is under the "php" subdomain
export const subscribeOrganizationToAddOnFeature = ({ organizationSubdomain, addOnFeatureName }) => post(
  `/api/v1/organizations/${organizationSubdomain}/add-on-features/${addOnFeatureName}/subscribe`,
)

// Endpoint is under the "php" subdomain
export const unsubscribeOrganizationFromAddOnFeature = ({ organizationSubdomain, addOnFeatureName }) => destroy(
  `/api/v1/organizations/${organizationSubdomain}/add-on-features/${addOnFeatureName}/unsubscribe`,
)

// Endpoint is under the "php" subdomain
export const deleteOrganizationAccess = ({ organizationSubdomain, organizationAccessId }) => destroy(
  `/api/v1/organizations/${organizationSubdomain}/organization-accesses/${organizationAccessId}`,
)

// Endpoint is under the "php" subdomain
export const createOrganizationAccess = ({ organizationSubdomain, userId }) => post(
  `/api/v1/organizations/${organizationSubdomain}/organization-accesses/`,
  {
    user_id: userId,
  },
)
