import React from "react"
import types from "prop-types"
import clsx from "clsx"

const SelectCSVOptionsPreview = ({ className, options }) => (
  <div className={clsx("rounded-lg border border-light overflow-hidden", className)}>
    <ul className="max-h-1/2-screen overflow-auto p-0 m-0">
      {
        options.map((option) => (
          <li key={option} className="px-4 pb-2 first:pt-2 border-b last:border-b-0 border-light mb-2 last:mb-0">
            <span>{option}</span>
          </li>
        ))
      }
    </ul>
  </div>
)

SelectCSVOptionsPreview.defaultProps = {
  className: undefined,
}

SelectCSVOptionsPreview.propTypes = {
  className: types.string,
  options: types.arrayOf(types.string).isRequired,
}

export default SelectCSVOptionsPreview
