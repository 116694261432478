import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import { FormsyTextInput } from "shared/inputs"
import { MIN_PHONE_DIGITS } from "utils/validationHelpers"

const phoneValidations = ({
  edit,
  required,
}) => {
  const validations = {
    hasSufficientDigits: { qty: MIN_PHONE_DIGITS },
    noExcelMetacharacters: true,
    noHTML: true,
  }

  if (edit) { return validations }

  if (required) { validations.isNotBlankString = true }

  return validations
}

const PhoneAnswerField = ({
  className,
  edit,
  initialValue,
  question,
  ...rest
}) => {
  const { required, uuid } = question

  return (
    <FormsyTextInput
      className={clsx("form-input", className)}
      name={uuid}
      validations={phoneValidations({ edit, required })}
      value={initialValue || ""}
      {...rest}
    />
  )
}

PhoneAnswerField.defaultProps = {
  edit: false,
  initialValue: null,
  disabled: false,
  className: "",
  placeholder: "",
  hasBaseInputStyle: false,
}

PhoneAnswerField.propTypes = {
  edit: types.bool,
  question: questionShape.isRequired,
  initialValue: types.string,
  disabled: types.bool,
  className: types.string,
  placeholder: types.string,
  hasBaseInputStyle: types.bool,
}

export default PhoneAnswerField
