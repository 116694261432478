import React from "react"
import types from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { taskShape } from "utils/propTypeShapes"
import {
  removeTask,
  submissionPermissions as getSubmissionPermissions,
} from "reduxSlices/formSubmissionSlice"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import { EllipsisIcon } from "shared/icons"
import * as API from "services/api"

const TaskMenu = ({ task, openForm, taskIsDeletable }) => {
  const dispatch = useDispatch()

  const deleteTask = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete this task?")) {
      const taskId = task.id
      const response = await API.deleteTask({ taskId })

      if (!response.ok) {
        console.error("DELETING TASK FAILED: ", response.data)
        return
      }

      dispatch(removeTask(taskId))
    }
  }

  const { canEdit: canEditTask } = task.userTaskPermissions

  const { canAssignTaskWithSubform, canAssignTask } = useSelector(getSubmissionPermissions)

  const taskIsEditable = (
    task.isOpen
    && canEditTask
    && (task.subform ? canAssignTaskWithSubform : canAssignTask)
  )

  if (!taskIsEditable && !taskIsDeletable) return null

  return (
    <ExpandMenu expandMenuComponent={(
      <div className="self-start h-6 w-6 bg-lightgray rounded mt-5 ml-4">
        <EllipsisIcon className="pl-1 pt-1" />
      </div>
    )}
    >
      {
        taskIsEditable && (
          <ExpandMenuItem onClick={openForm}>
            <span>Edit</span>
          </ExpandMenuItem>
        )
      }
      {
        taskIsDeletable && (
          <ExpandMenuItem onClick={deleteTask}>
            <span>Delete</span>
          </ExpandMenuItem>
        )
      }
    </ExpandMenu>
  )
}

TaskMenu.propTypes = {
  task: taskShape.isRequired,
  openForm: types.func.isRequired,
  taskIsDeletable: types.bool.isRequired,
}

export default TaskMenu
