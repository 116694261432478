import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { taskShape } from "utils/propTypeShapes"
import TaskDueDate from "./TaskDueDate"
import TaskTitle from "./TaskTitle"
import TaskTokenTitle from "./TaskTokenTitle"
import TaskHeaderAssigneesOverview from "./TaskHeaderAssigneesOverview"
import ExpansionControl from "./ExpansionControl"

const titlebarStyles = ({ expanded }) => clsx(
  "flex-center pointer",
  expanded && "mb-4",
)

const TaskHeader = ({
  expanded,
  task,
  toggleAccordion,
}) => {
  const { canSeeDetails } = task.userTaskPermissions
  const onHeaderClick = (task.isDeleted || !canSeeDetails) ? undefined : toggleAccordion

  return (
    <div
      className={clsx(onHeaderClick && "cursor-pointer", "px-4 py-3 rounded-lg")}
      onClickCapture={onHeaderClick}
    >
      <div className={titlebarStyles({ expanded })}>
        <div className="flex">
          <TaskDueDate dueDate={task.dueDate} isOverdue={task.isOverdue} />
          <TaskTitle title={task.title} isOpen={task.isOpen} />
        </div>
        <div className="flex-center">
          <TaskTokenTitle subform={task.subform} />
          {
            !expanded && <TaskHeaderAssigneesOverview assigneeIds={task.assigneeIds} />
          }
          {
            !task.isDeleted && (
              <ExpansionControl
                expanded={expanded}
                canSeeDetails={canSeeDetails}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

TaskHeader.defaultProps = {
  expanded: false,
}

TaskHeader.propTypes = {
  expanded: types.bool,
  task: taskShape.isRequired,
  toggleAccordion: types.func.isRequired,
}

export default TaskHeader
