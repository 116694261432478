import React, { useEffect } from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import {
  setCategory,
  setFormSubmissionData,
  setSubmissionPermissions,
  setRelatedFormSubmissions,
  setTasks,
  setGroups,
  setUsers,
  setDepartments,
  setAttachments,
  setQuestionUuidsIdentifyingTaskDepartments,
} from "reduxSlices/formSubmissionSlice"
import { clearAllDraftAnswers } from "reduxSlices/taskDraftAnswersSlice"
import { FormSubmissionFormContextProvider } from "contexts/FormSubmissionFormContext"
import {
  categoryShape,
  formSubmissionShape,
  formVersionShape,
  relatedFormSubmissionsShape,
  submissionPermissionsShape,
  taskShape,
} from "utils/propTypeShapes"
import { ACTIVITY_HUB_SUBMISSIONS_PATH } from "utils/routeHelpers"
import * as API from "services/api"
import withReduxProvider from "shared/withReduxProvider"
import FormSubmissionTabs from "views/FormSubmission/FormSubmissionTabs"
import DetailsBanner from "./DetailsBanner"
import SyncAllTaskAnswers from "./SyncAllTaskAnswers"

// eslint-disable-next-line no-alert
const confirmDelete = () => window.confirm("Are you sure you want to delete this submission?")

const FormSubmissionPage = ({
  category,
  currentFormVersion,
  form,
  formSubmission,
  questionUuidsIdentifyingTaskDepartments,
  relatedFormSubmissions,
  submissionPermissions,
  submittedFormVersion,
  tasks,
}) => {
  const dispatch = useDispatch()

  const { canDeleteSubmission, canExportSubmission } = submissionPermissions

  // Clear all draft answers when the component unmounts
  useEffect(() => () => dispatch(clearAllDraftAnswers()), [])

  useEffect(() => {
    dispatch(setFormSubmissionData(formSubmission))
  }, [formSubmission])

  useEffect(() => {
    dispatch(setSubmissionPermissions(submissionPermissions))
  }, [submissionPermissions])

  useEffect(() => {
    dispatch(setTasks(tasks))
  }, [tasks])

  useEffect(() => {
    dispatch(setAttachments(formSubmission.attachments))
  }, [formSubmission.attachments])

  useEffect(() => {
    dispatch(setCategory(category))
  }, [category])

  useEffect(() => {
    dispatch(setRelatedFormSubmissions(relatedFormSubmissions), [relatedFormSubmissions])
  })

  useEffect(() => {
    dispatch(setQuestionUuidsIdentifyingTaskDepartments(questionUuidsIdentifyingTaskDepartments))
  })

  useEffect(() => {
    (async function getUsers() {
      const response = await API.getUsers()

      if (response.ok) {
        dispatch(setUsers(response.data))
      } else {
        console.error("Error fetching users: ", response)
      }
    }())
  }, [])

  useEffect(() => {
    (async function getGroups() {
      const response = await API.getGroups()

      if (response.ok) {
        dispatch(setGroups(response.data))
      } else {
        console.error("Error fetching groups: ", response)
      }
    }())
  }, [])

  useEffect(() => {
    (async function getDepartments() {
      const response = await API.getDepartments()

      if (response.ok) {
        dispatch(setDepartments(response.data))
      } else {
        console.error("Error fetching departments: ", response)
      }
    }())
  }, [])

  const deleteFormSubmission = async () => {
    if (confirmDelete()) {
      const response = await API.deleteFormSubmission({ slug: formSubmission.slug })

      if (response.ok) {
        window.location.pathname = ACTIVITY_HUB_SUBMISSIONS_PATH
      } else {
        console.error("Error deleting form submission: ", response)
      }
    }
  }

  const exportFormSubmissionData = (includeFormSubmission, includedTaskIds) => async () => {
    const response = await API.exportFormSubmissionData({
      slug: formSubmission.slug, includeFormSubmission, includedTaskIds,
    })

    if (response.ok) {
      // eslint-disable-next-line no-undef
      const blob = new Blob([response.data], { type: "application/pdf" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")

      link.href = url
      link.download = `${formSubmission.slug}.pdf`
      link.click()
      link.remove()
    } else {
      console.error("Error exporting form submission: ", response)
    }
  }

  return (
    <FormSubmissionFormContextProvider
      currentFormVersion={currentFormVersion}
      form={form}
      submittedFormVersion={submittedFormVersion}
    >
      <SyncAllTaskAnswers />
      <DetailsBanner
        canDeleteSubmission={canDeleteSubmission}
        deleteFormSubmission={deleteFormSubmission}
        canExportSubmission={canExportSubmission}
        exportFormSubmissionData={exportFormSubmissionData}
      />
      <div className="flex flex-col relative" style={{ paddingTop: "60px" }}>
        <FormSubmissionTabs />
      </div>
    </FormSubmissionFormContextProvider>
  )
}

FormSubmissionPage.propTypes = {
  category: categoryShape.isRequired,
  currentFormVersion: formVersionShape.isRequired,
  form: types.shape({
    id: types.number.isRequired,
    title: types.string.isRequired,
    slug: types.string.isRequired,
    sections: types.arrayOf(types.object),
  }).isRequired,
  formSubmission: formSubmissionShape.isRequired,
  questionUuidsIdentifyingTaskDepartments: types.arrayOf(types.string).isRequired,
  relatedFormSubmissions: types.arrayOf(relatedFormSubmissionsShape).isRequired,
  submissionPermissions: submissionPermissionsShape.isRequired,
  submittedFormVersion: formVersionShape.isRequired,
  tasks: types.arrayOf(taskShape).isRequired,
}

export default withReduxProvider(FormSubmissionPage)
