import React from "react"
import types from "prop-types"
import clsx from "clsx"

const SelectCSVInvalidOptions = ({ className, invalidOverview }) => (
  <div className={clsx("rounded-lg border border-light overflow-hidden", className)}>
    <ul className="max-h-1/2-screen overflow-auto p-0 m-0">
      {
        invalidOverview.map(({ option, errorMessages }) => (
          <li key={option} className="px-4 pb-2 first:pt-2 border-b last:border-b-0 border-light mb-2 last:mb-0">
            <span className="block mb-2 font-medium">{ option }</span>
            <ul className="p-0 m-0 ml-4 text-sm text-gray">
              {
                errorMessages.map((errorMessage) => (
                  <li key={errorMessage}>{errorMessage}</li>
                ))
              }
            </ul>
          </li>
        ))
      }
    </ul>
  </div>
)

SelectCSVInvalidOptions.defaultProps = {
  className: undefined,
}

SelectCSVInvalidOptions.propTypes = {
  className: types.string,
  invalidOverview: types.arrayOf(types.shape({
    option: types.string.isRequired,
    errorMessages: types.arrayOf(types.string).isRequired,
  })).isRequired,
}

export default SelectCSVInvalidOptions
