import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import { FormsyListMultiSelect } from "shared/multiSelects"
import { FormsyCheckboxGroup } from "shared/checkboxes"
import { DROPDOWN } from "FormBuilder/QuestionFields/QuestionTypeAttributes/SelectAttributes/DisplayAs"

const MultiSelectAnswerField = ({
  question, initialValue, disabled, className, placeholder, hasBaseInputStyle, isFullWidth, edit, isTaskForm,
}) => {
  const { required, uuid, attributes } = question
  const {
    columnCount,
    defaultAnswers,
    displayAs,
    options,
  } = attributes

  const isNewFormSubmission = !edit && !isTaskForm
  initialValue = isNewFormSubmission ? defaultAnswers : initialValue

  let validations = null

  if (!edit) {
    validations = required ? { isNotEmptyArray: true } : null
  }

  if (displayAs === DROPDOWN) {
    // Must pass value prop for Formsy to register the value
    // Pass values prop for the component to find the selected options
    return (
      <FormsyListMultiSelect
        className={clsx("form-input", className)}
        searchable
        disabled={disabled}
        hasBaseInputStyle={hasBaseInputStyle}
        placeholder={placeholder}
        name={uuid}
        isFullWidth={isFullWidth}
        options={options}
        value={initialValue}
        values={disabled ? [] : initialValue}
        validations={validations}
      />
    )
  }

  return (
    <FormsyCheckboxGroup
      className={clsx("my-3 text-lg", className)}
      name={uuid}
      disabled={disabled}
      checkboxInputClassName="form-hover-highlight"
      options={options}
      value={disabled ? [] : initialValue}
      columnCount={columnCount}
      validations={validations}
    />
  )
}

MultiSelectAnswerField.defaultProps = {
  initialValue: [],
  disabled: false,
  className: "",
  placeholder: "",
  hasBaseInputStyle: false,
  isFullWidth: false,
  edit: false,
  isTaskForm: false,
}

MultiSelectAnswerField.propTypes = {
  question: questionShape.isRequired,
  initialValue: types.arrayOf(types.string),
  disabled: types.bool,
  className: types.string,
  placeholder: types.string,
  hasBaseInputStyle: types.bool,
  isFullWidth: types.bool,
  edit: types.bool,
  isTaskForm: types.bool,
}

export default MultiSelectAnswerField
