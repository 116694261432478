import React from "react"
import types from "prop-types"
import { workflowConditionShape } from "utils/propTypeShapes"
import {
  QUESTION_ANSWER_CONDITION_PROPERTY,
  SUBFORM_ANSWER_CONDITION_PROPERTY,
  STATUS_CONDITION_PROPERTY,
  SUBMISSION_AGE_CONDITION_PROPERTY,
  SUBMITTED_FORM_PROPERTY,
} from "utils/workflowHelpers"
import * as ValueDefiners from "./ConditionValueDefiners/index"

const DefineConditionValue = ({
  condition,
  changeConditionValue,
  className,
  conditionIndex,
}) => {
  let ValueDefiner
  switch (condition.property) {
  case STATUS_CONDITION_PROPERTY:
    ValueDefiner = ValueDefiners.DefineStatusValue;
    break;
  case QUESTION_ANSWER_CONDITION_PROPERTY:
  case SUBFORM_ANSWER_CONDITION_PROPERTY:
    ValueDefiner = ValueDefiners.DefineQuestionAnswerValue;
    break;
  case SUBMISSION_AGE_CONDITION_PROPERTY:
    ValueDefiner = ValueDefiners.DefineSubmissionAgeValue;
    break;
  case SUBMITTED_FORM_PROPERTY:
    ValueDefiner = ValueDefiners.DefineSubmittedFormValue;
    break;
  default:
    return null
  }

  return (
    <ValueDefiner
      condition={condition}
      changeConditionValue={changeConditionValue}
      className={className}
      conditionIndex={conditionIndex}
    />
  )
}

DefineConditionValue.defaultProps = {
  className: "",
}

DefineConditionValue.propTypes = {
  condition: workflowConditionShape.isRequired,
  changeConditionValue: types.func.isRequired,
  conditionIndex: types.number.isRequired,
  className: types.string,
}

export default DefineConditionValue
