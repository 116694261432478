import React, { useEffect } from "react"
import Popup from "reactjs-popup";
import withReduxProvider from "shared/withReduxProvider"
import { endAllTabSessionsListener } from "utils/endSession"
import { allTabImpersonationsListener } from "utils/impersonation"
import Panel from "./Panel"
import PanelTrigger from "./PanelTrigger"

const UsersPanel = () => {
  useEffect(() => {
    endAllTabSessionsListener()
    allTabImpersonationsListener()
  })

  return (
    <Popup
      trigger={<div><PanelTrigger /></div>}
      position="bottom right"
    >
      <Panel />
    </Popup>
  )
}

export default withReduxProvider(UsersPanel)
