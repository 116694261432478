import React from "react"
import types from "prop-types"
import { findValidHTMLElement } from "utils/customFormsyValidations"
import FormsyValidation from "shared/FormsyValidation"

const NoOptionsWithHTMLValidation = ({ value, sectionIndex, questionIndex }) => (
  <FormsyValidation
    name={`sections[${sectionIndex}].questions[${questionIndex}].noOptionsWithHTMLValidation`}
    value={value}
    validations={{
      noOptionsWithHTMLValidation(_model, val) {
        let validHTMLElement
        const parser = new window.DOMParser()

        for (let i = 0; i < val.length; i += 1) {
          if (val[i]) {
            const document = parser.parseFromString(val[i], "text/html")
            validHTMLElement = findValidHTMLElement(document.body, { checkElement: false })

            if (validHTMLElement) break
          }
        }

        if (validHTMLElement) {
          return `HTML not permitted in options: <${validHTMLElement.tagName}>`
        }

        return true
      },
    }}
  />
)

NoOptionsWithHTMLValidation.defaultProps = {
  value: [],
}

NoOptionsWithHTMLValidation.propTypes = {
  value: types.arrayOf(types.string),
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default NoOptionsWithHTMLValidation
