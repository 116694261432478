import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import { FormsyListSelect } from "shared/selects"
import { FormsyRadioButtonGroupInput } from "shared/inputs"
import { DROPDOWN } from "FormBuilder/QuestionFields/QuestionTypeAttributes/SelectAttributes/DisplayAs"

const SelectAnswerField = ({
  className,
  disabled,
  edit,
  hasBaseInputStyle,
  initialValue,
  question,
  ...rest
}) => {
  const { required, uuid, attributes } = question
  const {
    columnCount,
    defaultAnswer,
    displayAs,
    options,
  } = attributes

  let validations = null

  if (!edit) {
    validations = required ? { isNotBlankString: { errorMessage: "Must select 1 answer" } } : null
  }

  const value = initialValue || (displayAs === DROPDOWN && disabled ? "" : defaultAnswer)

  const answerIsNotAnOption = value && !options.includes(value)

  return (
    <>
      {
        displayAs === DROPDOWN
          ? (
            <FormsyListSelect
              className={clsx("form-input select-answer-field", answerIsNotAnOption && "mb-4", className)}
              isFullHeight
              searchable
              clearable
              hasBaseInputStyle={hasBaseInputStyle}
              disabled={disabled}
              name={uuid}
              options={options}
              value={value}
              validations={validations}
              {...rest}
            />
          )
          : (
            <FormsyRadioButtonGroupInput
              name={uuid}
              className={clsx(className, "text-lg mt-2", answerIsNotAnOption && "mb-4")}
              radioInputClassName="form-hover-highlight"
              disabled={disabled}
              options={options}
              value={value}
              columnCount={columnCount}
              validations={validations}
              {...rest}
            />
          )
      }
      {
        answerIsNotAnOption && (
          <p className="text-sm">
            The saved answer for this question is <span className="font-semibold">{value}</span>.<br />
            That is not currently an option.
          </p>
        )
      }
    </>
  )
}

SelectAnswerField.defaultProps = {
  edit: false,
  initialValue: "",
  disabled: false,
  className: "",
  placeholder: "",
  hasBaseInputStyle: false,
  isFullWidth: false,
}

SelectAnswerField.propTypes = {
  edit: types.bool,
  question: questionShape.isRequired,
  initialValue: types.string,
  disabled: types.bool,
  className: types.string,
  placeholder: types.string,
  hasBaseInputStyle: types.bool,
  isFullWidth: types.bool,
}

export default SelectAnswerField
