import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "services/api"

const INITIAL_STATE = {
  accessOverview: {
    members: [],
    nonMembersWithAccess: [],
  },
  category: {
    subforms: [],
    statuses: [],
  },
  data: {},
  submissionPermissions: {},
  tasks: [],
  attachments: [],
  users: [],
  showOnlyDeletedTasks: false,
  relatedFormSubmissions: [],
  questionUuidsIdentifyingTaskDepartments: [],
}

export const updateAccessOverview = createAsyncThunk(
  "formSubmission/updateAccessOverview",
  async ({ formSubmissionSlug }) => {
    const response = await API.getFormSubmissionAccessOverview({ formSubmissionSlug })
    return response.data
  },
)

export const formSubmissionSlice = createSlice({
  name: "formSubmission",
  initialState: INITIAL_STATE,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload
    },
    setFormSubmissionData: (state, action) => {
      state.data = action.payload
    },
    updateFormSubmissionAnswers: (state, action) => {
      state.data.answers = action.payload
    },
    setSubmissionPermissions: (state, action) => {
      state.submissionPermissions = action.payload
    },
    setRelatedFormSubmissions: (state, action) => {
      state.relatedFormSubmissions = action.payload
    },
    setTasks: (state, { payload }) => {
      payload.forEach((task) => { task.expanded = false })
      state.tasks = payload
    },
    toggleTaskExpanded: (state, { payload: taskId }) => {
      const task = state.tasks.find(({ id }) => id === taskId)
      task.expanded = !task.expanded
    },
    setAttachments: (state, action) => {
      state.attachments = action.payload
    },
    addTask: (state, { payload }) => {
      payload.expanded = false
      state.tasks.push(payload)
    },
    updateTask: (state, action) => {
      const { payload } = action
      const { id } = payload

      const taskIndex = state.tasks.findIndex((task) => task.id === id)
      state.tasks[taskIndex] = payload
    },
    updateTaskAnswer: (state, { payload }) => {
      const { answer, id, questionUuid } = payload

      const taskIndex = state.tasks.findIndex((task) => task.id === id)
      state.tasks[taskIndex].answers[questionUuid] = answer
    },
    deleteTaskAnswer: (state, { payload }) => {
      const { id, questionUuid } = payload

      const taskIndex = state.tasks.findIndex((task) => task.id === id)
      delete state.tasks[taskIndex].answers[questionUuid]
    },
    removeTask: (state, { payload: taskId }) => {
      const task = state.tasks.find(({ id }) => id === taskId)
      task.isDeleted = true
    },
    recoverTask: (state, { payload: taskId }) => {
      const task = state.tasks.find(({ id }) => id === taskId)
      task.isDeleted = false
    },
    setShowDeletedTasks: (state, action) => {
      state.showOnlyDeletedTasks = action.payload
    },
    closeSubmission: (state, action) => {
      state.data.statusName = action.payload
      state.data.isClosed = true
    },
    reopenSubmission: (state, action) => {
      state.data.statusName = action.payload
      state.data.isClosed = false
    },
    setSubmissionStatus: (state, action) => {
      state.data.statusName = action.payload
    },
    setGroups: (state, action) => {
      state.groups = action.payload
    },
    setUsers: (state, action) => {
      state.users = action.payload
    },
    setDepartments: (state, action) => {
      state.departments = action.payload
    },
    setQuestionUuidsIdentifyingTaskDepartments: (state, action) => {
      state.questionUuidsIdentifyingTaskDepartments = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateAccessOverview.fulfilled, (state, action) => {
      state.accessOverview = action.payload
    })
  },
})

export const {
  setAccessOverview,
  setCategory,
  setForm,
  setFormSubmissionData,
  updateFormSubmissionAnswers,
  setSubmissionPermissions,
  setRelatedFormSubmissions,
  setTasks,
  toggleTaskExpanded,
  setAttachments,
  addTask,
  updateTask,
  updateTaskAnswer,
  deleteTaskAnswer,
  removeTask,
  recoverTask,
  setShowDeletedTasks,
  closeSubmission,
  reopenSubmission,
  setSubmissionStatus,
  setGroups,
  setUsers,
  setDepartments,
  setQuestionUuidsIdentifyingTaskDepartments,
} = formSubmissionSlice.actions

export const accessOverview = (state) => state.formSubmission.accessOverview

export const formSubmissionMembers = (state) => state.formSubmission.accessOverview.members

export const formSubmissionNonMembersWithAccess = (state) => state.formSubmission.accessOverview.nonMembersWithAccess

export const category = (state) => state.formSubmission.category

export const form = (state) => state.formSubmission.form

export const formSubmissionData = (state) => state.formSubmission.data

export const formSubmissionIsClosed = (state) => state.formSubmission.data.isClosed

export const formSubmissionId = (state) => state.formSubmission.data.id

export const formSubmissionSlug = (state) => state.formSubmission.data.slug

export const submissionPermissions = (state) => state.formSubmission.submissionPermissions

export const relatedFormSubmissions = (state) => state.formSubmission.relatedFormSubmissions

export const allTasks = (state) => state.formSubmission.tasks

export const getUndiscardedTasks = (state) => state.formSubmission.tasks.filter((task) => !task.isDeleted)

export const getTasks = (state) => {
  const { tasks, showOnlyDeletedTasks } = state.formSubmission

  let filteredTasks = tasks

  if (showOnlyDeletedTasks) {
    filteredTasks = filteredTasks.filter((task) => task.isDeleted)
  } else {
    filteredTasks = getUndiscardedTasks(state)
  }

  return filteredTasks
}

export const showingDeletedTasks = (state) => state.formSubmission.showOnlyDeletedTasks

export const groups = (state) => state.formSubmission.groups

export const users = (state) => state.formSubmission.users

export const departments = (state) => state.formSubmission.departments

export const activeDepartments = (state) => state.formSubmission.departments.filter((dept) => dept.active)

export const attachments = (state) => state.formSubmission.attachments

export const questionUuidsIdentifyingTaskDepartments = (state) => state.formSubmission.questionUuidsIdentifyingTaskDepartments

export default formSubmissionSlice.reducer
