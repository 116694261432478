import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { submissionPermissions } from "reduxSlices/formSubmissionSlice"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import { FormIcon } from "shared/icons"
import ChangeSubmissionFormType from "./ChangeSubmissionFormType"
import EditSubmissionButton from "./EditSubmissionButton"

const SubmissionFormTitle = ({ closeEditMode, editMode, openEditMode }) => {
  const { form } = useFormSubmissionFormContext()
  const { canEditSubmission } = useSelector(submissionPermissions)

  return (
    <div
      className="flex justify-center items-center p-3"
      style={{ boxShadow: "inset 0px -2px 0px #E5E7F5" }}
    >
      <FormIcon form={form} />
      <span className={clsx("ml-2.5 font-medium text-xl", editMode && "mr-4")}>
        { form?.title }
      </span>
      {
        editMode
          ? <ChangeSubmissionFormType onFormTypeChange={closeEditMode} />
          : (
            canEditSubmission && (
              <EditSubmissionButton onClick={openEditMode} className="absolute right-3" />
            )
          )
      }
    </div>
  )
}

SubmissionFormTitle.propTypes = {
  closeEditMode: types.func.isRequired,
  editMode: types.bool.isRequired,
  openEditMode: types.func.isRequired,
}

export default SubmissionFormTitle
