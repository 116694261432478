import React from "react"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { getFormSectionUuids } from "reduxSlices/formBuilderSlice"
import SectionSidebar from "views/FormBuilder/SectionFields/SectionSidebar"

const DesignSidebar = () => {
  const sectionUuids = useSelector(getFormSectionUuids)

  return (
    <div
      className={clsx(
        "fixed right-0 bottom-0 w-1/5",
        "overflow-y-auto text-sm",
        "border-l border-lightgray-3 bg-light-100",
        "z-20",
      )}
      style={{
        height: "calc(100% - 7.1rem)",
        minWidth: "260px",
        boxShadow: "inset 1px 0px 4px #D9DCF2",
      }}
    >
      {
        sectionUuids.map((sectionUuid, sectionIndex) => (
          <SectionSidebar key={sectionUuid} sectionIndex={sectionIndex} />
        ))
      }
    </div>
  )
}

export default DesignSidebar
