import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"
import { formSubmissionId as getFormSubmissionId } from "reduxSlices/formSubmissionSlice"
import { errorToast } from "components/shared/toast"
import * as API from "services/api"
import queryParameter from "utils/queryParameter"
import { useModal } from "hooks"
import { SearchInput } from "shared/inputs"
import Modal, { ModalHeader, DEFAULT_MODAL_STYLE } from "shared/Modal"
import AddRelatedFormSubmissionButton from "./AddRelatedFormSubmissionButton"
import SearchResults from "./SearchResults"

const searchQueryParams = queryParameter("searchQuery") || ""

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    height: "60%",
    overflowY: "hidden",
  },
}

const AddRelatedFormSubmission = () => {
  const { modalIsOpen, openModal, closeModal } = useModal()

  const [searchQuery, setSearchQuery] = useState(searchQueryParams)
  const [searchInProgress, setSearchInProgress] = useState(false)
  const [hasCompletedSearch, setHasCompletedSearch] = useState(false)
  const [results, setResults] = useState([])

  const formSubmissionId = useSelector(getFormSubmissionId)

  const inputRef = useRef()

  const search = async (query) => {
    setSearchInProgress(true)
    const response = await API.searchForRelatedFormSubmissionOptions({ query, formSubmissionId })
    if (response.ok) {
      setResults(response.data)
      setSearchInProgress(false)
      setHasCompletedSearch(true)
    } else {
      setSearchInProgress(false)
      errorToast(response.data.errors.join(", "));
    }
  }

  const clearSearchQueryAndResults = () => {
    setSearchQuery("")
    setResults([])
    setHasCompletedSearch(false)
  }

  const close = () => {
    closeModal()
    clearSearchQueryAndResults()
  }

  return (
    <>
      <div className="mb-10">
        <AddRelatedFormSubmissionButton
          onClick={openModal}
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => inputRef.current?.focus()}
        style={MODAL_STYLE}
      >
        <ModalHeader
          closeModal={close}
          heading="New Related Form Submission"
        />

        <p className="mb-8 text-center text-sm">
          Type your search query here and click on a result to add a Related Form Submission.
        </p>
        <div className="mb-8">
          <SearchInput
            className="related-form-submission-search-input"
            ref={inputRef}
            search={search}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <SearchResults
          close={close}
          hasCompletedSearch={hasCompletedSearch}
          results={results}
          searchInProgress={searchInProgress}
        />
      </Modal>
    </>
  )
}

export default AddRelatedFormSubmission
