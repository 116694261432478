import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { formSubmissionIsClosed, submissionPermissions } from "reduxSlices/formSubmissionSlice"
import AddTaskButton from "./AddTaskButton"

const AddTask = ({ openForm }) => {
  const { canAssignTask, canAssignTaskWithSubform } = useSelector(submissionPermissions)

  const submissionIsClosed = useSelector(formSubmissionIsClosed)

  const canAddTask = (canAssignTask || canAssignTaskWithSubform) && !submissionIsClosed

  return (
    <AddTaskButton className={clsx("mb-8", !canAddTask && "invisible")} openForm={openForm} />
  )
}

AddTask.propTypes = {
  openForm: types.func.isRequired,
}

export default AddTask
