import React from "react"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { currentUser as getCurrentUser } from "reduxSlices/sessionSlice"
import { ProfileIcon } from "shared/icons"
import Logout from "./Logout"

const CurrentUser = () => {
  const currentUser = useSelector(getCurrentUser, isEqual)

  return (
    <div className="bg-primary-light p-4">
      <p className="pb-4 font-bold">My Account</p>
      <div className="flex">
        <div className="bg-white rounded-md border border-gray-800 mr-4 flex-grow flex items-stretch">
          <div className="py-4 px-8 border-r border-gray-800">
            <ProfileIcon className="w-12 h-12" user={currentUser} />
          </div>
          <div className="flex flex-col p-4">
            <span>{ currentUser.fullName }</span>
            <span>{ currentUser.email }</span>
          </div>
        </div>
        <div className="bg-white rounded-md border border-gray-800 p-4 flex justify-between items-center">
          <Logout />
        </div>
      </div>
    </div>
  )
}

export default CurrentUser
