import React from "react"
import Popup from "reactjs-popup";
import withReduxProvider from "shared/withReduxProvider"
import Panel from "./Panel"
import PanelTrigger from "./PanelTrigger"

const FeatureAnnouncementPanel = () => (
  <Popup
    className="feature-announcement-popup"
    trigger={<div><PanelTrigger /></div>}
    position="bottom right"
  >
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
    <div className="outline-none" tabIndex={0}>
      <Panel />
    </div>
  </Popup>
)

export default withReduxProvider(FeatureAnnouncementPanel)
