import React from "react"
import ActivityIndicator from "shared/ActivityIndicator"

const UploadingFormSubmissionAttachments = () => (
  <div className="flex flex-col w-full items-center">
    <ActivityIndicator className="mb-4" />
    <span>Uploading attachments ...</span>
  </div>
)

export default UploadingFormSubmissionAttachments
