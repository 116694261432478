import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import { FormsyTextInput } from "shared/inputs"

const ShortAnswerField = ({
  className,
  edit,
  initialValue,
  question,
  ...rest
}) => {
  const { uuid, required } = question

  const validations = {
    noExcelMetacharacters: true,
    noHTML: true,
  }

  if (required && !edit) {
    validations.isNotBlankString = true
  }

  return (
    <FormsyTextInput
      className={clsx("form-input", className)}
      name={uuid}
      value={initialValue ?? ""}
      validations={validations}
      {...rest}
    />
  )
}

ShortAnswerField.defaultProps = {
  edit: false,
  initialValue: null,
  disabled: false,
  className: "",
  placeholder: "",
  hasBaseInputStyle: false,
}

ShortAnswerField.propTypes = {
  edit: types.bool,
  question: questionShape.isRequired,
  initialValue: types.string,
  disabled: types.bool,
  className: types.string,
  placeholder: types.string,
  hasBaseInputStyle: types.bool,
}

export default ShortAnswerField
