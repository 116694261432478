import React from "react"
import clsx from "clsx"
import Copyright from "shared/Copyright"
import { ACTIVITY_HUB_LOGIN_PATH } from "utils/routeHelpers"

const PublicFooter = () => (
  <div className={clsx(
    "fixed transform translate-x-1/2 right-1/2 bottom-0 w-4/5",
    "pb-8 text-sm text-center text-dark -z-10",
  )}
  >
    <a
      className="font-semibold leading-10 underline text-dark"
      href={ACTIVITY_HUB_LOGIN_PATH}
    >
      Login
    </a>
    <span> to Activity Hub</span>
    <Copyright />
  </div>
)

export default PublicFooter
