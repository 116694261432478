import React from "react"
import types from "prop-types"
import UserInfo from "shared/UserInfo"

const GroupSearchGroupMemberList = ({ members }) => {
  if (!members.length) {
    return <div className="m-5">No Members</div>
  }

  return (
    <div className="mx-5 bg-primary-light">
      {
        members.map((member) => (
          <UserInfo
            key={member.id}
            user={member}
            className="flex-grow mb-1 bg-white list-row"
          />
        ))
      }
    </div>
  )
}

GroupSearchGroupMemberList.propTypes = {
  members: types.arrayOf(types.object).isRequired,
}

export default GroupSearchGroupMemberList
