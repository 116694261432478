import React from "react"
import types from "prop-types"
import SidebarVisibilityControl from "shared/Sidebar/SidebarVisibilityControl"
import { PrimaryButton } from "components/shared/buttons"
import { useFormSubmissionAttachmentDropzoneContext } from "contexts/FormSubmissionAttachmentDropzoneContext"

const AttachmentsHeader = ({ hiddenSidebar, toggleSidebar }) => {
  const { openAttachmentFileDialog } = useFormSubmissionAttachmentDropzoneContext()

  return (
    <div className="flex-center mt-20">
      <SidebarVisibilityControl hiddenSidebar={hiddenSidebar} toggleSidebar={toggleSidebar} />
      <h1 className="header-title">Attachments</h1>
      <PrimaryButton text="+ Upload File" onClick={openAttachmentFileDialog} />
    </div>
  )
}

AttachmentsHeader.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default AttachmentsHeader
