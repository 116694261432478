import types from "prop-types"
import { evaluateSectionBranchingConditionals } from "utils/QuestionBranching"
import { sectionShape } from "utils/propTypeShapes"

const BaseSectionConditionalCheck = ({
  children,
  isFirstSection,
  formAnswers,
  operatorData,
  section,
}) => (
  isFirstSection || evaluateSectionBranchingConditionals({ formAnswers, operatorData, section })
    ? children
    : null
)

BaseSectionConditionalCheck.defaultProps = {
  operatorData: {},
}

BaseSectionConditionalCheck.propTypes = {
  children: types.node.isRequired,
  isFirstSection: types.bool.isRequired,
  formAnswers: types.object.isRequired,
  operatorData: types.object,
  section: sectionShape.isRequired,
}

export default BaseSectionConditionalCheck
