import React from "react"
import MediumWrap from "shared/wraps/MediumWrap"
import IconSetting from "./IconSetting"
import FormTooltipDescription from "./FormTooltipDescription"
import FormSolutionSetting from "./FormSolutionSetting"
import FormAcceptsSubmittedFilesSetting from "./FormAcceptsSubmittedFilesSetting"
import FormActiveSetting from "./FormActiveSetting"
import FormListedSetting from "./FormListedSetting"
import FormPublicSubmissionStatusSetting from "./FormPublicSubmissionStatusSetting"

const FormSettingsTab = () => (
  <MediumWrap className="mt-32">
    <h1 className="py-6 header-title text-center">Form Settings</h1>
    <div className="p-12 bg-white border rounded-lg border-lightgray-3">
      <IconSetting />
      <FormSolutionSetting />
      <FormActiveSetting />
      <FormListedSetting />
      <FormAcceptsSubmittedFilesSetting />
      <FormPublicSubmissionStatusSetting />
      <FormTooltipDescription />
    </div>
  </MediumWrap>
)

export default FormSettingsTab
