import React, { Component, createRef } from "react"
import types from "prop-types"
import clsx from "clsx"
import { connect } from "react-redux"
import Formsy from "formsy-react"
import { isEmpty, isEqual } from "lodash-es"
import * as API from "services/api"
import removeQueryStringFromLocation from "utils/removeQueryStringFromLocation"
import { questionTagAnswers } from "utils/formDataHelpers"
import { surveyTokenPublicDetailsShape } from "utils/propTypeShapes"
import { redirectPathAfterFormSubmission } from "utils/routeHelpers"
import { FormIcon } from "shared/icons"
import Copyright from "shared/Copyright"
import withReduxProvider from "shared/withReduxProvider"
import withStoredQuestionTags from "shared/withStoredQuestionTags"
import { errorToast } from "shared/toast"
import FormTitle from "./FormElements/FormTitle"
import FormDescription from "./FormElements/FormDescription"
import FormSection from "./FormElements/FormSection"
import SubmitFormButton from "./FormElements/SubmitFormButton"
import SurveyDetails from "./FormElements/SurveyDetails"
import FilesToUploadCheckbox from "./FormElements/Attachments/FilesToUploadCheckbox"

export const HAS_UPLOADS_ADDITIONAL_TEXT = "Files can be uploaded after submitting form."

class Form extends Component {
  state = {
    canSubmit: false, submitting: false, formLoadedAt: new Date(), hasUploads: false,
  }

  formRef = createRef()

  componentDidMount() {
    window.addEventListener("beforeunload", removeQueryStringFromLocation)
  }

  componentDidUpdate(prevProps) {
    const { form, questionTags } = this.props
    const { questionTags: prevQuestionTags } = prevProps

    if (!isEqual(questionTags, prevQuestionTags)) {
      this.updateFormWithAnswers(questionTagAnswers(form, questionTags))
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", removeQueryStringFromLocation)
  }

  enableSubmit = () => this.setState({ canSubmit: true })

  disableSubmit = () => this.setState({ canSubmit: false })

  submitForm = async (model) => {
    const { form, token } = this.props
    const { slug } = form
    const { formLoadedAt, hasUploads } = this.state

    this.setState({ submitting: true })

    let response
    if (token) {
      response = await API.createSurveyFormSubmission({ answers: model, formLoadedAt, token })
    } else {
      response = await API.createFormSubmission({ answers: model, formLoadedAt, slug })
    }

    const redirect = redirectPathAfterFormSubmission(response, slug, hasUploads)
    if (redirect) {
      window.location.replace(`${redirect}`)
    } else {
      this.setState({ submitting: false })
      errorToast(`Something went wrong.  Unable to save form submission: ${response.data.errors.join(", ")}`)
    }
  }

  formAnswers = () => this.formRef.current?.getModel() || {}

  updateFormWithAnswers = (answers) => this.formRef.current?.updateInputsWithValue(answers)

  toggleHasUploads = () => {
    this.setState(({ hasUploads }) => ({ hasUploads: !hasUploads }))
  }

  render() {
    const { form, surveyDetails } = this.props
    const {
      title, description, sections, acceptsSubmittedFiles,
    } = form
    const { canSubmit, submitting, hasUploads } = this.state

    const renderSurveyDetails = !isEmpty(surveyDetails)

    return (
      <div>
        <div className="mx-auto mt-28 w-11/12 md:w-4/5 lg:w-3/5 mb-10 md:mb-40">
          <div className="bg-white rounded-t-xl px-8">
            <FormIcon
              className="h-20 w-20 sm:h-24 sm:w-24 absolute transform -translate-x-1/2 left-1/2 -translate-y-1/2"
              form={form}
            />
            <div
              className={clsx(
                "public-form-content flex flex-col items-center text-center",
                renderSurveyDetails && "mb-8",
              )}
            >
              <FormTitle>{title}</FormTitle>
              <FormDescription>{description}</FormDescription>
            </div>
            {
              renderSurveyDetails && (
                <SurveyDetails
                  className="mx-16 p-4 rounded border border-lightgray-3"
                  surveyDetails={surveyDetails}
                />
              )
            }
          </div>
          <Formsy
            id="form"
            ref={this.formRef}
            autoComplete="off"
            onValidSubmit={this.submitForm}
            onValid={this.enableSubmit}
            onInvalid={this.disableSubmit}
            disabled={submitting}
            preventDefaultSubmit
          >
            <div>
              <fieldset disabled={submitting}>
                {
                  sections.map((section, sectionIndex) => (
                    <FormSection
                      key={section.uuid}
                      section={section}
                      isFirstSection={sectionIndex === 0 && !acceptsSubmittedFiles}
                      isLastSection={sectionIndex === sections.length - 1}
                      formAnswers={this.formAnswers()}
                      isSubform={false}
                      canSubmit={canSubmit}
                    />
                  ))
                }
                {
                  acceptsSubmittedFiles
                  && (
                    <FilesToUploadCheckbox
                      checked={hasUploads}
                      onChange={this.toggleHasUploads}
                    />
                  )
                }

              </fieldset>
            </div>
            <div className="bg-white pb-20 rounded-b-xl">
              <SubmitFormButton canSubmit={canSubmit} submitting={submitting} />
              {
                hasUploads
                && (
                  <span className="flex justify-center mt-2 text-sm">
                    {HAS_UPLOADS_ADDITIONAL_TEXT}
                  </span>
                )
              }
            </div>
          </Formsy>
        </div>
        <Copyright className="text-center mb-10" />
      </div>
    )
  }
}

Form.defaultProps = {
  token: "",
  surveyDetails: {},
}

Form.propTypes = {
  form: types.shape({
    title: types.string.isRequired,
    description: types.string,
    sections: types.arrayOf(types.object),
    slug: types.string.isRequired,
    acceptsSubmittedFiles: types.bool.isRequired,
  }).isRequired,
  questionTags: types.arrayOf(types.shape({
    id: types.number.isRequired,
    name: types.string.isRequired,
  })).isRequired,
  token: types.string,
  surveyDetails: surveyTokenPublicDetailsShape,
}

const mapStateToProps = (state) => ({
  questionTags: state.questionTags.questionTags,
})

export default (
  withReduxProvider(
    withStoredQuestionTags(
      connect(mapStateToProps)(Form),
    ),
  )
)
