import React from "react"
import types from "prop-types"

const Answer = ({ rawAnswer, isNewQuestion }) => {
  if (isNewQuestion) return null

  return (
    <span className="font-medium text-lg bg-lightgray w-1/2 py-1.5 px-3 whitespace-pre-wrap break-words">
      { rawAnswer }
    </span>
  )
}

Answer.defaultProps = {
  rawAnswer: "",
  isNewQuestion: false,
}

Answer.propTypes = {
  rawAnswer: types.oneOfType([types.string, types.number]),
  isNewQuestion: types.bool,
}

export default Answer
