import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { publicSolutionPath } from "utils/routeHelpers"

const CATEGORY_COLORS = ["bg-green", "bg-purple", "bg-orange", "bg-red"];

const getCategoryBackgroundColor = (index, categoryColor) => {
  if (categoryColor) {
    return `bg-${categoryColor}`;
  }
  return CATEGORY_COLORS[index % 4];
}

const gridColCount = window.innerWidth < 600 ? "1" : "auto-fit"
const categoryListGridStyle = {
  display: "grid",
  gridTemplateColumns: `repeat(${gridColCount}, minmax(280px, calc(33% - 25px)))`,
  gridAutoRows: "minmax(180px, 1fr)",
  placeItems: "center",
  justifyContent: "center",
  gap: "40px",
}

const goToPublicSolution = (slug) => () => {
  window.location.pathname = publicSolutionPath(slug)
}

const PublicCategory = ({ categories }) => (
  <div style={categoryListGridStyle} className="sm:mt-10 mb-40 w-full">
    {
      categories.map((category, index) => (
        <div
          key={category.slug}
          className={clsx(
            "w-full h-full relative",
            "border border-lightgray-3 shadow-sm bg-white",
            "cursor-pointer rounded-2xl public-category-card",
          )}
          onClickCapture={goToPublicSolution(category.slug)}
        >
          <div className="px-8 py-7">
            <div className="mb-2 text-lg font-semibold">
              { category.name }
            </div>
            <div className="leading-5 mb-11">
              { category.description }
            </div>
          </div>
          <div
            className={clsx(
              "absolute bottom-0 w-full h-12 rounded-b-2xl",
              getCategoryBackgroundColor(index, category.color),
            )}
          />
        </div>
      ))
    }
  </div>
)

PublicCategory.propTypes = {
  categories: types.arrayOf(types.object).isRequired,
}

export default PublicCategory
