import React from "react"
import types from "prop-types"
import { departmentIdentifier } from "utils/taskHelpers"
import TaskDepartment from "../TaskDepartment"

const TaskDepartmentList = ({
  className,
  departments,
  removeDepartment,
  toggleNotify,
  toggleNotifyOnComplete,
  departmentLabelComponent,
  segmentsComponent,
}) => {
  const removeFromTask = (dept) => () => removeDepartment(departmentIdentifier(dept))

  return (
    <div className={className}>
      <div className="flex justify-between items-end font-semibold text-sm text-right pr-9 mb-2">
        <div>
          <span>Departments</span>
        </div>
        <div className="flex gap-x-2">
          <div className="w-20 text-center">Notify on Assign</div>
          <div className="w-20 text-center">Notify on Complete</div>
        </div>
      </div>
      {
        departments.map((department) => (
          <TaskDepartment
            key={`task-department-${department.departmentId ?? department.questionUUID}`}
            departmentSelection={department}
            removeFromTask={removeFromTask(department)}
            toggleNotify={toggleNotify(department)}
            toggleNotifyOnComplete={toggleNotifyOnComplete(department)}
            labelComponent={departmentLabelComponent}
            segmentsComponent={segmentsComponent}
          />
        ))
      }
    </div>
  )
}

TaskDepartmentList.defaultProps = {
  className: "",
}

TaskDepartmentList.propTypes = {
  className: types.string,
  departments: types.arrayOf(types.object).isRequired,
  removeDepartment: types.func.isRequired,
  toggleNotify: types.func.isRequired,
  toggleNotifyOnComplete: types.func.isRequired,
  departmentLabelComponent: types.elementType.isRequired,
  segmentsComponent: types.elementType.isRequired,
}

export default TaskDepartmentList
