import React from "react"
import { useSelector } from "react-redux"
import { getGroups, getUsers } from "reduxSlices/formBuilderSlice"
import { workflowActionShape } from "utils/propTypeShapes"
import { NOTIFY_ALL_MEMBERS } from "utils/workflowHelpers"
import { WorkflowActionSendNotificationIcon } from "shared/icons"
import ActionDetails from "./ActionDetails"
import ActionAbbreviatedUserList from "./ActionAbbreviatedUserList"
import ActionHeading from "./ActionHeading"
import ActionLabelContainer from "./ActionLabelContainer"

const AllMembersDetails = () => (
  <ActionDetails>
    <span className="workflow-label">all submission members</span>
  </ActionDetails>
)

const SpecificUsersDetails = ({ action }) => {
  const { groupIds, userIds } = action.data.recipients

  const groupsToNotify = useSelector(getGroups).filter((potentialGroup) => (
    groupIds.includes(potentialGroup.id)
  ))

  const usersToNotify = useSelector(getUsers).filter((potentialUser) => (
    userIds.includes(potentialUser.id)
  ))

  return (
    <ActionDetails>
      <span>
        {
          groupsToNotify.map((group) => (
            <span key={group.id} className="workflow-label mr-2 mb-2">{group.name}</span>
          ))
        }
      </span>
      <ActionAbbreviatedUserList users={usersToNotify} />
    </ActionDetails>
  )
}

SpecificUsersDetails.propTypes = {
  action: workflowActionShape.isRequired,
}

const SendNotificationDetails = ({ action }) => (
  action.subtype === NOTIFY_ALL_MEMBERS
    ? <AllMembersDetails />
    : <SpecificUsersDetails action={action} />
)

SendNotificationDetails.propTypes = {
  action: workflowActionShape.isRequired,
}

const SendNotificationActionLabel = ({ action }) => (
  <ActionLabelContainer>
    <ActionHeading
      iconComponent={WorkflowActionSendNotificationIcon}
      text="Send notification:"
    />
    <SendNotificationDetails action={action} />
  </ActionLabelContainer>
)

SendNotificationActionLabel.propTypes = {
  action: workflowActionShape.isRequired,
}

export default SendNotificationActionLabel
