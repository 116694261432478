import React from "react"
import types from "prop-types"
import clsx from "clsx"

const SubmissionQuestion = ({
  children,
  className,
  description,
  isNewQuestion,
  prompt,
  required,
}) => (
  <div
    className={
      clsx(
        className,
        "flex flex-col px-10 py-6 mb-4 border border-lightgray-3 rounded-lg relative",
        isNewQuestion && "submission-new-form-question",
      )
    }
  >
    <span
      className={clsx(
        !isNewQuestion && !description && "mb-4",
        !isNewQuestion && "text-dark",
        "font-medium text-lg",
      )}
    >
      { prompt } { required && "*" }
    </span>
    {
      description && <p className="font-normal text-sm mb-4">{description}</p>
    }
    { children }
    {
      (isNewQuestion) && (
        <span className={clsx(
          "absolute right-1 top-1",
          "font-semibold text-sm text-task-form-color",
          "px-1.5 py-1 tracking-wide bg-white rounded-md",
        )}
        >
          NEW
        </span>
      )
    }
  </div>
)

SubmissionQuestion.defaultProps = {
  children: null,
  className: "",
  description: undefined,
  isNewQuestion: false,
  required: false,
}

SubmissionQuestion.propTypes = {
  children: types.node,
  className: types.string,
  description: types.string,
  isNewQuestion: types.bool,
  prompt: types.string.isRequired,
  required: types.bool,
}

export default SubmissionQuestion
