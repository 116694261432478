import React from "react"
import types from "prop-types"
import Select from "./Select"

// Values must match access levels in AccessLevelable
const OPTIONS = [
  { label: "Super", value: "super_access" },
  { label: "Standard", value: "standard_access" },
  { label: "None", value: "none_access" },
]

const AccessLevelSelect = ({ onChange, value = null, isDisabled = false }) => (
  <Select
    backspaceDelete={false}
    onChange={onChange}
    options={OPTIONS}
    required
    disabled={isDisabled}
    value={value}
  />
)

AccessLevelSelect.propTypes = {
  onChange: types.func.isRequired,
  value: types.string,
  isDisabled: types.bool,
}

export default AccessLevelSelect
