import React from "react"
import types from "prop-types"

// Match defaults in app/helpers/svg_sprite_helpers
const DEFAULT_WIDTH = 20
const DEFAULT_HEIGHT = 20

const SvgSpriteIcon = ({
  className = "",
  height = DEFAULT_HEIGHT,
  iconName,
  onClick = () => {},
  style = null,
  svgIdAttribute = "",
  width = DEFAULT_WIDTH,
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    onClick={onClick}
    role="img"
    style={style}
    aria-label={`${iconName} icon`}
  >
    <use id={svgIdAttribute} href={`#${iconName}`} />
  </svg>
)

SvgSpriteIcon.propTypes = {
  className: types.string,
  svgIdAttribute: types.string,
  iconName: types.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: types.object,
  width: types.number,
  height: types.number,
  onClick: types.func,
}

export default SvgSpriteIcon
