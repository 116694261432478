import React from "react"
import types from "prop-types"
import { questionShape, taskShape } from "utils/propTypeShapes"
import TaskQuestion from "FormSubmission/FormSubmissionTabs/ActivitiesContent/ActivitiesMain/Task/TaskQuestion"

const SubformSectionQuestions = ({ questions, task }) => (
  questions.map((question) => (
    <TaskQuestion
      key={question.uuid}
      question={question}
      task={task}
    />
  ))
)

SubformSectionQuestions.propTypes = {
  questions: types.arrayOf(questionShape).isRequired,
  task: taskShape.isRequired,
}

export default SubformSectionQuestions
