import React from "react"
import types from "prop-types"
import { connect } from "react-redux"
import { FacilityAuditTrailContextProvider } from "contexts/FacilityAuditTrailContext"
import FacilityAuditTrailGroups from "./FacilityAuditTrailGroups"
import LoadMoreButton from "./LoadMoreButton"

const AuditTrailTab = ({ facilityId }) => (
  (
    <FacilityAuditTrailContextProvider facilityId={facilityId}>
      <FacilityAuditTrailGroups />
      <LoadMoreButton />
    </FacilityAuditTrailContextProvider>
  )
)

AuditTrailTab.defaultProps = {
  facilityId: "",
}

AuditTrailTab.propTypes = {
  facilityId: types.number,
}

const mapStateToProps = (state) => (
  {
    facilityId: state.adminFacility.id,
  }
)

export default connect(mapStateToProps, {})(AuditTrailTab)
