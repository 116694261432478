import React from "react"
import types from "prop-types"
import { SideTabs } from "shared/tabs"
import AttributesTab from "./AttributesTab"
import GeneralTab from "./GeneralTab"
import DepartmentsTab from "./DepartmentsTab"
import AuditTrailTab from "./AuditTrailTab"

const TABS = (tabProps) => [
  {
    title: "General",
    TabContent: GeneralTab,
    props: tabProps,
  },
  {
    title: "Attributes",
    TabContent: AttributesTab,
    props: tabProps,
  },
  {
    title: "Departments",
    TabContent: DepartmentsTab,
    props: tabProps,
  },
  {
    title: "Audit Trail",
    TabContent: AuditTrailTab,
    props: tabProps,
  },
]

const FacilityTabs = ({ tabProps }) => (
  <SideTabs tabs={TABS(tabProps)} />
)

FacilityTabs.defaultProps = {
  tabProps: {},
}

FacilityTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabProps: types.object,
}

export default FacilityTabs
