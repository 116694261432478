import React, { useEffect, useMemo, useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import DraggableUser from "./DraggableUser"

const UserSourceList = ({ className, nameFilter }) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    const loadUsers = async () => {
      const response = await API.getUsers({ active: true })

      if (response.ok) {
        setUsers(response.data)
      }
    }

    loadUsers()
  }, [])

  const filteredUsers = useMemo(() => {
    if (!nameFilter) return users

    const matcher = new RegExp(nameFilter, "i")

    return users.filter((user) => (
      matcher.test(user.fullName)
    ))
  }, [nameFilter, users])

  return (
    <div className={className}>
      <ol>
        {
          filteredUsers.map((user) => (
            <li key={user.id} className="mb-4 last:mb-0">
              <DraggableUser user={user} />
            </li>
          ))
        }
      </ol>
    </div>
  )
}

UserSourceList.defaultProps = {
  className: "",
  nameFilter: "",
}

UserSourceList.propTypes = {
  className: types.string,
  nameFilter: types.string,
}

export default UserSourceList
