import React from "react"
import types from "prop-types"
import TaskUser from "../TaskUser"

const TaskUserList = ({ className, removeUser, users }) => {
  const removeFromTask = (id) => () => removeUser(id)

  return (
    <div className={className}>
      <div className="flex justify-between items-end font-semibold text-sm text-right text-end pr-9 mb-2">
        <div>
          <span>Users</span>
        </div>
        <div className="flex gap-x-2">
          <div className="w-20 text-center">Notify on Assign</div>
          <div className="w-20 text-center">Notify on Complete</div>
        </div>
      </div>
      {
        users.map((user) => (
          <TaskUser key={`assignee-${user.id}`} removeFromTask={removeFromTask(user.id)} user={user} />
        ))
      }
    </div>
  )
}

TaskUserList.defaultProps = {
  className: "",
}

TaskUserList.propTypes = {
  className: types.string,
  removeUser: types.func.isRequired,
  users: types.arrayOf(types.object).isRequired,
}

export default TaskUserList
