import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { workflowActionShape } from "utils/propTypeShapes"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { DEFAULT_SUBTYPE, subtypeConnectorText } from "utils/workflowHelpers"

const DefaultActionLabel = ({ action, className }) => {
  const { type, subtype } = action

  return (
    <div className={clsx(className, "flex items-center")}>
      {
        type && (
          <span className="workflow-white-label mr-2">{snakeToCapitalCase(type)}</span>
        )
      }
      {
        subtype && subtype !== DEFAULT_SUBTYPE && (
          <>
            <span className="mr-2">{subtypeConnectorText(type)}</span>
            <span className="workflow-white-label">{snakeToCapitalCase(subtype)}</span>
          </>
        )
      }
    </div>
  )
}

DefaultActionLabel.defaultProps = {
  className: "",
}

DefaultActionLabel.propTypes = {
  action: workflowActionShape.isRequired,
  className: types.string,
}

export default DefaultActionLabel
