/* eslint-disable camelcase */
import React from "react"
import types from "prop-types"
import { taskShape, questionShape, userOptionShape } from "utils/propTypeShapes"
import { orgLocalDateTime } from "utils/dateHelpers"
import { ProfileIcon } from "shared/icons"
import SubformAnswerEntryMenu from "./SubformAnswerEntryMenu"

const SubformAnswerEntryCard = ({ task, question, entry }) => {
  const {
    answer, created_at, user,
  } = entry

  return (
    <div className="flex bg-light-100 p-3 rounded-lg mb-3 border border-lightgray-3">
      <ProfileIcon className="shrink-0 mr-3" user={user} />
      <div className="grow">
        <div className="flex w-full min-h-8 mb-2">
          <span className="grow mr-4 font-semibold text-sm break-anywhere">
            { user.fullName }
          </span>
          <span className="shrink-0 mr-4 text-sm">
            { orgLocalDateTime(created_at) }
          </span>
          <SubformAnswerEntryMenu
            className="shrink-0"
            task={task}
            question={question}
            entry={entry}
          />
        </div>
        <div className="whitespace-pre-wrap break-anywhere">
          { answer }
        </div>
      </div>
    </div>
  )
}

SubformAnswerEntryCard.propTypes = {
  task: taskShape.isRequired,
  question: questionShape.isRequired,
  entry: types.shape({
    answer: types.string.isRequired,
    created_at: types.string.isRequired,
    uuid: types.string.isRequired,
    user: userOptionShape.isRequired,
  }).isRequired,
}

export default SubformAnswerEntryCard
