import React from "react"
import StrikeAround from "shared/StrikeAround"
import { taskShape } from "utils/propTypeShapes"
import SubformSection from "Forms/FormElements/SubformElements/SubformSection"

const TaskSubform = ({ task }) => {
  const { subform } = task
  const { description, sections, title } = subform

  return (
    <div className="w-full">
      <StrikeAround className="mb-2">
        <span className="text-dark font-medium text-xl">{ title }</span>
      </StrikeAround>
      <p className="text-dark text-sm text-center">{ description }</p>
      {
        sections?.map((section, sectionIndex) => (
          <SubformSection
            key={section.uuid}
            section={section}
            task={task}
            isFirstSection={sectionIndex === 0}
          />
        ))
      }
    </div>
  )
}

TaskSubform.propTypes = {
  task: taskShape.isRequired,
}

export default TaskSubform
