import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { SidebarCollapsibleContent } from "shared/Sidebar"

const QuestionSidebarSection = ({
  children,
  description,
  hide,
  title,
  headerClassName,
}) => (
  <div className={clsx(hide && "hidden")}>
    <SidebarCollapsibleContent title={title} headerClassName={headerClassName}>
      {
        description && <p className="mb-6">{description}</p>
      }
      { children }
    </SidebarCollapsibleContent>
  </div>
)

QuestionSidebarSection.defaultProps = {
  description: "",
  headerClassName: "",
}

QuestionSidebarSection.propTypes = {
  children: types.node.isRequired,
  description: types.string,
  hide: types.bool.isRequired,
  title: types.string.isRequired,
  headerClassName: types.string,
}

export default QuestionSidebarSection
