import React from "react"
import types from "prop-types"
import { subtypeConnectorText } from "utils/workflowHelpers"

const ActionSubtypeConnector = ({ type }) => {
  const text = subtypeConnectorText(type)

  return (
    <p className="mx-2">{ text }</p>
  )
}

ActionSubtypeConnector.defaultProps = {
  type: undefined,
}

ActionSubtypeConnector.propTypes = {
  type: types.string,
}

export default ActionSubtypeConnector
