import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { SCIM_FEATURE_NAME } from "utils/addOnFeatureHelpers"

const UserExternalId = ({ user }) => {
  const subscribedToSCIM = useSelector(subscribedToAddOnFeature(SCIM_FEATURE_NAME))

  if (!subscribedToSCIM) return null

  return (
    <div className="w-full p-4 mb-2 rounded-lg bg-primary-light flex justify-between">
      <div className="font-bold">External Identifier</div>
      <div className="text-dark">{ user.externalIdentifier }</div>
    </div>
  )
}

UserExternalId.propTypes = {
  user: types.object.isRequired,
}

export default UserExternalId
