import React from "react"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { useModal } from "hooks"
import {
  formSubmissionData,
  questionUuidsIdentifyingTaskDepartments as getQuestionUuidsIdentifyingTaskDepartments,
} from "reduxSlices/formSubmissionSlice"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import { MediumWrap } from "shared/wraps"
import SubmissionFormTitle from "./SubmissionFormTitle"
import FormSubmissionAccessOverview from "./FormSubmissionAccessOverview"
import SubmissionEdit from "./SubmissionEdit"
import SubmissionOverview from "./SubmissionOverview"
import TaskDepartmentWarning from "./TaskDepartmentWarning"

const SubmissionContent = () => {
  // If a question is used to identify an assignee/member department
  // in a create-task action on one of the form's workflows,
  // then we want to warn the user that task assigments might need to be updated.
  const {
    modalIsOpen: taskDepartmentWarningIsVisible,
    openModal: showTaskDepartmentWarning,
    closeModal: hideTaskDepartmentWarning,
  } = useModal()

  const { answers: currentAnswers, slug } = useSelector(formSubmissionData)
  const questionUuidsIdentifyingTaskDepartments = useSelector(getQuestionUuidsIdentifyingTaskDepartments)

  const {
    form, editMode, closeEditMode, openEditMode,
  } = useFormSubmissionFormContext()
  if (!form.id) return null

  const checkUpdatedAnswers = ({ answers: updatedAnswers }) => {
    const taskDepartmentAnswerChanged = questionUuidsIdentifyingTaskDepartments.some((questionUuid) => (
      !isEqual(updatedAnswers[questionUuid], currentAnswers[questionUuid])
    ))

    if (taskDepartmentAnswerChanged) {
      showTaskDepartmentWarning()
    }
  }

  // Ensure that the form submission data is in Redux
  // before rendering
  if (!slug) return null

  return (
    <MediumWrap className="relative">
      <FormSubmissionAccessOverview />
      <div className="my-28 rounded-lg bg-white border-2 border-light-300 light-shadow-lg">
        <SubmissionFormTitle
          closeEditMode={closeEditMode}
          editMode={editMode}
          openEditMode={openEditMode}
        />
        {
          editMode
            ? <SubmissionEdit onCancel={closeEditMode} onSuccess={checkUpdatedAnswers} />
            : <SubmissionOverview />
        }
      </div>
      <TaskDepartmentWarning isOpen={taskDepartmentWarningIsVisible} close={hideTaskDepartmentWarning} />
    </MediumWrap>
  )
}

export default SubmissionContent
