export const PATIENT_FIRST_NAME_QUESTION_TAG = "patientFirstName"
export const PATIENT_LAST_NAME_QUESTION_TAG = "patientLastName"
export const PATIENT_MEDICAL_RECORD_NUMBER_QUESTION_TAG = "patientMedicalRecordNumber"
export const PATIENT_SEX_QUESTION_TAG = "patientSex"
export const PATIENT_DATE_OF_BIRTH_QUESTION_TAG = "patientDateOfBirth"
export const PATIENT_ADDRESS_STREET_ONE_QUESTION_TAG = "patientAddressStreetOne"
export const PATIENT_ADDRESS_STREET_TWO_QUESTION_TAG = "patientAddressStreetTwo"
export const PATIENT_ADDRESS_CITY_QUESTION_TAG = "patientAddressCity"
export const PATIENT_ADDRESS_STATE_QUESTION_TAG = "patientAddressState"
export const PATIENT_ADDRESS_ZIP_CODE_QUESTION_TAG = "patientAddressZipCode"
export const PATIENT_CELL_PHONE_QUESTION_TAG = "patientCellPhone"
export const PATIENT_HOME_PHONE_QUESTION_TAG = "patientHomePhone"

export const PATIENT_QUESTION_TAGS = [
  PATIENT_FIRST_NAME_QUESTION_TAG,
  PATIENT_LAST_NAME_QUESTION_TAG,
  PATIENT_MEDICAL_RECORD_NUMBER_QUESTION_TAG,
  PATIENT_SEX_QUESTION_TAG,
  PATIENT_DATE_OF_BIRTH_QUESTION_TAG,
  PATIENT_ADDRESS_STREET_ONE_QUESTION_TAG,
  PATIENT_ADDRESS_STREET_TWO_QUESTION_TAG,
  PATIENT_ADDRESS_CITY_QUESTION_TAG,
  PATIENT_ADDRESS_STATE_QUESTION_TAG,
  PATIENT_ADDRESS_ZIP_CODE_QUESTION_TAG,
  PATIENT_CELL_PHONE_QUESTION_TAG,
  PATIENT_HOME_PHONE_QUESTION_TAG,
]
