import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import FormattedNote from "./FormattedNote"

const NoteAnswerField = ({
  className,
  initialValue,
  question,
}) => {
  const { attributes: { note }, uuid } = question

  return (
    <div className={clsx(className)}>
      <FormattedNote
        name={uuid}
        value={note || initialValue}
      />
    </div>
  )
}

NoteAnswerField.defaultProps = {
  initialValue: null,
  className: "",
}

NoteAnswerField.propTypes = {
  question: questionShape.isRequired,
  initialValue: types.string,
  className: types.string,

}

export default NoteAnswerField
