import React from "react"
import types from "prop-types"
import AttachmentGrouping from "./AttachmentGrouping"

const AttachmentGroupingList = ({ attachmentGroups }) => {
  if (!Object.keys(attachmentGroups).length) {
    return <div>No Attachments</div>
  }

  return (
    Object.entries(attachmentGroups).map(([groupName, attachments]) => (
      <AttachmentGrouping
        key={groupName}
        groupName={groupName}
        attachments={attachments}
      />
    ))
  )
}

AttachmentGroupingList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  attachmentGroups: types.object.isRequired,
}

export default AttachmentGroupingList
