export const submissionAccessLevel = ({ categoryGrant, formGrant, formSubmissionGrant }) => {
  const applicableGrant = formSubmissionGrant || formGrant || categoryGrant

  return applicableGrant
}

export const formAccessExplanation = ({ categoryGrant, formGrant, formSubmissionGrant }) => {
  if (formSubmissionGrant) {
    if (categoryGrant) return "Overrides Solution grant"
    if (formGrant) return "Overrides Form grant"

    return "Granted by Submission"
  }

  if (formGrant) { return "Granted by Form" }

  return "Granted by Solution"
}
