import React from "react"
import types from "prop-types"
import { withFormsy } from "formsy-react"
import clsx from "clsx"

const RadioButton = ({
  className = "",
  label = "",
  name = "",
  value,
  checked,
  onChange,
  disabled = false,
}) => {
  const cursorClass = disabled ? "cursor-not-allowed" : "cursor-pointer"

  return (
    <div
      className={clsx(className, cursorClass, "flex gap-2 items-center")}
      onClickCapture={() => !disabled && onChange(value)}
    >
      <input
        type="radio"
        className={cursorClass}
        id={`${name}-${value}`}
        name={name}
        disabled={disabled}
        value={value}
        checked={checked}
        onChange={(event) => onChange(event.target.value)}
      />
      <label className={cursorClass} htmlFor={`${name}-${value}`}>{ label || value }</label>
    </div>
  )
}

RadioButton.propTypes = {
  name: types.string,
  className: types.string,
  label: types.string,
  value: types.string.isRequired,
  checked: types.bool.isRequired,
  onChange: types.func.isRequired,
  disabled: types.bool,
}

export const FormsyRadioButton = withFormsy(({ setValue, ...rest }) => (
  <RadioButton
    onChange={setValue}
    {...rest}
  />
))

export default RadioButton
