import React, { useEffect } from "react"
import { taskShape } from "utils/propTypeShapes"
import { store } from "store"
import { toggleTaskExpanded } from "reduxSlices/formSubmissionSlice"
import queryParameter from "utils/queryParameter"
import TaskIcon from "./TaskIcon"

export const taskTextIdentifier = (task) => task.subform?.title ?? task.title

const TaskShortcut = ({ task }) => {
  const toggleExpanded = () => {
    store.dispatch(toggleTaskExpanded(task.id))

    const taskEl = document.querySelector(`#task-${task.id}`)
    if (taskEl) {
      const scrollOptions = { behavior: "smooth", block: "start", inline: "start" }
      setTimeout(() => taskEl.scrollIntoView(scrollOptions), 100)
    }
  }

  useEffect(() => {
    const taskId = Number(queryParameter("taskId"))
    if (task.id === taskId) {
      toggleExpanded()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.id])

  return (
    <div className="flex items-center pb-4 cursor-pointer" onClickCapture={toggleExpanded}>
      <TaskIcon isOpen={task.isOpen} isSubform={!!task.subform} />
      <span
        className="truncate font-medium text-sm tracking-tight"
        title={taskTextIdentifier(task)}
      >
        { taskTextIdentifier(task) }
      </span>
    </div>
  )
}

TaskShortcut.propTypes = {
  task: taskShape.isRequired,
}

export default TaskShortcut
