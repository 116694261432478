/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { SCIM_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import EditForm from "./EditForm"
import ScimDisplay from "./ScimDisplay"

const GeneralTab = ({ user, setUser }) => {
  const scimFeatureEnabled = useSelector(subscribedToAddOnFeature(SCIM_FEATURE_NAME))

  return (
    <>
      <h2 className="tab-title">General</h2>
      {
        scimFeatureEnabled
          ? <ScimDisplay user={user} />
          : <EditForm user={user} setUser={setUser} />
      }
    </>
  )
}

GeneralTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
  setUser: types.func.isRequired,
}

export default GeneralTab
