import React from "react"
import { useSelector } from "react-redux"
import { hasUnread } from "reduxSlices/featureAnnouncementSlice"
import { BellIcon, IndicatorIcon } from "shared/icons"

const PanelTrigger = () => {
  const unread = useSelector(hasUnread)

  return (
    <div>
      <div className="relative mr-4">
        <BellIcon className="cursor-pointer" />
        {
          unread && (
            <div className="absolute top-0 -right-1">
              <IndicatorIcon className="cursor-pointer" />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default PanelTrigger
