import React from "react"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import { FormIcon } from "shared/icons"
import DetailBannerInfo from "./DetailBannerInfo"

const FormSubmissionForm = () => {
  const { form } = useFormSubmissionFormContext()

  return (
    <DetailBannerInfo name="Form">
      <div className="flex">
        <FormIcon form={form} className="h-6 w-6 inline-block" />
        <span className="font-medium ml-1">{ form.title }</span>
      </div>
    </DetailBannerInfo>
  )
}

export default FormSubmissionForm
