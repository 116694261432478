import React, { useState } from "react"
import * as API from "services/api"
import { SearchInput } from "shared/inputs"
import SearchResults from "./SearchResults"

const SearchPanel = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [results, setResults] = useState([])
  const [hasCompletedSearch, setHasCompletedSearch] = useState(false)

  const search = async (query) => {
    const res = await API.tasksAndSubmissionsSearch({ query, pageSize: 100 })
    if (res.ok) {
      setResults(res.data)
      setHasCompletedSearch(true)
    } else {
      console.error("Error searching:", res);
    }
  }

  return (
    <div
      className="bg-white w-96 p-4 overflow-hidden rounded-lg"
      style={{ boxShadow: "0px 4px 44px rgba(0, 0, 0, 0.1)" }}
    >
      <SearchInput
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <SearchResults
        results={results}
        searchQuery={searchQuery}
        hasCompletedSearch={hasCompletedSearch}
      />
    </div>
  )
}

export default SearchPanel
