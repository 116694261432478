import React from "react"
import types from "prop-types"
import clsx from "clsx"

const DrawerContent = ({ children, isOpen }) => (
  <div className={clsx(!isOpen && "hidden", "w-96 bg-light-100 border-l border-lightgray-3 overflow-y-scroll")}>
    { children }
  </div>
)

DrawerContent.propTypes = {
  children: types.node.isRequired,
  isOpen: types.bool.isRequired,
}

export default DrawerContent
