import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable-next-line max-len */
import TaskDepartmentList from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskDepartmentList"
import { departmentIdentifier } from "utils/taskHelpers"
import TaskMemberDepartmentSegments from "./TaskMemberDepartmentSegments"
import TaskDepartmentByIdLabel from "../UI/TaskDepartmentLabel/TaskDepartmentByIdLabel"

const TaskMemberDepartmentList = ({ className }) => {
  const {
    addMemberDepartmentNotification,
    addMemberDepartmentNotificationOnComplete,
    removeMemberDepartment,
    removeMemberDepartmentNotification,
    removeMemberDepartmentNotificationOnComplete,
    taskMemberDepartments,
  } = useTaskFormContext()

  if (taskMemberDepartments.length === 0) return null

  const toggleNotify = (dept) => () => {
    const toggleFunction = dept.notify ? removeMemberDepartmentNotification : addMemberDepartmentNotification
    toggleFunction(departmentIdentifier(dept))
  }

  const toggleNotifyOnComplete = (dept) => () => {
    const toggleFunction = dept.notifyOnComplete
      ? removeMemberDepartmentNotificationOnComplete
      : addMemberDepartmentNotificationOnComplete

    toggleFunction(departmentIdentifier(dept))
  }

  return (
    <div className={className}>
      <TaskDepartmentList
        className="mb-2"
        departments={taskMemberDepartments}
        removeDepartment={removeMemberDepartment}
        toggleNotify={toggleNotify}
        toggleNotifyOnComplete={toggleNotifyOnComplete}
        departmentLabelComponent={TaskDepartmentByIdLabel}
        segmentsComponent={TaskMemberDepartmentSegments}
      />
      <p className="text-xs text-dark w-4/5">
        Each member department will have each of its members in the selected segments
        added to the task individually.  Each department member in the selected segments
        will also be granted individual access to the form submission and be made a member
        of any tasks selected under &quot;Access to Other Tasks&quot;.
      </p>
    </div>
  )
}

TaskMemberDepartmentList.defaultProps = {
  className: "",
}

TaskMemberDepartmentList.propTypes = {
  className: types.string,
}

export default TaskMemberDepartmentList
