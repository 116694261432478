import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const PHPLogo = ({
  className = "", height = 32, width = 49,
}) => (
  <SvgSpriteIcon
    height={height}
    width={width}
    iconName="php-logo"
    className={className}
  />
)

PHPLogo.propTypes = {
  className: types.string,
  height: types.number,
  width: types.number,
}

export default PHPLogo
