import React from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { taskShape } from "utils/propTypeShapes"
import { recoverTask } from "reduxSlices/formSubmissionSlice"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import { EllipsisIcon } from "shared/icons"
import * as API from "services/api"

const RecoverTaskMenu = ({ task, taskIsRecoverable }) => {
  const dispatch = useDispatch()

  const recover = async () => {
    const taskId = task.id
    const response = await API.recoverTask({ taskId })

    if (response.ok) {
      dispatch(recoverTask(taskId))
    } else {
      console.error("Error recovering task: ", response)
    }
  }

  if (!taskIsRecoverable) return null

  return (
    <ExpandMenu expandMenuComponent={(
      <div className="self-start h-6 w-6 bg-lightgray rounded mt-5 ml-4">
        <EllipsisIcon className="pl-1 pt-1" />
      </div>
    )}
    >
      <ExpandMenuItem onClick={recover}>
        <span>Recover</span>
      </ExpandMenuItem>
    </ExpandMenu>
  )
}

RecoverTaskMenu.propTypes = {
  task: taskShape.isRequired,
  taskIsRecoverable: types.bool.isRequired,
}

export default RecoverTaskMenu
