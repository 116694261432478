import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { users as getUsers } from "reduxSlices/formSubmissionSlice"
import { ProfileIcon } from "shared/icons"

const MAX_SHOWING = 3

const TaskHeaderAssigneesOverview = ({ assigneeIds }) => {
  const users = useSelector(getUsers)

  if (!assigneeIds.length || !users.length) return null

  const assignees = assigneeIds
    .map((userId) => (
      users.find(({ id }) => id === userId)
    ))
    .filter(Boolean)

  const numberOfTruncated = assignees.length - MAX_SHOWING

  return (
    <div className="flex mr-4">
      {
        assignees.map((user, index) => (
          index < MAX_SHOWING ? (
            <ProfileIcon
              key={user.id}
              user={user}
              className="mr-1 h-6 w-6"
            />
          ) : null
        ))
      }
      {
        numberOfTruncated > 0 && (
          <span
            className={clsx(
              "h-6 w-6",
              "font-semibold text-sm text-center leading-6 text-dark",
              "bg-lightgray-3 mr-1 rounded-full",
            )}
          >
            { `+${numberOfTruncated}` }
          </span>
        )
      }
    </div>
  )
}

TaskHeaderAssigneesOverview.propTypes = {
  assigneeIds: types.arrayOf(types.number).isRequired,
}

export default TaskHeaderAssigneesOverview
