import React, { useEffect } from "react"
import { Provider, useSelector } from "react-redux"
import { store } from "store"
import { useHiddenSidebar } from "hooks"
import Sidebar from "shared/Sidebar"
import { attachments as getAttachments } from "reduxSlices/formSubmissionSlice"
import { setAttachments } from "reduxSlices/formSubmissionAttachmentsGroupingSlice"
import { FormSubmissionAttachmentDropzoneContextProvider } from "contexts/FormSubmissionAttachmentDropzoneContext"
import AttachmentGroupBy from "./Sidebar/AttachmentGroupBy"
import AttachmentTypeFilter from "./Sidebar/AttachmentTypeFilter"
import AttachmentsList from "./Content/AttachmentList"

const AttachmentsContent = () => {
  const attachments = useSelector(getAttachments)
  const { hiddenSidebar, toggleSidebar } = useHiddenSidebar()

  useEffect(() => {
    store.dispatch(setAttachments(attachments))
  }, [attachments])

  return (
    <Provider store={store}>
      <FormSubmissionAttachmentDropzoneContextProvider>
        <Sidebar
          hiddenSidebar={hiddenSidebar}
          className="fixed right-0 top-48 bottom-0 mt-0.5 min-w-max max-w-sm z-10"
        >
          <AttachmentTypeFilter attachments={attachments} />
          <AttachmentGroupBy />
        </Sidebar>
        <AttachmentsList
          attachments={attachments}
          hiddenSidebar={hiddenSidebar}
          toggleSidebar={toggleSidebar}
        />
      </FormSubmissionAttachmentDropzoneContextProvider>
    </Provider>
  )
}

export default AttachmentsContent
