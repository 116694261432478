import React, { useState } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { impersonationInProgress } from "reduxSlices/sessionSlice"
import withReduxProvider from "shared/withReduxProvider"
import { PHPLogo } from "shared/icons"
import GlobalHeaderTabs from "./GlobalHeaderTabs"
import FeatureAnnouncementsPanel from "../../FeatureAnnouncementPanel"
import UsersPanel from "../UsersPanel"
import TasksAndSubmissionsSearch from "./TasksAndSubmissionsSearch"

import { GLOBAL_HEADER_CHILDREN_PORTAL_TARGET_ID } from "./GlobalHeaderChildrenPortal"
import ImpersonationBanner from "../ImpersonationBanner"

const GlobalHeader = ({ currentTab = "", additionalTabs = [] }) => {
  const showImpersonationBanner = useSelector(impersonationInProgress)

  const [searchVisible, setSearchVisible] = useState(false)

  return (
    <nav className="main-header">
      <div className="logo-container">
        <PHPLogo />
      </div>
      <GlobalHeaderTabs currentTab={currentTab} additionalTabs={additionalTabs} />
      <div id={GLOBAL_HEADER_CHILDREN_PORTAL_TARGET_ID} />
      {
        showImpersonationBanner && (
          <div className="banner-container">
            <ImpersonationBanner />
          </div>
        )
      }
      <TasksAndSubmissionsSearch
        searchVisible={searchVisible}
        setSearchVisible={setSearchVisible}
      />
      <FeatureAnnouncementsPanel />
      <UsersPanel />
    </nav>
  )
}

GlobalHeader.propTypes = {
  currentTab: types.node,
  additionalTabs: types.arrayOf(types.string),
}

export default withReduxProvider(GlobalHeader)
