import React, { useEffect } from "react"
import types from "prop-types"
import { Provider } from "react-redux"
import { setFacility } from "reduxSlices/adminFacilitySlice"
import { store } from "store"
import { MediumWrap } from "shared/wraps"
import FacilityAdminHeader from "./FacilityAdminHeader"
import FacilityTabs from "./FacilityTabs"

const ShowFacility = ({ facility }) => {
  useEffect(() => {
    store.dispatch(setFacility(facility))
  }, [facility])

  return (
    <Provider store={store}>
      <MediumWrap>
        <FacilityAdminHeader />
        <FacilityTabs />
      </MediumWrap>
    </Provider>
  )
}

ShowFacility.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  facility: types.object.isRequired,
}

export default ShowFacility
