import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { surveyConfigurationPath, ACTIVITY_HUB_SURVEYS_PATH } from "utils/routeHelpers"
import { useSurveyBatchContext } from "contexts/SurveyBatchContext"
import SurveyBatchTabs from "./SurveyBatchTabs"

const SurveyBatchHeader = ({ configRoot, className }) => {
  const { surveyBatch } = useSurveyBatchContext()
  const { surveyConfiguration } = surveyBatch
  const headerLink = configRoot
    ? (<a className="header-link" href={surveyConfigurationPath(surveyConfiguration.id)}>{surveyConfiguration.name}</a>)
    : (<a className="header-link" href={ACTIVITY_HUB_SURVEYS_PATH}>Survey Batches</a>)

  return (
    <div className={clsx("admin-header", className)}>
      <div>{headerLink}</div>
      <div className="header-title">{surveyBatch.name}</div>
      <div className="header-description">{surveyBatch.description}</div>
      <div><SurveyBatchTabs surveyBatch={surveyBatch} /></div>
    </div>
  )
}

SurveyBatchHeader.defaultProps = {
  className: "",
  configRoot: false,
}

SurveyBatchHeader.propTypes = {
  className: types.string,
  configRoot: types.bool,
}

export default SurveyBatchHeader
