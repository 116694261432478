import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { formSubmissionData } from "reduxSlices/formSubmissionSlice"
import { CustomIconButton } from "shared/buttons"
import FormSubmissionForm from "./FormSubmissionForm"
import FormSubmissionCreatedDate from "./FormSubmissionCreatedDate"
import FormSubmissionFlagging from "./FormSubmissionFlagging"
import FormSubmissionStatus from "./FormSubmissionStatus"
import FormSubmissionExport from "./FormSubmissionExport"
import SurveyBatchSendDate from "./SurveyBatchSendDate"

const DetailsBanner = ({
  canDeleteSubmission,
  deleteFormSubmission,
  canExportSubmission,
  exportFormSubmissionData,
}) => {
  const {
    slug, createdAt, important, batchSentAt,
  } = useSelector(formSubmissionData)

  if (!slug || !createdAt) return null

  return (
    <div
      className={clsx(
        "fixed left-0 -mt-6",
        "w-full z-20 bg-light-100",
      )}
      style={{ boxShadow: "inset 0px -1px 0px #D9DCF2" }}
    >
      <div className="flex">
        <div className="px-11 py-6 text-2xl font-semibold text-dark border-r border-lightgray-3">
          {`ID - ${slug}`}
        </div>
        <div className="flex-center flex-grow">
          <div className="flex justify-start">
            <FormSubmissionForm />
            <FormSubmissionCreatedDate createdAt={createdAt} />
            {batchSentAt && <SurveyBatchSendDate sentAt={batchSentAt} />}
            {important && <FormSubmissionFlagging />}
          </div>
          <div className="flex items-end">
            {
              (canDeleteSubmission) && (
                <CustomIconButton
                  text="Delete"
                  className="mr-4 mb-3 border border-light text-sm font-semibold"
                  icon="trash-can"
                  onClick={deleteFormSubmission}
                />
              )
            }
            {
              canExportSubmission && <FormSubmissionExport exportFormSubmissionData={exportFormSubmissionData} />
            }
            <FormSubmissionStatus />
          </div>
        </div>
      </div>
    </div>
  )
}

DetailsBanner.propTypes = {
  canDeleteSubmission: types.bool.isRequired,
  canExportSubmission: types.bool.isRequired,
  deleteFormSubmission: types.func.isRequired,
  exportFormSubmissionData: types.func.isRequired,
}

export default DetailsBanner
