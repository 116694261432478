/* eslint-disable camelcase */
import React, { useState } from "react"
import types from "prop-types"
import { AccessLevelSelect } from "shared/selects"
import * as API from "services/api"
import { submissionAccessLevel } from "./utils"
import AccessExplanation from "./AccessExplanation"

const AccessLevelIdentifier = ({
  categoryGrant,
  formGrant,
  formSubmissionGrant,
  updateAccessGranteeLists,
  formSubmissionSlug,
  isDisabled,
  granteeType: grantee_type,
  granteeId: grantee_id,
}) => {
  const applicableGrant = submissionAccessLevel({ categoryGrant, formGrant, formSubmissionGrant })
  const { accessLevel, id } = applicableGrant

  const [currentApplicableGrantId, setCurrentApplicableGrantId] = useState(id)

  const manageSubmissionAccessGrant = async (access_level) => {
    // Checks if accessGrant is the same from previous renders to prevent unnecessary update on delete
    if (currentApplicableGrantId !== id) {
      setCurrentApplicableGrantId(id)
      return
    }

    const access_level_grant = { access_level, grantee_type, grantee_id }

    const response = await API.manageFormSubmissionAccessGrant({
      formSubmissionSlug, access_level_grant,
    })
    if (response.ok) {
      updateAccessGranteeLists(response.data)
    } else {
      console.error("Error updating access level grant", response)
    }
  }

  return (
    <div className="flex-center">
      <AccessExplanation
        categoryGrant={categoryGrant}
        formGrant={formGrant}
        formSubmissionGrant={formSubmissionGrant}
      />
      <AccessLevelSelect
        value={accessLevel}
        onChange={manageSubmissionAccessGrant}
        isDisabled={isDisabled}
      />
    </div>
  )
}

AccessLevelIdentifier.defaultProps = {
  categoryGrant: null,
  formGrant: null,
  formSubmissionGrant: null,
}

/* eslint-disable react/forbid-prop-types */
AccessLevelIdentifier.propTypes = {
  categoryGrant: types.object,
  formGrant: types.object,
  formSubmissionGrant: types.object,
  updateAccessGranteeLists: types.func.isRequired,
  granteeType: types.string.isRequired,
  granteeId: types.number.isRequired,
  formSubmissionSlug: types.string.isRequired,
  isDisabled: types.bool.isRequired,
}
/* eslint-enable react/forbid-prop-types */

export default AccessLevelIdentifier
