import React from "react"
import types from "prop-types"
import { MediumWrap } from "shared/wraps"
import { SurveyConfigurationContextProvider } from "contexts/SurveyConfigurationContext"
import SurveyConfigurationHeader from "./Content/SurveyConfigurationHeader"
import SurveyConfigurationButtons from "./Content/SurveyConfigurationButtons"
import SurveyConfigurationTabs from "./Content/SurveyConfigurationTabs"

const SurveyConfigurationShow = ({ surveyConfiguration }) => (
  <SurveyConfigurationContextProvider surveyConfiguration={surveyConfiguration}>
    <MediumWrap>
      <SurveyConfigurationHeader className="mb-4" />
      <SurveyConfigurationButtons className="mb-8" />
      <SurveyConfigurationTabs />
    </MediumWrap>
  </SurveyConfigurationContextProvider>
)

SurveyConfigurationShow.propTypes = {
  surveyConfiguration: types.object.isRequired,
}

export default SurveyConfigurationShow
