import { useEffect } from "react"
import { SessionStoragePersistence as storage } from "utils/Persistence"

const PROPERTY = "acknowledged"

const storageKey = (messageName) => `oneTimeMessage-${messageName}`

const useOncePerPageLoadMessage = (message, messageName) => {
  const key = storageKey(messageName)

  useEffect(() => (
    () => storage.clear({ key })
  ), [key])

  const messageAcknowledged = () => (
    Boolean(storage.lookup({ key, property: PROPERTY }))
  )

  const displayMessage = () => {
    if (messageAcknowledged()) return

    window.alert(message)
    storage.persist({ key, property: PROPERTY, value: true })
  }

  return {
    displayMessage,
  }
}

export default useOncePerPageLoadMessage
