import React from "react"
import { useSelector } from "react-redux"
import { getFormHasWorkflows } from "reduxSlices/formBuilderSlice"
import AddWorkflowButton from "./AddWorkflowButton"

const WorkflowHeader = () => {
  const formHasWorkflows = useSelector(getFormHasWorkflows)

  return (
    <div className="flex-center py-10">
      <div />
      <h1 className="header-title absolute-horizontal-center">Workflows</h1>
      <div>
        {
          formHasWorkflows && (<AddWorkflowButton />)
        }
      </div>
    </div>
  )
}

export default WorkflowHeader
