import React from "react"
import types from "prop-types"

const TaskTitle = ({ title }) => (
  <span className="flex font-medium tracking-tight items-center">
    { title }
  </span>
)

TaskTitle.propTypes = {
  title: types.string.isRequired,
}

export default TaskTitle
