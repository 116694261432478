import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { departmentMemberShape } from "utils/propTypeShapes"
import DraggableMember from "./DraggableMember"

const MembersList = ({
  className, departmentId, members, segmentName,
}) => (
  <ul className={clsx(className, "p-0 m-0 list-none flex-grow flex flex-wrap gap-2")}>
    {
      members.map((member) => (
        <li key={member.id}>
          <DraggableMember departmentId={departmentId} user={member} segmentName={segmentName} />
        </li>
      ))
    }
  </ul>
)

MembersList.defaultProps = {
  className: "",
}

MembersList.propTypes = {
  className: types.string,
  departmentId: types.number.isRequired,
  members: types.arrayOf(departmentMemberShape).isRequired,
  segmentName: types.string.isRequired,
}

export default MembersList
