/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { getFacilities } from "reduxSlices/facilitiesSlice"
import { questionShape } from "utils/propTypeShapes"
import FormsyDepartmentSelect from "./FormsyDepartmentSelect"
import FacilitySelector from "./FacilitySelector"

const facilityWithDepartment = (facilities, departmentId) => (
  facilities.find((facility) => (
    facility.departments.find(({ id }) => id === departmentId)
  ))
)

const LocationAnswerField = ({
  className,
  disabled,
  edit,
  initialValue,
  question,
}) => {
  const { attributes, required, uuid } = question
  const facilities = useSelector(getFacilities)
  const departments = facilities.flatMap((f) => f.departments)

  const [facilityId, setFacilityId] = useState(null)
  const [showErrors, setShowErrors] = useState(false)

  // Set selected facility when the component mounts
  useEffect(() => {
    const selectedFacility = facilityWithDepartment(facilities, initialValue.department_id)
    if (selectedFacility) {
      setFacilityId(selectedFacility.id)
      return
    }

    const defaultFacility = facilities.find((facility) => facility.active && facility.id === attributes.defaultFacility)
    if (defaultFacility) {
      setFacilityId(defaultFacility.id)
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [initialValue.department_id])

  // Only active facilities/departments, ALWAYS include saved selection(s) (even if currently inactive)
  const facilitiesToChooseFrom = facilities.filter((facility) => (
    facility.active || facility.id === facilityId
  ))

  const departmentsToChooseFrom = departments.filter((department) => (
    department.facilityId === facilityId
      && (department.active || department.id === initialValue.department_id)
  ))

  const departmentValidations = edit
    ? null
    : {
      requiresDepartmentId: (_model, value) => {
        if (!required) return true
        if (value?.department_id !== null && value?.department_id !== undefined) return true
        return "Must select a department"
      },
    }

  const departmentSelectClassName = clsx(
    "mt-3 location-answer-field-department-selector",
    "form-input",
    required && facilityId && showErrors && !initialValue.department_id && "highlight-required-input",
  )

  return (
    <div className={clsx(className, "location-answer-field")}>
      <FacilitySelector
        disabled={disabled}
        columnCount={attributes?.columnCount}
        displayAs={attributes?.displayAs}
        facilities={facilitiesToChooseFrom}
        onChange={setFacilityId}
        required={required}
        showErrors={showErrors}
        questionUuid={uuid}
        value={facilityId}
      />
      <FormsyDepartmentSelect
        className={departmentSelectClassName}
        disabled={!facilityId}
        isFullHeight
        name={uuid}
        options={departmentsToChooseFrom}
        optionsFromList={(options) => options.map((o) => ({ label: o.name, value: o.id, active: o.active }))}
        searchable
        setShowErrors={setShowErrors}
        value={initialValue}
        validations={departmentValidations}
      />
    </div>
  )
}

LocationAnswerField.defaultProps = {
  className: "",
  disabled: false,
  edit: false,
  initialValue: {},
}

LocationAnswerField.propTypes = {
  className: types.string,
  disabled: types.bool,
  edit: types.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: types.object,
  question: questionShape.isRequired,
}

export default LocationAnswerField
