import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { withFormsy } from "formsy-react"
import InputErrorMessage from "shared/InputErrorMessage"

const Checkbox = ({
  checked,
  className = "",
  disabled = false,
  name,
  onChange,
  selectedValues,
  value,
}) => {
  const onCheckboxChange = (event) => {
    event.stopPropagation()
    if (checked) {
      onChange(selectedValues.filter((val) => val !== value))
    } else {
      onChange([...selectedValues, value])
    }
  }
  return (
    <div
      className={clsx("flex items-center p-4 cursor-pointer", className)}
      onClickCapture={onCheckboxChange}
    >
      <input
        className="mr-2 cursor-pointer"
        type="checkbox"
        disabled={disabled}
        id={`${name}-${value}`}
        name={name}
        value={value}
        checked={checked}
        onChange={onCheckboxChange}
      />
      <span className="cursor-pointer">
        { value }
      </span>
    </div>
  )
}

Checkbox.propTypes = {
  name: types.string.isRequired,
  className: types.string,
  value: types.string.isRequired,
  selectedValues: types.oneOfType([
    types.arrayOf(types.string),
    types.arrayOf(types.number),
  ]).isRequired,
  checked: types.bool.isRequired,
  onChange: types.func.isRequired,
  disabled: types.bool,
}

const CheckboxGroup = ({
  checkboxInputClassName = "",
  className = "",
  columnCount,
  disabled = false,
  errorMessages = [],
  isPristine = true,
  name,
  onChange,
  options,
  value,
}) => {
  const shouldHaveGridCol = (window.screen.availWidth > 500)

  return (
    <>
      <div className={clsx(
        className,
        "cursor-pointer checkbox-group",
        shouldHaveGridCol && `grid grid-cols-${columnCount}`,
      )}
      >
        {
          options.map((option) => (
            <Checkbox
              key={option}
              className={checkboxInputClassName}
              disabled={disabled}
              name={name}
              value={option}
              selectedValues={value}
              checked={!!value?.includes(option)}
              onChange={onChange}
            />
          ))
        }
      </div>
      <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
    </>
  )
}

CheckboxGroup.propTypes = {
  className: types.string,
  checkboxInputClassName: types.string,
  name: types.string.isRequired,
  options: types.arrayOf(types.string).isRequired,
  value: types.oneOfType([
    types.arrayOf(types.string),
    types.arrayOf(types.number),
  ]).isRequired,
  onChange: types.func.isRequired,
  columnCount: types.number.isRequired,
  errorMessages: types.arrayOf(types.string),
  isPristine: types.bool,
  disabled: types.bool,
}

export const FormsyCheckboxGroup = withFormsy(({ setValue, ...rest }) => (
  <CheckboxGroup onChange={setValue} {...rest} />
))

export default CheckboxGroup
