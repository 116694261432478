import React from "react"
import types from "prop-types"
import { numberDate } from "utils/dateHelpers"
import DetailBannerInfo from "./DetailBannerInfo"

const SurveyBatchSendDate = ({ sentAt }) => (
  <DetailBannerInfo name="Survey Date">
    <span className="font-medium">
      { numberDate(sentAt) }
    </span>
  </DetailBannerInfo>
)

SurveyBatchSendDate.propTypes = {
  sentAt: types.string.isRequired,
}

export default SurveyBatchSendDate
