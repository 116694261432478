import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { departmentIdentifier } from "utils/taskHelpers"
/* eslint-disable max-len */
import TaskDepartmentList from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskDepartmentList"
import TaskDepartmentByQuestionLabel from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskDepartmentLabel/TaskDepartmentByQuestionLabel"
/* eslint-enable max-len */
import TaskAssigneeDepartmentSegments from "./TaskAssigneeDepartmentSegments"

const TaskAssigneeDepartmentList = ({ className }) => {
  const {
    addAssigneeDepartmentNotification,
    addAssigneeDepartmentNotificationOnComplete,
    removeAssigneeDepartment,
    removeAssigneeDepartmentNotification,
    removeAssigneeDepartmentNotificationOnComplete,
    taskAssigneeDepartments,
  } = useTaskFormContext()

  if (taskAssigneeDepartments.length === 0) return null

  const toggleNotify = (dept) => () => {
    const toggleFunction = dept.notify ? removeAssigneeDepartmentNotification : addAssigneeDepartmentNotification
    toggleFunction(departmentIdentifier(dept))
  }

  const toggleNotifyOnComplete = (dept) => () => {
    const toggleFunction = dept.notifyOnComplete
      ? removeAssigneeDepartmentNotificationOnComplete
      : addAssigneeDepartmentNotificationOnComplete

    toggleFunction(departmentIdentifier(dept))
  }

  return (
    <div className={className}>
      <TaskDepartmentList
        className="mb-2"
        departments={taskAssigneeDepartments}
        removeDepartment={removeAssigneeDepartment}
        toggleNotify={toggleNotify}
        toggleNotifyOnComplete={toggleNotifyOnComplete}
        departmentLabelComponent={TaskDepartmentByQuestionLabel}
        segmentsComponent={TaskAssigneeDepartmentSegments}
      />
      <p className="text-xs text-dark">
        Each assignee department will have each of its members in the selected segments
        assigned to the task individually.  Each department member in the selected segments
        will also be granted individual access to the form submission.
      </p>
    </div>
  )
}

TaskAssigneeDepartmentList.defaultProps = {
  className: "",
}

TaskAssigneeDepartmentList.propTypes = {
  className: types.string,
}

export default TaskAssigneeDepartmentList
