import React, { useState } from "react"
import types from "prop-types"
import { MediumWrap } from "shared/wraps"
import * as API from "services/api"
import { ADMIN_USERS_AND_GROUPS_PATH } from "utils/routeHelpers"
import GroupTabs from "../GroupTabs"

const ShowGroup = ({ group: initialGroup, groupWorkflows }) => {
  const [group, setGroup] = useState(initialGroup)

  const addMember = (member) => setGroup((prevGroup) => ({
    ...prevGroup,
    members: [...prevGroup.members, member],
  }))

  const removeMember = (memberId) => setGroup((prevGroup) => ({
    ...prevGroup,
    members: [...prevGroup.members.filter(({ id }) => id !== memberId)],
  }))

  const updateGroup = async (model) => {
    const response = await API.updateGroup({ groupId: group.id, group: model })

    if (response.ok) {
      setGroup((prevGroup) => ({ ...prevGroup, ...model }))
    } else {
      console.error("Error updating group: ", response)
    }
  }

  const tabProps = {
    group,
    groupWorkflows,
    addMember,
    removeMember,
    updateGroup,
  }

  return (
    <MediumWrap>
      <div className="admin-header">
        <a className="header-link" href={ADMIN_USERS_AND_GROUPS_PATH}>Users & Groups</a>
        <h1 className="header-title">{ group.name }</h1>
      </div>
      <GroupTabs tabProps={tabProps} />
    </MediumWrap>
  )
}

ShowGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
  groupWorkflows: types.arrayOf(types.object).isRequired,
}

export default ShowGroup
