import React from "react"
import types from "prop-types"

const RAW_ANSWER_DATE_MATCHER = /(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<day>[0-9]{2})/g

const formatDateAnswer = (rawAnswer) => (
  rawAnswer.replace(RAW_ANSWER_DATE_MATCHER, "$<month>/$<day>/$<year>")
)

const DateAnswer = ({ rawAnswer, isNewQuestion }) => {
  if (isNewQuestion) return null

  return (
    <span className="font-medium text-lg bg-lightgray w-1/2 py-1.5 px-3 whitespace-pre-wrap">
      {rawAnswer ? formatDateAnswer(rawAnswer) : null}
    </span>
  )
}

DateAnswer.defaultProps = {
  rawAnswer: undefined,
  isNewQuestion: false,
}

DateAnswer.propTypes = {
  rawAnswer: types.string,
  isNewQuestion: types.bool,
}

export default DateAnswer
