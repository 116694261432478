import React, { useState } from "react"
import types from "prop-types"
import { EllipsisIcon } from "shared/icons"
import { taskShape, questionShape } from "utils/propTypeShapes"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import * as API from "services/api"
import { errorToast } from "shared/toast"
import EditEntryModal from "./EntryElements/EditEntryModal"

const DELETE_FAILURE_MESSAGE = "Something went wrong.  Unable to delete entry."

const SubformAnswerEntryMenu = ({
  className, task, question, entry,
}) => {
  const [entryModalIsOpen, setEntryModalIsOpen] = useState(false)
  const openTaskFormModal = () => setEntryModalIsOpen(true)
  const closeTaskFormModal = () => setEntryModalIsOpen(false)

  const canEditEntry = entry.user?.canUserEdit && task.isOpen

  if (!canEditEntry) return null

  // If the entry is succussfully deleted, the event will be communicated via WebSocket,
  // which will trigger an update of the answer state.
  // See app/javascript/components/views/FormSubmission/SyncAllTaskAnswers/TaskAnswersSync.jsx
  const deleteEntry = async () => {
    const answerData = { entry_uuid: entry.uuid, question_uuid: question.uuid }
    const response = await API.deleteTaskAnswer({ taskId: task.id, answerData })

    if (!response.ok) {
      errorToast(DELETE_FAILURE_MESSAGE)
    }
  }

  return (
    <>
      <ExpandMenu expandMenuComponent={<EllipsisIcon className={className} />}>
        <ExpandMenuItem onClick={openTaskFormModal}>
          Edit
        </ExpandMenuItem>
        <ExpandMenuItem onClick={deleteEntry}>
          Delete
        </ExpandMenuItem>
      </ExpandMenu>
      <EditEntryModal
        task={task}
        question={question}
        answer={entry.answer}
        entryUuid={entry.uuid}
        modalIsOpen={entryModalIsOpen}
        closeModal={closeTaskFormModal}
      />
    </>
  )
}

SubformAnswerEntryMenu.defaultProps = {
  className: "",
}

SubformAnswerEntryMenu.propTypes = {
  className: types.string,
  task: taskShape.isRequired,
  question: questionShape.isRequired,
  entry: types.shape({
    user: types.shape({
      id: types.number.isRequired,
      canUserEdit: types.bool.isRequired,
    }).isRequired,
    answer: types.string.isRequired,
    created_at: types.string.isRequired,
    uuid: types.string.isRequired,
  }).isRequired,
}

export default SubformAnswerEntryMenu
