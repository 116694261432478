import React from "react"
import types from "prop-types"
import { numberDate } from "utils/dateHelpers"
import DetailBannerInfo from "./DetailBannerInfo"

const FormSubmissionCreatedDate = ({ createdAt }) => (
  <DetailBannerInfo name="Submission Date">
    <span className="font-medium">
      { numberDate(createdAt) }
    </span>
  </DetailBannerInfo>
)

FormSubmissionCreatedDate.propTypes = {
  createdAt: types.string.isRequired,
}

export default FormSubmissionCreatedDate
