import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { DrawerIcon } from "shared/icons"

const DrawerToggle = ({ className = "", isOpen, toggleDrawer }) => (
  <span
    className={clsx(className, "p-2 m-2 bg-white cursor-pointer")}
    onClickCapture={toggleDrawer}
  >
    <DrawerIcon isOpen={isOpen} />
  </span>
)

DrawerToggle.propTypes = {
  className: types.string,
  isOpen: types.bool.isRequired,
  toggleDrawer: types.func.isRequired,
}

export default DrawerToggle
