import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import { FormsyTimeInput } from "shared/inputs"

const timeValidations = ({ required, edit, initialValue }) => {
  // HH:MM 24-hour format
  const timeRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
  const validations = initialValue ? { matchRegexp: timeRegex } : {}

  if (!edit && required) { validations.isNotBlankString = true }

  return validations
}

const TimeAnswerField = ({
  className,
  disabled,
  edit,
  initialValue,
  isTaskForm,
  question,
  ...rest
}) => {
  const { attributes, required, uuid } = question
  const { setCurrentTime } = attributes

  const currentTime = (new Date()).toTimeString().substr(0, 5)
  const timeValue = (setCurrentTime ? currentTime : "")

  const isNewFormSubmission = !edit && !isTaskForm
  const defaultAnswer = disabled ? "" : timeValue
  initialValue = isNewFormSubmission ? defaultAnswer : initialValue

  const validationErrors = initialValue ? { matchRegexp: "Invalid time: must be HH:MM (24-hour format)" } : null

  return (
    <FormsyTimeInput
      className={clsx("form-input-date-time", className)}
      disabled={disabled}
      name={uuid}
      validations={timeValidations({ required, edit, initialValue })}
      validationErrors={validationErrors}
      value={initialValue}
      {...rest}
    />
  )
}

TimeAnswerField.defaultProps = {
  initialValue: "",
  disabled: false,
  className: "",
  placeholder: "",
  hasBaseInputStyle: false,
  edit: false,
  isTaskForm: false,
}

TimeAnswerField.propTypes = {
  question: questionShape.isRequired,
  initialValue: types.string,
  disabled: types.bool,
  className: types.string,
  placeholder: types.string,
  hasBaseInputStyle: types.bool,
  edit: types.bool,
  isTaskForm: types.bool,
}

export default TimeAnswerField
