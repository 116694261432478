import React from "react"
import types from "prop-types"
import { NewTaskForm } from "FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms"
import TaskList from "./TaskList"

const ActivitiesMain = ({ taskFormIsOpen, closeForm }) => (
  <div className="w-2/3 mb-28">
    <TaskList />
    <NewTaskForm
      taskFormIsOpen={taskFormIsOpen}
      onCancel={closeForm}
      onCreate={closeForm}
    />
  </div>
)

ActivitiesMain.propTypes = {
  taskFormIsOpen: types.bool.isRequired,
  closeForm: types.func.isRequired,
}

export default ActivitiesMain
