import React, { useEffect, useMemo } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getActiveOrMatchingFacilities } from "reduxSlices/formBuilderSlice"
import InactiveOptionsItemRenderer from "shared/selects/InactiveOptionsItemRenderer"
import { MultiSelect } from "shared/multiSelects"

const LocationIsAnyOfFacilitiesMatchValueInput = ({
  className,
  isFullWidth,
  onChange,
  values,
}) => {
  const facilities = useSelector(getActiveOrMatchingFacilities(values))

  const facilityOptions = useMemo(() => (
    facilities.map((facility) => ({
      label: facility.name,
      value: facility.id,
      active: facility.active,
    }))
  ), [facilities])

  useEffect(() => {
    if ((!values || values.length === 0) && facilityOptions.length !== 0) {
      onChange([facilityOptions[0].value])
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <MultiSelect
      backspaceDelete={false}
      className={className}
      isFullWidth={isFullWidth}
      itemRenderer={InactiveOptionsItemRenderer}
      onChange={onChange}
      options={facilityOptions}
      required
      values={values}
    />
  )
}

LocationIsAnyOfFacilitiesMatchValueInput.defaultProps = {
  className: "",
  values: [],
}

LocationIsAnyOfFacilitiesMatchValueInput.propTypes = {
  className: types.string,
  isFullWidth: types.bool.isRequired,
  onChange: types.func.isRequired,
  values: types.arrayOf(types.number),
}

export default LocationIsAnyOfFacilitiesMatchValueInput
