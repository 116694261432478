import types from "prop-types"
import { CONTROLS, evaluateBranchingConditionals } from "utils/QuestionBranching"

const BaseConditionalCheck = ({
  children,
  conditionals,
  formAnswers,
  operatorData,
}) => {
  if (!evaluateBranchingConditionals({ conditionals, formAnswers, operatorData })) {
    return null
  }

  return children
}

BaseConditionalCheck.defaultProps = {
  conditionals: {},
  operatorData: {},
}

BaseConditionalCheck.propTypes = {
  children: types.node.isRequired,
  // Copied from prop-type shapes, the import was throwing an error
  // for this component only
  conditionals: types.shape({
    control: types.oneOf(CONTROLS),
    conditions: types.arrayOf(types.shape({
      questionUuid: types.string,
      operator: types.string,
      matchValue: types.oneOfType([
        types.string,
        types.number,
        types.arrayOf(types.string),
        types.arrayOf(types.number),
      ]),
    })),
  }),
  formAnswers: types.object.isRequired,
  operatorData: types.object,
}

export default BaseConditionalCheck
