import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormQuestionOptionsByUuid, getFormQuestionTypeByUuid } from "reduxSlices/formBuilderSlice"
import { matchValueInput } from "Forms/FormElementTypes"
import { ListMultiSelect } from "shared/multiSelects"
import { LocationIsAnyOfFacilitiesMatchValueInput } from "views/FormBuilder/QuestionBranching/ConditionMatchValueInputs"

const matchValueInputNeedsValues = (input) => (
  [LocationIsAnyOfFacilitiesMatchValueInput, ListMultiSelect].includes(input)
)

// Component is used for question branching conditions and flag conditions.
// For question branching, the options are from another question.
// For flag conditions, the option represent a question's own options.
const ConditionMatchValueField = ({
  conditionOperator,
  setConditionMatchValue,
  matchValue,
  conditionQuestionUuid,
}) => {
  const conditionQuestionOptions = useSelector(getFormQuestionOptionsByUuid(conditionQuestionUuid), isEqual)
  const conditionQuestionType = useSelector(getFormQuestionTypeByUuid(conditionQuestionUuid))

  const MatchValueInput = matchValueInput({ questionType: conditionQuestionType, conditionOperator })

  if (!MatchValueInput) return null

  // For a couple match value input components we need to pass a "values" prop;
  // however, for other match value inputs, we need to pass a "value" prop
  const valueProp = {
    [matchValueInputNeedsValues(MatchValueInput) ? "values" : "value"]: matchValue,
  }

  return (
    <MatchValueInput
      className="text-sm"
      isFullWidth
      onChange={setConditionMatchValue}
      options={conditionQuestionOptions}
      {...valueProp}
    />
  )
}

ConditionMatchValueField.defaultProps = {
  conditionQuestionUuid: null,
  conditionOperator: null,
  matchValue: "",
}

ConditionMatchValueField.propTypes = {
  conditionOperator: types.string,
  setConditionMatchValue: types.func.isRequired,
  matchValue: types.oneOfType([types.string, types.number, types.arrayOf(types.string), types.arrayOf(types.number)]),
  conditionQuestionUuid: types.string,
}

export default ConditionMatchValueField
