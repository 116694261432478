import React from "react"
import { FlagIcon } from "shared/icons"
import DetailBannerInfo from "./DetailBannerInfo"

const FormSubmissionFlagging = () => (
  <DetailBannerInfo name="Flagging" hideName>
    <FlagIcon />
  </DetailBannerInfo>
)

export default FormSubmissionFlagging
