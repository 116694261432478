import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { showingDeletedTasks as getShowingDeleted } from "reduxSlices/formSubmissionSlice"
import AddTask from "FormSubmission/FormSubmissionTabs/ActivitiesContent/AddTask"
import { SidebarVisibilityControl } from "shared/Sidebar"
import OptionsSidebar from "./OptionsSidebar"

const TasksHeader = ({ openForm, hiddenSidebar, toggleSidebar }) => {
  const showingDeletedTasks = useSelector(getShowingDeleted)

  const header = showingDeletedTasks ? "Deleted Tasks" : "Tasks"

  return (
    <>
      <OptionsSidebar
        hiddenSidebar={hiddenSidebar}
        showingDeletedTasks={showingDeletedTasks}
      />
      <div className={clsx("flex-center relative", !hiddenSidebar && "ml-80")}>
        <SidebarVisibilityControl
          hiddenSidebar={hiddenSidebar}
          toggleSidebar={toggleSidebar}
        />
        <div className="text-center pl-5 py-6 header-title">{ header }</div>
        <AddTask openForm={openForm} />
      </div>
    </>
  )
}

TasksHeader.propTypes = {
  openForm: types.func.isRequired,
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default TasksHeader
