import React from "react"
import types from "prop-types"
import { SearchSelectedUser } from "shared/searches"
import AccessLevelIdentifier from "./AccessLevelIdentifier"

const UserGranteeList = ({
  deleteFormSubmissionGrant, users, formSubmissionSlug, updateAccessGranteeLists, isDisabled,
}) => {
  if (!users.length) {
    return <div>No Users with Grants</div>
  }

  return (
    users.map(({
      grantee, formGrant, categoryGrant, formSubmissionGrant,
    }) => (
      <SearchSelectedUser
        key={grantee.id}
        user={grantee}
        deselect={deleteFormSubmissionGrant(formSubmissionGrant?.id)}
        showDeselectButton={!isDisabled && !!formSubmissionGrant}
      >
        <AccessLevelIdentifier
          formSubmissionSlug={formSubmissionSlug}
          formSubmissionGrant={formSubmissionGrant}
          formGrant={formGrant}
          categoryGrant={categoryGrant}
          updateAccessGranteeLists={updateAccessGranteeLists}
          granteeType="User"
          granteeId={grantee.id}
          isDisabled={isDisabled}
        />
      </SearchSelectedUser>
    ))
  )
}

UserGranteeList.propTypes = {
  users: types.arrayOf(types.object).isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  deleteFormSubmissionGrant: types.func.isRequired,
  formSubmissionSlug: types.string.isRequired,
  isDisabled: types.bool.isRequired,
}

export default UserGranteeList
