import React from "react"
import NotificationOverview from "./NotificationOverview"
import CurrentUser from "./CurrentUser"

const Panel = () => (
  <div className="users-panel">
    <NotificationOverview />
    <CurrentUser />
  </div>
)

export default Panel
