import React, { useCallback } from "react"
import Formsy from "formsy-react"
import types from "prop-types"
import clsx from "clsx"
import { useSubmit } from "hooks"
import * as API from "services/api"
import { Button, PrimaryButton } from "shared/buttons"
import { errorToast, successToast } from "shared/toast"
import QuestionTagFormFields from "./QuestionTagFormFields"

const CREATION_SUCCESSFUL_MESSAGE = "Question tag created successfully!"
const CREATION_FAILURE_MESSAGE = "Something went wrong. Unable to add question tag."

const NewQuestionTagForm = ({
  addQuestionTag,
  className,
  onCancel,
  tagNames,
}) => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit(false)

  const createQuestionTag = useCallback(async (formData) => {
    const response = await API.createQuestionTag(formData)

    if (response.ok) {
      successToast(CREATION_SUCCESSFUL_MESSAGE)
      addQuestionTag(response.data)
      onCancel()
    } else {
      errorToast(CREATION_FAILURE_MESSAGE)
    }
  }, [addQuestionTag, onCancel])

  return (
    <Formsy
      className={clsx(className)}
      onInvalid={disableSubmit}
      onInvalidSubmit={null}
      onValid={enableSubmit}
      onValidSubmit={createQuestionTag}
    >
      <QuestionTagFormFields
        className={canSubmit ? "mb-8" : "mb-16"}
        tagNames={tagNames}
      />
      <div className="flex justify-center">
        <PrimaryButton
          className="mr-4"
          disabled={!canSubmit}
          text="Add Question Tag"
          type="submit"
        />
        <Button className="bg-white border border-lightgray-3" text="Cancel" onClick={onCancel} />
      </div>
    </Formsy>
  )
}

NewQuestionTagForm.defaultProps = {
  className: "",
}

NewQuestionTagForm.propTypes = {
  addQuestionTag: types.func.isRequired,
  className: types.string,
  onCancel: types.func.isRequired,
  tagNames: types.arrayOf(types.string).isRequired,
}

export default NewQuestionTagForm
