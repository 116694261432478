import React from "react"
import types from "prop-types"
import ExpandIconButton from "./ExpandIconButton"
import TaskLockedIcon from "./TaskLockedIcon"

const ExpansionControl = ({ expanded, canSeeDetails }) => (
  canSeeDetails
    ? <ExpandIconButton expanded={expanded} />
    : <TaskLockedIcon />
)

ExpansionControl.defaultProps = {
  expanded: false,
}

ExpansionControl.propTypes = {
  expanded: types.bool,
  canSeeDetails: types.bool.isRequired,
}

export default ExpansionControl
