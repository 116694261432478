import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormQuestionByUuid } from "reduxSlices/formBuilderSlice"
import { questionShape } from "utils/propTypeShapes"
import { operatorRequiresMatchValue } from "utils/QuestionBranching"
import DeleteConditionButton from "FormBuilder/UIElements/DeleteConditionButton"
import FormsyValidation from "shared/FormsyValidation"
import ConditionMatchValueField from "./ConditionMatchValueField"
import ConditionOperatorField from "./ConditionOperatorField"
import ConditionQuestionUuidField from "./ConditionQuestionUuidField"

const validations = {
  questionBranchingCondition: (_model, condition) => {
    if (!condition) return true

    const { questionUuid, operator, matchValue } = condition

    if (!questionUuid) return "Must select question"
    if (!operator) return "Must select operator"
    if (!operatorRequiresMatchValue(operator)) return true
    if (!matchValue && matchValue !== 0) return "Must enter match value"
    if (matchValue?.length === 0) return "Must enter match value"

    return true
  },
}

const ConditionDefiner = ({
  condition,
  deleteCondition,
  fieldPath,
  unusedQuestionOptions,
  updateConditionMatchValue,
  updateConditionOperator,
  updateConditionQuestionUuid,
}) => {
  const { questionUuid, operator, matchValue } = condition

  const currentConditionQuestion = useSelector(getFormQuestionByUuid(questionUuid), isEqual)

  if (!condition) return null

  const questionOptions = [
    currentConditionQuestion,
    ...unusedQuestionOptions,
  ]

  const matchValueRequired = operator && operatorRequiresMatchValue(operator)

  return (
    <div className="flex flex-col border border-light p-3 rounded-lg relative mr-6 mb-3">
      <DeleteConditionButton
        deleteCondition={deleteCondition}
        className="absolute -right-8 top-3 h-5 w-5 bg-white rounded-full p-1"
      />
      <ConditionQuestionUuidField
        conditionQuestionOptions={questionOptions}
        setConditionQuestionUuid={updateConditionQuestionUuid}
        selectedQuestionUuid={questionUuid}
      />
      <ConditionOperatorField
        conditionQuestionUuid={currentConditionQuestion.uuid}
        setConditionOperator={updateConditionOperator}
        value={operator}
      />
      {
        matchValueRequired && (
          <ConditionMatchValueField
            setConditionMatchValue={updateConditionMatchValue}
            conditionQuestionUuid={questionUuid}
            conditionOperator={operator}
            matchValue={matchValue}
          />
        )
      }
      <FormsyValidation
        name={fieldPath}
        validations={validations}
        value={condition}
      />
    </div>
  )
}

ConditionDefiner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  condition: types.object.isRequired,
  deleteCondition: types.func.isRequired,
  fieldPath: types.string.isRequired,
  unusedQuestionOptions: types.arrayOf(questionShape).isRequired,
  updateConditionMatchValue: types.func.isRequired,
  updateConditionOperator: types.func.isRequired,
  updateConditionQuestionUuid: types.func.isRequired,
}

export default ConditionDefiner
