import React from "react"
import { Provider } from "react-redux"
import { store } from "store"
import { MediumWrap } from "shared/wraps"
import { ADMIN_PATH } from "utils/routeHelpers"
import GeneralSettingsTabs from "./GeneralSettingsTabs"

const GeneralSettings = () => (
  <Provider store={store}>
    <MediumWrap>
      <div className="admin-header">
        <a className="header-link" href={ADMIN_PATH}>Admin Panel</a>
        <div className="header-title">General Settings</div>
        <div className="header-description">Configurations for the organization.</div>
      </div>
      <GeneralSettingsTabs />
    </MediumWrap>
  </Provider>
)

export default GeneralSettings
