import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { RemoveButton } from "shared/buttons"
import { ProfileIcon } from "shared/icons"
import Switch from "shared/Switch"

const TaskUser = ({ removeFromTask, user }) => {
  const {
    addNotifyId,
    addNotifyOnCompleteId,
    removeNotifyId,
    removeNotifyOnCompleteId,
    taskNotifyIds,
    taskNotifyOnCompleteIds,
  } = useTaskFormContext()

  const userNotified = taskNotifyIds.includes(user.id)
  const userNotifiedOnComplete = taskNotifyOnCompleteIds.includes(user.id)

  const toggleNotifyId = () => {
    const toggleFunction = userNotified ? removeNotifyId : addNotifyId
    toggleFunction(user.id)
  }

  const toggleNotifyOnCompleteId = () => {
    const toggleFunction = userNotifiedOnComplete ? removeNotifyOnCompleteId : addNotifyOnCompleteId
    toggleFunction(user.id)
  }

  return (
    <div className="flex mb-2">
      <div className="h-12 flex flex-grow items-center bg-primary-light rounded-md p-2 mr-2">
        <ProfileIcon user={user} className="mr-3 w-8 h-8" />
        <span className="flex-grow mr-3">{user.fullName}</span>
        <div className="flex gap-x-2">
          <div className="flex justify-center items-center w-20">
            <Switch
              checked={userNotified}
              onChange={toggleNotifyId}
            />
          </div>
          <div className="flex justify-center items-center w-20">
            <Switch
              checked={userNotifiedOnComplete}
              onChange={toggleNotifyOnCompleteId}
            />
          </div>
        </div>
      </div>
      <RemoveButton className="self-center justify-self-end" onClick={removeFromTask} />
    </div>
  )
}

TaskUser.propTypes = {
  removeFromTask: types.func.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  user: types.object.isRequired,
}

export default TaskUser
