import { v4 as makeUuid } from "uuid"
import { snakeToCapitalCase } from "utils/stringHelpers"
/* eslint-disable-next-line max-len */
import * as DataDefiners from "views/FormBuilder/FormBuilderTabs/WorkflowsTab/WorkflowForm/WorkflowFields/Actions/Action/DefineActionData/ActionDataDefiners"

// Triggers
const SUBMISSION_IS_CREATED = "submission_is_created"
const SUBMISSION_IS_CLOSED = "submission_is_closed"
const SUBMISSION_STATUS_IS_CHANGED = "submission_status_is_changed"
const RECURRING_EVENT = "recurring_event"
export const TASK_IS_CLOSED = "task_is_closed"

// Matches Workflow::TASK_FORM_TRIGGERS in app/models/workflow.rb
export const TASK_FORM_TRIGGERS = [TASK_IS_CLOSED]

// Matches Workflow::PUBLIC_FORM_TRIGGERS in app/models/workflow.rb
export const FORM_TRIGGERS = [
  SUBMISSION_IS_CREATED,
  SUBMISSION_IS_CLOSED,
  SUBMISSION_STATUS_IS_CHANGED,
  RECURRING_EVENT,
]

export const FORM_TRIGGER_ICONS = {
  [SUBMISSION_IS_CREATED]: "workflow-trigger-submission-created",
  [SUBMISSION_IS_CLOSED]: "workflow-trigger-submission-closed",
  [SUBMISSION_STATUS_IS_CHANGED]: "workflow-trigger-submission-changed",
  [RECURRING_EVENT]: "workflow-trigger-recurring-event",
  [TASK_FORM_TRIGGERS]: "workflow-trigger-task-closed",
}

export const FORM_TRIGGER_OPTIONS = FORM_TRIGGERS.map((value) => ({
  value,
  label: snakeToCapitalCase(value),
  icon: FORM_TRIGGER_ICONS[value],
}))

export const isRecurringEvent = (trigger) => trigger === RECURRING_EVENT

// Matches Workflow::RECURRING_EVENT_FREQUENCIES in app/models/workflow.rb
export const FREQUENCY_OPTIONS = ["daily", "weekly", "monthly"]

// Condition Operators
export const ALWAYS_CONDITION_OPERATOR = "always"
export const ALL_CONDITION_OPERATOR = "all"
export const ANY_CONDITION_OPERATOR = "any"

export const OPERATOR_CONJUNCTIONS = (operator) => {
  switch (operator) {
  case ALL_CONDITION_OPERATOR:
    return "AND"
  case ANY_CONDITION_OPERATOR:
    return "OR"
  default:
    return ""
  }
}

export const DEFAULT_OPERATOR = ALWAYS_CONDITION_OPERATOR
export const isAlwaysOperator = (operator) => operator === ALWAYS_CONDITION_OPERATOR
export const CONDITION_OPERATORS = [
  ALWAYS_CONDITION_OPERATOR,
  ALL_CONDITION_OPERATOR,
  ANY_CONDITION_OPERATOR,
]

export const STATUS_CONDITION_PROPERTY = "status"
export const FLAGGED_CONDITION_PROPERTY = "flagged" // Related to form submission important attribute
export const SUBMISSION_AGE_CONDITION_PROPERTY = "submission_age"
export const QUESTION_ANSWER_CONDITION_PROPERTY = "answer"
export const SUBFORM_ANSWER_CONDITION_PROPERTY = "subform_answer"
export const SUBMITTED_FORM_PROPERTY = "submitted_form"

export const FORM_CONDITION_PROPERTIES = [
  STATUS_CONDITION_PROPERTY,
  FLAGGED_CONDITION_PROPERTY,
  SUBMISSION_AGE_CONDITION_PROPERTY,
]

export const SUBFORM_CONDITION_PROPERTIES = [
  SUBMITTED_FORM_PROPERTY,
]

export const isSubformConditionProperty = (property) => SUBFORM_CONDITION_PROPERTIES.includes(property)

export const isQuestionAnswerOrSubformAnswerConditionProperty = (property) => [
  QUESTION_ANSWER_CONDITION_PROPERTY,
  SUBFORM_ANSWER_CONDITION_PROPERTY,
].includes(property)

export const isGenericFormSubmissionProperty = (property) => FORM_CONDITION_PROPERTIES.includes(property)

export const IS_PROPERTY_OP = "is"
export const IS_NOT_PROPERTY_OP = "is_not"
export const IS_EMPTY_PROPERTY_OP = "is_empty"
export const IS_NOT_EMPTY_PROPERTY_OP = "is_not_empty"
export const IS_FACILITY_PROPERTY_OP = "is_facility"
export const IS_NOT_FACILITY_PROPERTY_OP = "is_not_facility"
export const IS_ANY_OF_FACILITIES_PROPERTY_OP = "is_any_of_facilities"
export const HAS_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP = "has_any_of_facility_group_codes"
export const DOES_NOT_HAVE_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP = "does_not_have_any_of_facility_group_codes"
export const IS_DEPARTMENT_PROPERTY_OP = "is_department"
export const EQUAL_OR_OLDER_PROPERTY_OP = "equal_or_older"

export const CONDITION_PROPERTY_OPERATORS = [
  IS_PROPERTY_OP,
  IS_NOT_PROPERTY_OP,
  IS_EMPTY_PROPERTY_OP,
  IS_NOT_EMPTY_PROPERTY_OP,
  IS_FACILITY_PROPERTY_OP,
  IS_NOT_FACILITY_PROPERTY_OP,
  IS_ANY_OF_FACILITIES_PROPERTY_OP,
  HAS_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP,
  DOES_NOT_HAVE_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP,
  IS_DEPARTMENT_PROPERTY_OP,
  EQUAL_OR_OLDER_PROPERTY_OP,
]

// Action types and subtypes
export const SEND_NOTIFICATION = "send_notification"
export const NOTIFY_ALL_MEMBERS = "all_members"
const NOTIFY_SELECTED = "selected_users"

export const CHANGE_STATUS = "change_status"

export const ADD_MEMBERS = "add_members"

export const ADD_DEPARTMENT = "add_department"

export const REMOVE_ALL_MEMBERS = "remove_all_members"

export const CREATE_TASK = "create_task"

export const DEFAULT_SUBTYPE = "default"

export const ACTION_TYPES = [
  ADD_MEMBERS,
  ADD_DEPARTMENT,
  REMOVE_ALL_MEMBERS,
  CHANGE_STATUS,
  CREATE_TASK,
  SEND_NOTIFICATION,
]

export const ACTION_TYPE_ICONS = {
  [SEND_NOTIFICATION]: "workflow-action-send-notification",
  [CHANGE_STATUS]: "workflow-action-change-status",
  [ADD_MEMBERS]: "workflow-action-add-members",
  [REMOVE_ALL_MEMBERS]: "workflow-action-remove-members",
  [CREATE_TASK]: "workflow-action-create-task",
  [ADD_DEPARTMENT]: "workflow-action-add-department-members",
}

export const ACTION_OPTIONS = ACTION_TYPES.map((value) => ({
  value,
  label: snakeToCapitalCase(value),
  icon: ACTION_TYPE_ICONS[value],
}))

export const CONDITION_DETAILS = {
  [FLAGGED_CONDITION_PROPERTY]: {
    operators: [IS_PROPERTY_OP, IS_NOT_PROPERTY_OP],
  },
  [STATUS_CONDITION_PROPERTY]: {
    operators: [IS_PROPERTY_OP, IS_NOT_PROPERTY_OP],
  },
  [SUBMISSION_AGE_CONDITION_PROPERTY]: {
    operators: [EQUAL_OR_OLDER_PROPERTY_OP],
  },
  [QUESTION_ANSWER_CONDITION_PROPERTY]: {
    operators: [
      IS_PROPERTY_OP,
      IS_NOT_PROPERTY_OP,
      IS_EMPTY_PROPERTY_OP,
      IS_NOT_EMPTY_PROPERTY_OP,
    ],
  },
  [SUBFORM_ANSWER_CONDITION_PROPERTY]: {
    operators: [
      IS_PROPERTY_OP,
      IS_NOT_PROPERTY_OP,
      IS_EMPTY_PROPERTY_OP,
      IS_NOT_EMPTY_PROPERTY_OP,
    ],
  },
}

const ACTION_DETAILS = {
  [CREATE_TASK]: {
    subtypeConnectorText: "",
    subtypes: {
      [DEFAULT_SUBTYPE]: {
        dataDefiner: DataDefiners.DefineCreateTaskData,
      },
    },
    // validations is already inside TaskForm
  },
  [CHANGE_STATUS]: {
    subtypeConnectorText: "to",
    subtypes: {
      [DEFAULT_SUBTYPE]: {
        dataDefiner: DataDefiners.DefineStatusData,
      },
    },
    validations: (_model, { data }) => {
      if (!data?.statusId) {
        return "Please select a status"
      }

      return true
    },
  },
  [REMOVE_ALL_MEMBERS]: {
    subtypeConnectorText: "",
    subtypes: {},
  },
  [ADD_MEMBERS]: {
    subtypeConnectorText: "",
    subtypes: {
      [DEFAULT_SUBTYPE]: {
        dataDefiner: DataDefiners.DefineAddMembersSelectedUsersData,
      },
    },
    validations: (_model, { data }) => {
      if (
        data?.members
        && Object.values(data.members).flat().length === 0
      ) {
        return "Please select at least one member"
      }

      return true
    },
  },
  [ADD_DEPARTMENT]: {
    subtypeConnectorText: "",
    subtypes: {
      [DEFAULT_SUBTYPE]: {
        dataDefiner: DataDefiners.DefineAddDepartmentData,
      },
    },
    validations: (_model, { data }) => {
      if (!data?.questionUUID) {
        return "Please select a location question"
      }

      return true
    },
  },
  [SEND_NOTIFICATION]: {
    subtypeConnectorText: "to",
    subtypes: {
      [NOTIFY_ALL_MEMBERS]: {
        dataDefiner: DataDefiners.NoDataToDefine,
      },
      [NOTIFY_SELECTED]: {
        dataDefiner: DataDefiners.DefineNotifySelectedUsersData,
      },
    },
    validations: (_model, { subtype, data }) => {
      if (
        subtype === NOTIFY_SELECTED
        && data?.recipients
        && Object.values(data.recipients).flat().length === 0
      ) {
        return "Please select at least one recipient"
      }

      return true
    },
  },
}

export const subtypeConnectorText = (type) => (
  ACTION_DETAILS[type]?.subtypeConnectorText
)

export const subtypes = (type) => {
  const actionSubtypes = ACTION_DETAILS[type]?.subtypes

  if (!actionSubtypes) return null

  return Object.keys(actionSubtypes)
}

export const dataDefiner = ({ subtype, type }) => (
  ACTION_DETAILS[type]?.subtypes?.[subtype]?.dataDefiner
)

export const conditionOperatorDefiner = (property) => (
  CONDITION_DETAILS[property]?.operatorDefiner || (() => (""))
)

export const conditionValueDefiner = (property) => (
  CONDITION_DETAILS[property]?.valueDefiner || (() => (""))
)

export const actionValidations = (type) => (
  ACTION_DETAILS[type]?.validations ?? (() => true)
)

export const newAction = (type) => {
  const subtypesForType = subtypes(type)
  let data
  switch (type) {
  case CREATE_TASK: {
    data = {
      task: {
        title: "",
        description: "",
        due_date: "",
        subform_id: undefined,
        relative_due_date: {},
        assignee_ids: [],
        member_ids: [],
        notify_ids: [],
        notify_on_complete_ids: [],
        assignee_group_ids: [],
        member_group_ids: [],
        notify_group_ids: [],
        notify_on_complete_group_ids: [],
        member_departments: [],
        assignee_departments: [],
      },
    }
    break
  }
  default:
    data = {}
  }

  return {
    type: type || "",
    subtype: subtypesForType?.[0] || DEFAULT_SUBTYPE,
    data,
    uuid: makeUuid(),
  }
}

export const newCondition = (property) => ({
  uuid: makeUuid(),
  property: property || "",
  operator: "",
  // { statusID } | { days }
  value: {},
})
