import React, { useEffect } from "react"
import types from "prop-types"
import clsx from "clsx"
import { Select, IconSelect } from "shared/selects"
import { snakeToCapitalCase } from "utils/stringHelpers"
import FormsyValidation from "shared/FormsyValidation"
import { FORM_TRIGGER_OPTIONS, FREQUENCY_OPTIONS, isRecurringEvent } from "utils/workflowHelpers"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const optionList = (options) => options.map((value) => ({ value, label: snakeToCapitalCase(value) }))

const renderContentIcon = ({ icon }) => <SvgSpriteIcon iconName={icon} />
const itemRenderer = ({ item, methods }) => (
  <div
    className={clsx(
      "flex items-center py-2.5",
      isRecurringEvent(item.value) && "py-3 border-t border-light",
    )}
    onClickCapture={() => methods.addItem(item)}
  >
    <SvgSpriteIcon iconName={item.icon} className="mx-2" />
    <span className="text-sm font-medium">{ item.label }</span>
  </div>
)

const FormTrigger = ({ trigger, frequency, setWorkflow }) => {
  const resetFrequency = () => setWorkflow((workflow) => ({ ...workflow, frequency: undefined }))

  useEffect(() => {
    if (!isRecurringEvent && frequency) {
      resetFrequency()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecurringEvent, trigger])

  return (
    <div className="flex items-center">
      <IconSelect
        placeholder="Select trigger... *"
        required
        style={{ height: "32px", minWidth: "285px" }}
        onChange={(value) => setWorkflow((wf) => ({ ...wf, trigger: value }))}
        value={trigger}
        options={FORM_TRIGGER_OPTIONS}
        renderContentIcon={renderContentIcon}
        itemRenderer={itemRenderer}
        backspaceDelete={false}
      />
      <FormsyValidation
        name="trigger"
        value={trigger}
        validations={{ isNotBlankString: true }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
      {
        isRecurringEvent(trigger) && (
          <>
            <span className="mx-2">run it</span>
            <div>
              <Select
                placeholder="Select frequency... *"
                value={frequency}
                style={{ height: "32px" }}
                required={isRecurringEvent(trigger)}
                options={optionList(FREQUENCY_OPTIONS)}
                onChange={(value) => setWorkflow((wf) => ({ ...wf, frequency: value }))}
              />
              <FormsyValidation
                name="frequency"
                value={frequency}
                validations={{ isNotBlankString: isRecurringEvent(trigger) }}
              />
            </div>
          </>
        )
      }
    </div>
  )
}

FormTrigger.defaultProps = {
  trigger: "",
  frequency: "",
}

FormTrigger.propTypes = {
  trigger: types.string,
  frequency: types.string,
  setWorkflow: types.func.isRequired,
}

export default FormTrigger
