import React, { useCallback, useMemo } from "react"
import types from "prop-types"
import clsx from "clsx"
import { useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import {
  addMemberToDepartmentSegment,
  facilityDepartmentMembers,
  facilityId as getFacilityId,
} from "reduxSlices/adminFacilitySlice"
import * as API from "services/api"
import { departmentSegmentShape } from "utils/propTypeShapes"
import { errorToast } from "shared/toast"
import MembersList from "./MembersList"
import { DraggableItemTypes } from "../DraggableItemTypes"

const DepartmentSegment = ({ className, departmentId, segment }) => {
  const { name, members } = segment

  const dispatch = useDispatch()

  const facilityId = useSelector(getFacilityId)
  const departmentMembers = useSelector(facilityDepartmentMembers(departmentId))

  const updateSegmentMembersInStore = useCallback(({ member, segmentName = name }) => {
    dispatch(addMemberToDepartmentSegment({
      departmentId,
      member,
      segmentName,
    }))
  }, [departmentId, dispatch, name])

  // Functions for adding a new member to the department
  const canAddNewDepartmentMemberToSegment = useCallback((draggableUserProfileIcon) => (
    !departmentMembers.find((member) => member.id === draggableUserProfileIcon.id)
  ), [departmentMembers])

  const addDepartmentSegmentMember = useCallback(async (draggableUserProfileIcon) => {
    const response = await API.createDepartmentMembership({
      departmentId,
      departmentMembership: {
        memberId: draggableUserProfileIcon.id,
        segmentName: name,
      },
      facilityId,
    })

    if (response.ok) {
      updateSegmentMembersInStore({ member: response.data })
    } else {
      errorToast("Something went wrong.  Unable to add department member.")
    }
  }, [departmentId, facilityId, name, updateSegmentMembersInStore])

  // Functions for moving a member to a different segment
  const canMoveMemberToSegment = useCallback((draggableMemberProfileItem) => {
    if (draggableMemberProfileItem.departmentId !== departmentId) return false
    if (draggableMemberProfileItem.segmentName === name) return false

    return true
  }, [departmentId, name])

  const moveMemberToSegment = useCallback(async (draggableMemberProfileItem) => {
    updateSegmentMembersInStore({ member: draggableMemberProfileItem.user })

    const response = await API.updateDepartmentMemberSegment({
      departmentId,
      facilityId,
      memberId: draggableMemberProfileItem.memberId,
      segmentName: name,
    })

    if (!response.ok) {
      errorToast("Something went wrong.  Unable to change segment.")
      updateSegmentMembersInStore({
        member: draggableMemberProfileItem.user,
        segmentName: draggableMemberProfileItem.segmentName,
      })
    }
  }, [departmentId, facilityId, name, updateSegmentMembersInStore])

  // Drop zone setup
  // Handle two types of draggable items.
  // If the item is a user profile icon, attempts to add the user to the department.
  // If the item is a member profile icon, attempts to change the segment.
  const dropCallbacks = useMemo(
    () => (
      {
        [DraggableItemTypes.MEMBER_PROFILE_ICON]: {
          canDrop: canMoveMemberToSegment,
          drop: moveMemberToSegment,
        },
        [DraggableItemTypes.USER_PROFILE_ICON]: {
          canDrop: canAddNewDepartmentMemberToSegment,
          drop: addDepartmentSegmentMember,
        },
      }
    ),
    [
      canMoveMemberToSegment,
      moveMemberToSegment,
      canAddNewDepartmentMemberToSegment,
      addDepartmentSegmentMember,
    ],
  )

  const [collected, drop] = useDrop(() => ({
    accept: [
      DraggableItemTypes.MEMBER_PROFILE_ICON,
      DraggableItemTypes.USER_PROFILE_ICON,
    ],
    canDrop: (item, monitor) => (
      dropCallbacks[monitor.getItemType()].canDrop(item)
    ),
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item, monitor) => {
      dropCallbacks[monitor.getItemType()].drop(item)
    },
  }), [dropCallbacks])

  const classList = clsx(
    className,
    "flex gap-4 items-start",
    (
      collected.isOver && collected.canDrop && "bg-green-200 !border-green-800"
    ),
  )

  return (
    <div ref={drop} className={classList}>
      <span>{name.toUpperCase()}</span>
      <MembersList className="grow" departmentId={departmentId} members={members} segmentName={name} />
    </div>
  )
}

DepartmentSegment.defaultProps = {
  className: "",
}

DepartmentSegment.propTypes = {
  className: types.string,
  departmentId: types.number.isRequired,
  segment: departmentSegmentShape.isRequired,
}

export default DepartmentSegment
