import React from "react"
import types from "prop-types"
import clxs from "clsx"
import { useSelector } from "react-redux"
import { getCategorySubforms } from "reduxSlices/formBuilderSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { GENERAL_TASK_OPTION } from "utils/taskHelpers"
import { Select } from "shared/selects"
import SubformDropdown from "shared/selects/SubformDropdown"
import FormsyValidation from "shared/FormsyValidation"

const subformsToOptions = (subforms) => subforms.map(({ id, title }) => (
  { label: title, value: id }
))

const subformOptions = (subforms) => (
  [
    { ...GENERAL_TASK_OPTION },
    ...subformsToOptions(subforms),
  ]
)

const TaskSubformSelectInput = ({ actionIndex, className }) => {
  const { taskSubformId, updateTaskSubformId } = useTaskFormContext()

  const subforms = useSelector(getCategorySubforms)

  return (
    <div className={clxs("w-max", className)}>
      <span className="block mb-1 font-semibold required-prompt">Task Form</span>
      <Select
        backspaceDelete={false}
        dropdownRenderer={SubformDropdown}
        onChange={updateTaskSubformId}
        options={subformOptions(subforms)}
        value={taskSubformId}
      />
      <FormsyValidation
        name={`actions[${actionIndex}].data.task.subform_id`}
        required
        validationErrors={{ isDefaultRequiredValue: "Selection required" }}
        value={taskSubformId}
      />
    </div>
  )
}

TaskSubformSelectInput.defaultProps = {
  className: "",
}

TaskSubformSelectInput.propTypes = {
  actionIndex: types.number.isRequired,
  className: types.string,
}

export default TaskSubformSelectInput
