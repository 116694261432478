import React from "react"
import types from "prop-types"
import TaskGroup from "../TaskGroup"

const TaskGroupList = ({ className, removeGroup, groups }) => {
  const removeFromTask = (id) => () => removeGroup(id)

  return (
    <div className={className}>
      <div className="flex justify-between items-end font-semibold text-sm text-right pr-9 mb-2">
        <div>
          <span>Groups</span>
        </div>
        <div className="flex gap-x-2">
          <div className="w-20 text-center">Notify on Assign</div>
          <div className="w-20 text-center">Notify on Complete</div>
        </div>
      </div>
      {
        groups.map((group) => (
          <TaskGroup key={`task-group-${group.id}`} removeFromTask={removeFromTask(group.id)} group={group} />
        ))
      }
    </div>
  )
}

TaskGroupList.defaultProps = {
  className: "",
}

TaskGroupList.propTypes = {
  className: types.string,
  removeGroup: types.func.isRequired,
  groups: types.arrayOf(types.object).isRequired,
}

export default TaskGroupList
