import React from "react"
import types from "prop-types"
import { SearchSelectedUser } from "shared/searches"
import { AccessLevelSelect } from "shared/selects"
import activeBeforeInactive from "utils/activeBeforeInactive"

const SelectedUsers = ({
  users, removeUser, setUserAccessLevel, workflowData,
}) => {
  if (users.length === 0) {
    return <div className="ml-4">No Selected User</div>
  }

  users = users.sort(activeBeforeInactive)

  const selectedAccessLevel = (userId) => workflowData.find((user) => user.id === userId).access_level

  return (
    users.map((user) => (
      <SearchSelectedUser
        key={user.id}
        user={user}
        className="w-11/12"
        deselect={() => removeUser(user.id)}
      >
        {
          setUserAccessLevel && !!workflowData.length && (
            <AccessLevelSelect
              onChange={(value) => setUserAccessLevel(value, user.id)}
              value={selectedAccessLevel(user.id)}
            />
          )
        }
      </SearchSelectedUser>
    ))
  )
}

SelectedUsers.defaultProps = {
  workflowData: [],
  setUserAccessLevel: null,
}

SelectedUsers.propTypes = {
  users: types.arrayOf(types.object).isRequired,
  removeUser: types.func.isRequired,
  setUserAccessLevel: types.func,
  workflowData: types.arrayOf(types.object),
}

export default SelectedUsers
