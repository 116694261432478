import React from "react"
import { endSession } from "utils/endSession"

const Logout = () => (
  <div className="button_to">
    <button
      className="flex-center flex-col outline-none"
      onClick={endSession}
      type="submit"
    >
      <i className="fas fa-sign-out-alt" />
      <span>Logout</span>
    </button>
  </div>
)

export default Logout
