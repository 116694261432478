import React from "react"
import types from "prop-types"
import * as ReactModal from "react-modal" // https://github.com/reactjs/react-modal

export { default as ModalHeader } from "./ModalHeader"

ReactModal.defaultStyles.overlay.zIndex = "99"

export const DEFAULT_MODAL_STYLE = {
  content: {
    top: "50%",
    left: "50%",
    minWidth: "300px",
    maxWidth: "600px",
    height: "fit-content",
    padding: "40px 20px",
    border: "1px solid #E5E7F5",
    borderRadius: "8px",
    transform: "translate(-50%, -50%)",
  },
}

const Modal = ({
  children, isOpen, style = DEFAULT_MODAL_STYLE, onAfterOpen = () => {},
}) => (
  <ReactModal
    isOpen={isOpen}
    appElement={document.querySelector("body")}
    style={style}
    onAfterOpen={onAfterOpen}
  >
    {children}
  </ReactModal>
)

Modal.propTypes = {
  onAfterOpen: types.func,
  children: types.node.isRequired,
  isOpen: types.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: types.object,
}

export default Modal
