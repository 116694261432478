import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import FormsyValidation from "shared/FormsyValidation"
import { FormsyHiddenInput } from "shared/inputs"
import TaskAssigneeDepartmentSelector from "./TaskAssigneeDepartmentSelector"
import TaskAssigneeGroupList from "./TaskAssigneeGroupList"
import TaskAssigneeGroupSelector from "./TaskAssigneeGroupSelector"
import TaskAssigneeList from "./TaskAssigneeList"
import TaskAssigneeSelector from "./TaskAssigneeSelector"
import TaskAssigneeDepartmentList from "./TaskAssigneeDepartmentList"

const TaskAssignees = ({ actionIndex, className }) => {
  const { taskAssigneeDepartments, taskAssigneeIds, taskAssigneeGroupIds } = useTaskFormContext()

  return (
    <div className={className}>
      <span className="block mb-1 font-semibold required-prompt">Assignees</span>
      <TaskAssigneeList className="mb-4" />
      <TaskAssigneeGroupList className="mb-4" />
      <TaskAssigneeDepartmentList className="mb-4" />
      <div className="relative max-w-max">
        <div className="flex">
          <TaskAssigneeSelector className="mr-2" />
          <TaskAssigneeGroupSelector className="mr-2" />
          <TaskAssigneeDepartmentSelector />
        </div>
        <FormsyValidation
          name={`actions[${actionIndex}].data.task.assignees_validation`}
          validations={{
            requireAssignees: (values) => {
              if (values[`actions[${actionIndex}].data.task.assignee_ids`]?.length > 0) return true
              if (values[`actions[${actionIndex}].data.task.assignee_group_ids`]?.length > 0) return true
              if (values[`actions[${actionIndex}].data.task.assignee_departments`]?.length > 0) return true

              return "task must be assigned to a user, group, or department through location question answer"
            },
            requireDepartmentSegments: (values) => {
              const departments = values[`actions[${actionIndex}].data.task.assignee_departments`]

              if (!departments?.length > 0) return true
              if (departments.filter((dept) => !dept.segments?.length > 0).length === 0) return true

              return "at least one department segment must be selected"
            },
          }}
        />
      </div>
      <FormsyHiddenInput name={`actions[${actionIndex}].data.task.assignee_ids`} value={taskAssigneeIds} />
      <FormsyHiddenInput name={`actions[${actionIndex}].data.task.assignee_group_ids`} value={taskAssigneeGroupIds} />
      <FormsyHiddenInput
        name={`actions[${actionIndex}].data.task.assignee_departments`}
        value={taskAssigneeDepartments ?? []}
      />
    </div>
  )
}

TaskAssignees.defaultProps = {
  className: "",
}

TaskAssignees.propTypes = {
  actionIndex: types.number.isRequired,
  className: types.string,
}

export default TaskAssignees
