import React from "react"
import types from "prop-types"
import { SearchSelectedGroup } from "shared/searches"
import AccessLevelIdentifier from "./AccessLevelIdentifier"

const DepartmentGranteeList = ({
  departments, formSubmissionSlug, updateAccessGranteeLists, deleteFormSubmissionGrant, isDisabled,
}) => {
  if (!departments.length) {
    return <span>No Department with Grants</span>
  }

  return (
    departments.map(({
      grantee, formGrant, categoryGrant, formSubmissionGrant,
    }) => (
      <SearchSelectedGroup
        key={grantee.id}
        group={grantee}
        showDeselectButton={!isDisabled && !!formSubmissionGrant}
        deselect={deleteFormSubmissionGrant(formSubmissionGrant?.id)}
      >
        <AccessLevelIdentifier
          formSubmissionSlug={formSubmissionSlug}
          formSubmissionGrant={formSubmissionGrant}
          formGrant={formGrant}
          categoryGrant={categoryGrant}
          updateAccessGranteeLists={updateAccessGranteeLists}
          granteeType="Department"
          granteeId={grantee.id}
          isDisabled={isDisabled}
        />
      </SearchSelectedGroup>
    ))
  )
}

DepartmentGranteeList.propTypes = {
  departments: types.arrayOf(types.object).isRequired,
  formSubmissionSlug: types.string.isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  deleteFormSubmissionGrant: types.func.isRequired,
  isDisabled: types.bool.isRequired,
}

export default DepartmentGranteeList
