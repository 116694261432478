import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { LargeWrap } from "shared/wraps"
import { FormLightIcon } from "shared/icons"
import { NewTaskForm } from "FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms"
import TasksHeader from "./TasksHeader"

const EmptyActivities = ({
  taskFormIsOpen, openForm, closeForm, hiddenSidebar, toggleSidebar,
}) => (
  <LargeWrap className="mt-20 relative">
    <TasksHeader
      openForm={openForm}
      hiddenSidebar={hiddenSidebar}
      toggleSidebar={toggleSidebar}
    />
    <div className={clsx("flex flex-col items-center pb-48", !hiddenSidebar && "ml-80")}>
      <FormLightIcon className="w-16 h-16" />
      <span className="my-8">You do not have any activities yet</span>
      <div className="w-2/3">
        <NewTaskForm
          taskFormIsOpen={taskFormIsOpen}
          onCancel={closeForm}
          onCreate={closeForm}
        />
      </div>
    </div>
  </LargeWrap>
)

EmptyActivities.propTypes = {
  taskFormIsOpen: types.bool.isRequired,
  openForm: types.func.isRequired,
  closeForm: types.func.isRequired,
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default EmptyActivities
