import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { RemoveButton } from "shared/buttons"
import Switch from "shared/Switch"

const TaskGroup = ({ removeFromTask, group }) => {
  const {
    addNotifyGroupId,
    addNotifyOnCompleteGroupId,
    removeNotifyGroupId,
    removeNotifyOnCompleteGroupId,
    taskNotifyGroupIds,
    taskNotifyOnCompleteGroupIds,
  } = useTaskFormContext()

  const groupNotified = taskNotifyGroupIds.includes(group.id)
  const groupNotifiedOnComplete = taskNotifyOnCompleteGroupIds.includes(group.id)

  const toggleNotifyId = () => {
    const toggleFunction = groupNotified ? removeNotifyGroupId : addNotifyGroupId
    toggleFunction(group.id)
  }

  const toggleNotifyOnCompleteId = () => {
    const toggleFunction = groupNotifiedOnComplete ? removeNotifyOnCompleteGroupId : addNotifyOnCompleteGroupId
    toggleFunction(group.id)
  }

  return (
    <div className="flex mb-2">
      <div className="h-12 flex flex-grow items-center bg-primary-light rounded-md p-2 mr-2">
        <span className="flex-grow mr-3">{group.name}</span>
        <div className="flex gap-x-2">
          <div className="flex justify-center items-center w-20">
            <Switch
              checked={groupNotified}
              onChange={toggleNotifyId}
            />
          </div>
          <div className="flex justify-center items-center w-20">
            <Switch
              checked={groupNotifiedOnComplete}
              onChange={toggleNotifyOnCompleteId}
            />
          </div>
        </div>
      </div>
      <RemoveButton className="self-center justify-self-end" onClick={removeFromTask} />
    </div>
  )
}

TaskGroup.propTypes = {
  removeFromTask: types.func.isRequired,
  group: types.shape({
    id: types.number.isRequired,
    name: types.string.isRequired,
  }).isRequired,
}

export default TaskGroup
