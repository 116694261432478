import React, { useRef, useState, useEffect } from "react"
import types from "prop-types"
import clsx from "clsx"

const TruncateText = ({ children, truncateOn = "", className = "" }) => {
  const [isOverflowing, setIsOverflowing] = useState(false)

  const truncateRef = useRef(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const truncateEl = truncateRef.current
    if (truncateEl?.firstElementChild) {
      const {
        firstElementChild: { scrollWidth, clientWidth },
      } = truncateEl
      setIsOverflowing(scrollWidth > clientWidth)
    }
  })

  return (
    <div
      ref={truncateRef}
      className={clsx(className, "truncate-middle")}
      data-truncate-on={isOverflowing ? truncateOn : ""}
    >
      { children }
    </div>
  )
}

TruncateText.propTypes = {
  children: types.node.isRequired,
  truncateOn: types.string,
  className: types.string,
}

export default TruncateText
