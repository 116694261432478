import React, { useCallback, useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import DrawerContent from "./DrawerContent"
import DrawerToggle from "./DrawerToggle"

const DEFAULT_CLASSES = "absolute flex right-0 top-0 bottom-0 mt-44"

const Drawer = ({ children, className = DEFAULT_CLASSES, onOpen = undefined }) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeDrawer = useCallback(() => setIsOpen(false), [setIsOpen])

  const openDrawer = useCallback(() => {
    onOpen?.()
    setIsOpen(true)
  }, [onOpen, setIsOpen])

  return (
    <div className={clsx(className)}>
      <DrawerToggle
        className="self-start"
        isOpen={isOpen}
        toggleDrawer={isOpen ? closeDrawer : openDrawer}
      />
      <DrawerContent isOpen={isOpen}>
        { children }
      </DrawerContent>
    </div>
  )
}

Drawer.propTypes = {
  children: types.node.isRequired,
  className: types.string,
  onOpen: types.func,
}

export default Drawer
