import React, { Component, createRef } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import { connect } from "react-redux"
import * as API from "services/api"
import { addTask } from "reduxSlices/formSubmissionSlice"
import { TaskFormContextProvider } from "contexts/TaskFormContext"
import { RemoveButton } from "shared/buttons"
import { errorToast, successToast } from "shared/toast"
import { CancelButton, SaveTaskButton } from "./TaskButtons"
import * as TaskFields from "./TaskFields"

class NewTaskForm extends Component {
  state = { canSubmit: false }

  formRef = createRef()

  componentDidUpdate(prevProp) {
    const { taskFormIsOpen } = this.props

    if (taskFormIsOpen && prevProp.taskFormIsOpen !== taskFormIsOpen) {
      document.getElementById("new-task-form")?.scrollIntoView()
    }
  }

  enableSubmit = () => this.setState({ canSubmit: true })

  disableSubmit = () => this.setState({ canSubmit: false })

  currentTaskData = () => this.formRef.current?.getModel() || { task: {} }

  cancel = () => {
    const { onCancel } = this.props

    this.formRef.current?.reset()
    if (onCancel) onCancel()
  }

  createTask = async (model) => {
    const { canSubmit } = this.state

    if (!canSubmit) return

    const {
      addTask: addNewTask,
      formSubmissionSlug,
      onCreate,
    } = this.props

    const createTaskResponse = await API.createTask({ formSubmissionSlug, model })

    if (!createTaskResponse.ok) {
      errorToast("Something went wrong. Unable to create task.")
      console.error("ERROR CREATING TASK: ", createTaskResponse.data)
      return
    }

    const { data: newTask } = createTaskResponse

    addNewTask(newTask)
    this.formRef.current?.reset()
    if (onCreate) onCreate()
    successToast("Task created successfully!")
  }

  render() {
    const { onCancel, taskFormIsOpen } = this.props

    if (!taskFormIsOpen) return null

    return (
      <TaskFormContextProvider>
        <div
          id="new-task-form"
          className="mb-6 grid"
          style={{ gridTemplateColumns: "30px 100%" }}
        >
          <div />
          <div className="light-shadow rounded-lg border border-lightgray-2 bg-white py-6 px-8">
            <div className="flex-center mb-4">
              <span />
              <h3 className="m-0">New Task</h3>
              <RemoveButton onClick={onCancel} />
            </div>
            <Formsy
              id="task-form"
              onValidSubmit={this.createTask}
              onValid={this.enableSubmit}
              onInvalid={this.disableSubmit}
              ref={this.formRef}
            >
              <TaskFields.Title className="mb-8" />
              <TaskFields.Description className="mb-8" />
              <div className="flex mb-8">
                <TaskFields.DueDate className="mr-8" />
                <TaskFields.Subform />
              </div>
              <TaskFields.NotifyGroupIds />
              <TaskFields.NotifyOnCompleteGroupIds />
              <TaskFields.NotifyIds />
              <TaskFields.NotifyOnCompleteIds />
              <TaskFields.Assignees className="mb-8" />
              <TaskFields.Members className="mb-8" />
              <TaskFields.OtherTaskAccess className="mb-12" />
              <div className="flex flex-col items-center mb-8">
                <div>
                  <CancelButton cancel={this.cancel} />
                  <SaveTaskButton text="Add this Task" />
                </div>
              </div>
            </Formsy>
          </div>
        </div>
      </TaskFormContextProvider>
    )
  }
}

NewTaskForm.defaultProps = {
  onCancel: undefined,
  onCreate: undefined,
  formSubmissionSlug: "",
}

NewTaskForm.propTypes = {
  addTask: types.func.isRequired,
  taskFormIsOpen: types.bool.isRequired,
  formSubmissionSlug: types.string,
  onCancel: types.func,
  onCreate: types.func,
}

const mapStateToProps = (state) => (
  {
    formSubmissionSlug: state.formSubmission.data.slug,
  }
)

export default connect(mapStateToProps, { addTask })(NewTaskForm)
