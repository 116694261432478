import React from "react"
import types from "prop-types"

const ScimDisplay = ({ user }) => (
  <>
    <div className="flex-center gap-x-6">
      <div className="flex-grow">
        <div className="mb-px">First Name</div>
        <div className="text-sm mb-4 px-3 py-2 border border-light rounded-md cursor-not-allowed opacity-75">{user.first_name}</div>
      </div>

      <div className="flex-grow">
        <div className="mb-px">Last Name</div>
        <div className="text-sm mb-4 px-3 py-2 border border-light rounded-md cursor-not-allowed opacity-75">{user.last_name}</div>
      </div>
    </div>
    <div className="mb-px">Email</div>
    <div className="text-sm mb-4 px-3 py-2 border border-light rounded-md cursor-not-allowed opacity-75">{user.email}</div>
    <div className="absolute-horizontal-center bottom-16 text-sm text-center text-dark space-y-1">
      <p>Your organization manages users via the SCIM User Provisioning feature.</p>
      <p>Please contact your internal support to edit these attributes.</p>
    </div>
  </>
)

ScimDisplay.propTypes = {
  user: types.object.isRequired,
}

export default ScimDisplay
