import React from "react"
import types from "prop-types"
import { TaskGeneralIcon, TaskSubformIcon, CheckmarkCircleIcon } from "shared/icons"

const TaskIcon = ({ isSubform, isOpen }) => (
  <div className="relative">
    {
      isSubform
        ? <TaskSubformIcon className="mr-1.5 w-8 h-8" />
        : <TaskGeneralIcon className="mr-1.5 w-8 h-8" />
    }
    {
      !isOpen && <CheckmarkCircleIcon className="absolute h-3 w-3 right-2 bottom-1" />
    }
  </div>
)

TaskIcon.propTypes = {
  isSubform: types.bool.isRequired,
  isOpen: types.bool.isRequired,
}

export default TaskIcon
