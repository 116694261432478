import React from "react"
import types from "prop-types"
import FormsyValidation from "shared/FormsyValidation"

const NoBlankOptionsValidation = ({ value, sectionIndex, questionIndex }) => (
  <FormsyValidation
    name={`sections[${sectionIndex}].questions[${questionIndex}].noBlankOptions`}
    value={value}
    validations={{
      noBlankOptions(_model, val) {
        if (val?.every(Boolean)) return true
        return "Blank options prohibited"
      },
    }}
  />
)

NoBlankOptionsValidation.defaultProps = {
  value: [],
}

NoBlankOptionsValidation.propTypes = {
  value: types.arrayOf(types.string),
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default NoBlankOptionsValidation
