import React from "react"
import { AttachmentTypeIcon } from "shared/icons"
import { PrimaryButton } from "components/shared/buttons"
import { useFormSubmissionAttachmentDropzoneContext } from "contexts/FormSubmissionAttachmentDropzoneContext"

const EmptyAttachments = () => {
  const { openAttachmentFileDialog } = useFormSubmissionAttachmentDropzoneContext()

  return (
    <div className="flex flex-col items-center pb-80 pt-48 ml-4">
      <AttachmentTypeIcon className="h-8 w-8 my-2" fileType="" />
      <p className="font-bold my-2">Drag and drop files here </p>
      <p className="my-2 text-sm">or</p>
      <PrimaryButton className="my-2" text="Browse files" onClick={openAttachmentFileDialog} />
      <p className="my-4 text-sm">Attachment size limit: 35 MB</p>
    </div>
  )
}

export default EmptyAttachments
