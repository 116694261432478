import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { withFormsy } from "formsy-react"
import InputErrorMessage from "shared/InputErrorMessage"
import RadioButtonNumber from "./RadioButtonNumber"

const RadioButtonLabeledGroupInput = ({
  className = "",
  columnCount = 1,
  disabled = false,
  errorMessages = [],
  isPristine = true,
  onChange,
  options,
  questionUuid,
  radioInputClassName = "",
  value = null,
}) => (
  <>
    <div className={`${className} grid grid-cols-${columnCount}`}>
      {
        options.map((option) => (
          <RadioButtonNumber
            key={option.value}
            className={clsx("p-4", radioInputClassName, !option.active && "text-gray-200")}
            disabled={disabled}
            value={option.value}
            label={option.label}
            name={`${questionUuid}-${option.label}`}
            checked={option.value === value}
            onChange={onChange}
            questionUuid={questionUuid}
            title={option.active ? undefined : `${option.label} is inactive`}
          />
        ))
      }
    </div>
    <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
  </>
)

RadioButtonLabeledGroupInput.propTypes = {
  className: types.string,
  columnCount: types.number,
  disabled: types.bool,
  errorMessages: types.arrayOf(types.string),
  isPristine: types.bool,
  onChange: types.func.isRequired,
  options: types.arrayOf(types.object).isRequired,
  questionUuid: types.string.isRequired,
  radioInputClassName: types.string,
  value: types.number,
}

export const FormsyRadioButtonLabeledGroupInput = withFormsy(({ setValue, ...rest }) => (
  <RadioButtonLabeledGroupInput
    onChange={setValue}
    {...rest}
  />
))

export default RadioButtonLabeledGroupInput
