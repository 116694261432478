import React from "react"
import types from "prop-types"
import clsx from "clsx"

const RadioButtonNumber = ({
  checked,
  className = "",
  disabled = false,
  label = "",
  name = "",
  onChange,
  questionUuid,
  title = "",
  value,
}) => {
  const identifier = `${questionUuid}-${name}-${value}`

  return (
    <div
      className={clsx(className, "flex items-center cursor-pointer")}
      title={title}
      onClickCapture={() => onChange(value)}
    >
      <input
        checked={checked}
        className="mr-2 cursor-pointer"
        disabled={disabled}
        id={identifier}
        name={name}
        onChange={(event) => onChange(Number(event.target.value))}
        type="radio"
        value={value}
      />
      <label className="cursor-pointer" htmlFor={identifier}>{ label || value }</label>
    </div>
  )
}

RadioButtonNumber.propTypes = {
  checked: types.bool.isRequired,
  className: types.string,
  disabled: types.bool,
  label: types.string,
  name: types.string,
  onChange: types.func.isRequired,
  questionUuid: types.string.isRequired,
  title: types.string,
  value: types.number.isRequired,
}

export default RadioButtonNumber
