import React from "react"
import { LargeWrap } from "shared/wraps"
import { useSelector } from "react-redux"
import { getIsWorkflowFormVisible } from "reduxSlices/formBuilderSlice"
import WorkflowList from "./WorkflowList"
import WorkflowHeader from "./WorkflowHeader"
import WorkflowForm from "./WorkflowForm"

const WorkflowsTab = () => {
  const isWorkflowFormVisible = useSelector(getIsWorkflowFormVisible)

  if (isWorkflowFormVisible) {
    return <WorkflowForm />
  }

  return (
    <LargeWrap className="mt-32">
      <WorkflowHeader />
      <WorkflowList />
    </LargeWrap>
  )
}

export default WorkflowsTab
