import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  formSubmissionIsClosed as getFormSubmissionIsClosed,
  updateTask,
} from "reduxSlices/formSubmissionSlice"
import { taskShape } from "utils/propTypeShapes"
import * as API from "services/api"
import CloseTaskButton from "./CloseTaskButton"
import ReopenTaskButton from "./ReopenTaskButton"

const TaskStatusControl = ({ task }) => {
  const { isOpen, userTaskPermissions } = task

  const formSubmissionIsClosed = useSelector(getFormSubmissionIsClosed)

  const dispatch = useDispatch()

  const closeTask = async () => {
    const response = await API.closeTask({ taskId: task.id })

    if (response.ok) {
      const { data: updatedTask } = response
      updatedTask.expanded = false
      dispatch(updateTask(updatedTask))
    } else {
      console.log("CLOSING TASK FAILED: ", response.data)
    }
  }

  const reopenTask = async () => {
    const response = await API.reopenTask({ taskId: task.id })

    if (response.ok) {
      const { data: updatedTask } = response
      updatedTask.expanded = true
      dispatch(updateTask(updatedTask))
    } else {
      console.log("REOPENING TASK FAILED: ", response.data)
    }
  }

  if (formSubmissionIsClosed || !userTaskPermissions.canChangeStatus) return null

  return (
    isOpen
      ? <CloseTaskButton closeTask={closeTask} task={task} />
      : <ReopenTaskButton reopenTask={reopenTask} />
  )
}

TaskStatusControl.propTypes = {
  task: taskShape.isRequired,
}

export default TaskStatusControl
