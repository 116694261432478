import React from "react"
import types from "prop-types"
import { formShape } from "utils/propTypeShapes"
import { TaskSubformIcon, TaskGeneralIcon } from "shared/icons"

const TaskTokenTitle = ({ subform, title }) => {
  const isSubform = !!subform
  const formTitle = title?.length ? title : "General Task"

  if (isSubform) {
    return (
      <span className="ml-4 mr-5 text-token h-9 font-medium p-1.5">
        <TaskSubformIcon className="mr-1.5 h-6 w-6 bg-white" />
        { subform.title }
      </span>
    )
  }

  return (
    <span className="ml-4 mr-5 text-token h-9 font-medium p-1.5">
      <TaskGeneralIcon className="mr-1.5 h-6 w-6 bg-white" />
      <span>{formTitle}</span>
    </span>
  )
}

TaskTokenTitle.defaultProps = {
  subform: {},
  title: "",
}

TaskTokenTitle.propTypes = {
  subform: formShape,
  title: types.string,
}

export default TaskTokenTitle
