import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormAcceptsSubmittedFiles,
  getFormSlug,
  setFormAcceptsFiles,
} from "reduxSlices/formBuilderSlice"
import { isFeatureEnabled } from "reduxSlices/featuresSlice"
import * as API from "services/api"
import { FORM_FILE_UPLOADS_FEATURE } from "utils/featuresHelpers"
import Switch from "shared/Switch"
import { errorToast } from "components/shared/toast"
import ContentBar from "shared/lists/ContentBar"

const TITLE = "Accept Attachments with Submissions"
const DESCRIPTION = "When allowed, a user can attach files to their submission."

const FormAcceptsSubmittedFilesSetting = () => {
  const dispatch = useDispatch()

  const formAcceptsSubmittedFiles = useSelector(getFormAcceptsSubmittedFiles)
  const formSlug = useSelector(getFormSlug)

  const formFileUploadsFeatureEnabled = useSelector(isFeatureEnabled(FORM_FILE_UPLOADS_FEATURE))

  if (!formFileUploadsFeatureEnabled) return null

  const updateAcceptsFiles = async (acceptsFiles) => {
    const response = await API.updateFormAcceptsFiles({ formSlug, acceptsFiles })
    if (response.ok) {
      dispatch(setFormAcceptsFiles(acceptsFiles))
    } else {
      errorToast("Something went wrong.  Unable to update the accept attachments with submissions setting.")
    }
  }

  return (
    <ContentBar
      title={TITLE}
      description={DESCRIPTION}
    >
      <Switch
        checked={formAcceptsSubmittedFiles}
        onChange={updateAcceptsFiles}
      />
    </ContentBar>
  )
}

export default FormAcceptsSubmittedFilesSetting
