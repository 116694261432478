import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { withFormsy } from "formsy-react"
import InputErrorMessage from "shared/InputErrorMessage"
import RadioButton from "./RadioButton"

const RadioButtonGroupInput = ({
  className = "",
  columnCount = 1,
  disabled = false,
  errorMessages = [],
  isPristine = true,
  name,
  onChange,
  options,
  radioInputClassName = "",
  value = "",
}) => {
  const shouldHaveGridCol = (window.screen.availWidth > 500)
  return (
    <>
      <div className={clsx(
        className,
        "cursor-pointer radio-button-group-input",
        shouldHaveGridCol && `grid grid-cols-${columnCount}`,
      )}
      >
        {
          options.map((option) => (
            <RadioButton
              key={option}
              name={name}
              className={clsx("p-4", radioInputClassName)}
              disabled={disabled}
              value={option}
              checked={option === value}
              onChange={onChange}
            />
          ))
        }
      </div>
      <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
    </>
  )
}

RadioButtonGroupInput.propTypes = {
  className: types.string,
  columnCount: types.number,
  disabled: types.bool,
  errorMessages: types.arrayOf(types.string),
  isPristine: types.bool,
  name: types.string.isRequired,
  onChange: types.func.isRequired,
  options: types.arrayOf(types.string).isRequired,
  radioInputClassName: types.string,
  value: types.string,
}

export const FormsyRadioButtonGroupInput = withFormsy(({ setValue, ...rest }) => (
  <RadioButtonGroupInput
    onChange={setValue}
    {...rest}
  />
))

export default RadioButtonGroupInput
