import React from "react"
import Popup from "reactjs-popup"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import SearchPanel from "./SearchPanel"

const TasksAndSubmissionsSearch = () => (
  <Popup
    trigger={(
      <div>
        <SvgSpriteIcon
          iconName="search"
          className="mr-4 cursor-pointer"
        />
      </div>
    )}
    position="bottom right"
  >
    <SearchPanel />
  </Popup>
)

export default TasksAndSubmissionsSearch
