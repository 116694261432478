import React from "react"
import types from "prop-types"
import { newAction } from "utils/workflowHelpers"
import FormsyValidation from "shared/FormsyValidation"
import Action from "./Action"
import AddActionButton from "./AddActionButton"

const Actions = ({ actions, formSlug, setWorkflow }) => {
  const updateActions = (newActions) => setWorkflow((wf) => ({ ...wf, actions: newActions }))
  const addAction = () => updateActions([...actions, newAction()])

  const removeAction = (index) => () => {
    const newActions = [...actions]
    newActions.splice(index, 1)
    updateActions(newActions)
  }

  const changeActionType = (index) => (type) => {
    const newActions = [...actions]
    newActions[index] = newAction(type)
    updateActions(newActions)
  }

  const changeActionSubtype = (index) => (subtype) => {
    const newActions = [...actions]

    const updatedAction = {
      ...actions[index],
      subtype,
    }

    newActions.splice(index, 1, updatedAction)
    updateActions(newActions)
  }

  const changeActionData = (index) => (data) => {
    const newActions = [...actions]

    const updatedAction = {
      ...actions[index],
      data,
    }

    newActions.splice(index, 1, updatedAction)
    updateActions(newActions)
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="pb-5">Take the following actions:</div>
      </div>
      {
        actions.map((action, index) => (
          <Action
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            action={action}
            actionIndex={index}
            formSlug={formSlug}
            changeActionData={changeActionData(index)}
            changeActionSubtype={changeActionSubtype(index)}
            changeActionType={changeActionType(index)}
            removeAction={removeAction(index)}
          />
        ))
      }
      <FormsyValidation
        name="actions[]"
        value={actions}
        validations={{
          isNotEmptyArray: {
            errorMessage: "Must select at least one action",
          },
        }}
      />
      <AddActionButton addAction={addAction} className="w-44" />
    </>
  )
}

Actions.defaultProps = {
  actions: [newAction()],
}

Actions.propTypes = {
  actions: types.arrayOf(types.object),
  formSlug: types.string.isRequired,
  setWorkflow: types.func.isRequired,
}

export default Actions
