import ReactOnRails from "react-on-rails";

import Form from "../components/views/Forms/Form";
import FormIndex from "../components/views/Forms/FormIndex";
import FormBuilder from "../components/views/FormBuilder/FormBuilder";
import FormSubmission from "../components/views/FormSubmission";
import FormSubmissionList from "../components/views/FormSubmissionList";
import FormSubmissionReport from "../components/views/FormSubmissionReport";
import FormSubmissionUploads from "../components/views/FormSubmissionUploads";
import NewReportModal from "../components/views/FormSubmissionReport/NewReportModal";
import NewFormSubmissionReportExport from "../components/views/NewFormSubmissionReportExport";
import NewSolutionLevelReportExport from "../components/views/NewSolutionLevelReportExport";
import SurveyBatchShow from "../components/views/Surveys/SurveyBatchShow";
import SurveyBatchIndex from "../components/views/Surveys/SurveyBatchIndex";
import NewSurveyReportExport from "../components/views/NewSurveyReportExport";
import SurveyConfigurationNew from "../components/views/Surveys/SurveyConfigurationNew";
import SurveyConfigurationShow from "../components/views/Surveys/SurveyConfigurationShow";
import SurveyConfigurationIndex from "../components/views/Surveys/SurveyConfigurationIndex";
import TaskList from "../components/views/TaskList";
import TasksAndSubmissionsSearch from "../components/views/TasksAndSubmissionsSearch";
import CategoryList from "../components/shared/CategoryList";
import CategoryPage from "../components/views/Categories/CategoryPage";
import PublicCategory from "../components/views/Categories/PublicCategory";
import ListsCategoryList from "../components/shared/lists/CategoryList";
import PublicCategoryList from "../components/views/Categories/PublicCategoryList";
import ShowDepartment from "../components/views/Departments/ShowDepartment";
import ShowFacility from "../components/views/Facilities/ShowFacility";
import FacilityList from "../components/shared/lists/FacilityList";
import ShowGroup from "../components/views/Groups/ShowGroup";
import GroupList from "../components/shared/lists/GroupList";
import SmartOnFhirFetchData from "../components/views/SmartOnFhir/FetchData";
import SmartOnFhirAuthorization from "../components/views/SmartOnFhir/Authorization";
import GeneralSettings from "../components/views/GeneralSettings";
import Copyright from "../components/shared/Copyright";
import ExportMetabaseDashboard from "../components/shared/ExportMetabaseDashboard";
import SessionIdleTracker from "../components/shared/IdleTrackers/SessionIdleTracker";
import ShowUser from "../components/views/Users/ShowUser";
import UserList from "../components/shared/lists/UserList";
import UserEmailInput from "../components/shared/inputs/UserEmailInput";
import UserPasswordInput from "../components/shared/inputs/UserPasswordInput";
import ResetPasswordForm from "../components/shared/forms/ResetPasswordForm";
import RequiredPasswordResetForm from "../components/shared/forms/RequiredPasswordResetForm";
import FlashMessage from "../components/shared/FlashMessage";
import GlobalHeader from "../components/shared/headers/GlobalHeader";
import NeedHelpButton from "../components/shared/buttons/NeedHelpButton";
import FeatureAnnouncementPreview from "../components/shared/FeatureAnnouncementPanel/FeatureAnnouncementPreview";
import FeatureAnnouncement from "../components/shared/FeatureAnnouncementPanel/FeatureAnnouncement";
import OrganizationAddOnFeatureSubscriptions from "../components/shared/OrganizationAddOnFeatureSubscriptions";
import ManagePhpAdminOrganizationAccess from "../components/shared/ManagePhpAdminOrganizationAccess";
import CopyFormToOrganization from "../components/shared/CopyFormToOrganization";
import SetupAddOnFeatureSubscriptionsInRedux from "../components/shared/SetupAddOnFeatureSubscriptionsInRedux";
import SetupGeneralSettingsInRedux from "../components/shared/SetupGeneralSettingsInRedux";
import SetupFeatureAnnouncementInRedux from "../components/shared/SetupFeatureAnnouncementInRedux";
import SetupFeaturesInRedux from "../components/shared/SetupFeaturesInRedux";
import SetupFacilitiesInRedux from "../components/shared/SetupFacilitiesInRedux";
import FormFillingIdleTracker from "../components/shared/IdleTrackers/FormFillingIdleTracker";
import SetupToastContainer from "../components/shared/toast/SetupToastContainer";
import ActivityHubFooter from "../components/shared/footers/ActivityHubFooter";
import HeadersUsersPanel from "../components/shared/headers/UsersPanel";
import NewSolutionLevelScheduledReportExport from "../components/views/NewSolutionLevelScheduledReportExport";
import NewFormSubmissionScheduledReportExport from "../components/views/NewFormSubmissionScheduledReportExport";
import EditFormSubmissionScheduledReportExport from "../components/views/EditFormSubmissionScheduledReportExport";
import NewSurveyScheduledReportExport from "../components/views/NewSurveyScheduledReportExport";
import EditSolutionLevelScheduledReportExport from "../components/views/EditSolutionLevelScheduledReportExport";
import EditSurveyScheduledReportExport from "../components/views/EditSurveyScheduledReportExport";
import SetupSessionInRedux from "../components/shared/SetupSessionInRedux"
import ReportCreator from "../components/views/ReportCreator"
import ReportEditor from "../components/views/ReportEditor"

ReactOnRails.register({
  EditSurveyScheduledReportExport,
  EditSolutionLevelScheduledReportExport,
  EditFormSubmissionScheduledReportExport,
  NewFormSubmissionReportExport,
  NewReportModal,
  FormSubmissionReport,
  SetupFacilitiesInRedux,
  FormSubmission,
  FormSubmissionList,
  FormBuilder,
  FormIndex,
  Copyright,
  ResetPasswordForm,
  ExportMetabaseDashboard,
  SessionIdleTracker,
  NewSolutionLevelReportExport,
  NewSurveyReportExport,
  SurveyBatchIndex,
  SurveyBatchShow,
  SurveyConfigurationIndex,
  SurveyConfigurationNew,
  SurveyConfigurationShow,
  TaskList,
  GroupList,
  UserList,
  CategoryList,
  ListsCategoryList,
  CategoryPage,
  ShowDepartment,
  FacilityList,
  ShowFacility,
  ShowGroup,
  ShowUser,
  PublicCategoryList,
  PublicCategory,
  FormSubmissionUploads,
  Form,
  SmartOnFhirFetchData,
  SmartOnFhirAuthorization,
  FlashMessage,
  GlobalHeader,
  NeedHelpButton,
  FeatureAnnouncementPreview,
  FeatureAnnouncement,
  OrganizationAddOnFeatureSubscriptions,
  ManagePhpAdminOrganizationAccess,
  CopyFormToOrganization,
  SetupAddOnFeatureSubscriptionsInRedux,
  SetupGeneralSettingsInRedux,
  SetupFeatureAnnouncementInRedux,
  SetupFeaturesInRedux,
  UserEmailInput,
  UserPasswordInput,
  RequiredPasswordResetForm,
  FormFillingIdleTracker,
  SetupToastContainer,
  ActivityHubFooter,
  HeadersUsersPanel,
  TasksAndSubmissionsSearch,
  GeneralSettings,
  NewSolutionLevelScheduledReportExport,
  NewFormSubmissionScheduledReportExport,
  NewSurveyScheduledReportExport,
  SetupSessionInRedux,
  ReportCreator,
  ReportEditor,
});
