import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from "react"
import types from "prop-types"
import clsx from "clsx"
import { useTaskAnswerEditorsContext } from "contexts/TaskAnswerEditorsContext"
import { useOnClickOutside, useOnFocusChange } from "hooks"
import { questionShape } from "utils/propTypeShapes"
import { answerField, requiresTaskAnswerEditingSession } from "Forms/FormElementTypes"
import Autosave from "./Autosave"

const UnimplementedTypeAnswerField = ({ type }) => (
  `I am a ${type} answer field`
)

const SubformQuestionAnswerField = ({
  autosave,
  currentAnswer,
  disabled,
  persistedAnswers,
  question,
  taskId,
}) => {
  const { type, uuid } = question
  const persistedAnswerToQuestion = persistedAnswers[uuid]

  const answerFieldContainerRef = useRef()

  const [focusIsOnTaskQuestion, setFocusIsOnTaskQuestion] = useState(false)

  const {
    answerEditorName,
    anotherUserEditingQuestion,
    beginEditingSession,
    endEditingSession,
  } = useTaskAnswerEditorsContext()

  const taskAnswerEditingSessionRequired = useMemo(() => (
    requiresTaskAnswerEditingSession({ questionType: type })
  ), [type])

  const taskAnswerEditingSessionSetup = useCallback(() => {
    if (taskAnswerEditingSessionRequired) {
      beginEditingSession(uuid)
    }

    setFocusIsOnTaskQuestion(true)
  }, [taskAnswerEditingSessionRequired, beginEditingSession, setFocusIsOnTaskQuestion, uuid])

  const taskAnswerEditingSessionTeardown = useCallback(() => {
    if (taskAnswerEditingSessionRequired) {
      endEditingSession(uuid)
    }
    setFocusIsOnTaskQuestion(false)
  }, [taskAnswerEditingSessionRequired, endEditingSession, setFocusIsOnTaskQuestion, uuid])

  useOnClickOutside(answerFieldContainerRef, taskAnswerEditingSessionTeardown)

  useOnFocusChange(answerFieldContainerRef, { focusInsideCallback: taskAnswerEditingSessionSetup, focusOutsideCallback: taskAnswerEditingSessionTeardown })

  const setUpOnDescendentClick = (event) => {
    if (!answerFieldContainerRef.current || answerFieldContainerRef.current === event.target) return

    if (answerFieldContainerRef.current.contains(event.target)) {
      taskAnswerEditingSessionSetup()
    }
  }

  // Ensure that any editing session ends if the page is refreshed
  useEffect(() => {
    const cleanup = () => taskAnswerEditingSessionRequired && endEditingSession(uuid)

    window.addEventListener("beforeunload", cleanup)

    return () => {
      window.removeEventListener("beforeunload", cleanup)
    }
  }, [endEditingSession, taskAnswerEditingSessionRequired, uuid])

  const TypeSpecificAnswerField = answerField({ questionType: type })

  const disableInput = disabled || anotherUserEditingQuestion(uuid)

  return (
    <div className={clsx("flex flex-col", disableInput && "disabled-task-question")}>
      <div ref={answerFieldContainerRef} onClickCapture={setUpOnDescendentClick}>
        {
          TypeSpecificAnswerField
            ? (
              <TypeSpecificAnswerField
                className={clsx(anotherUserEditingQuestion(uuid) && "mb-4", "type-specific-answer-field")}
                question={question}
                hasBaseInputStyle={false}
                isFullWidth
                placeholder="Add an answer..."
                formAnswers={persistedAnswers}
                initialValue={persistedAnswerToQuestion}
                isTaskForm
                taskId={taskId}
              />
            )
            : <UnimplementedTypeAnswerField type={type} />
        }
      </div>
      {
        autosave && (
          <Autosave
            currentAnswer={currentAnswer}
            disabled={!focusIsOnTaskQuestion}
            persistedAnswer={persistedAnswerToQuestion}
            uuid={uuid}
          />
        )
      }
      {
        anotherUserEditingQuestion(uuid) && (
          <span className="text-sm mb-2">{answerEditorName(uuid)} is currently answering this question</span>
        )
      }
    </div>
  )
}

SubformQuestionAnswerField.defaultProps = {
  currentAnswer: undefined,
  disabled: false,
}

SubformQuestionAnswerField.propTypes = {
  autosave: types.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentAnswer: types.any,
  disabled: types.bool,
  // eslint-disable-next-line react/forbid-prop-types
  persistedAnswers: types.object.isRequired,
  question: questionShape.isRequired,
  taskId: types.number.isRequired,
}

export default SubformQuestionAnswerField
