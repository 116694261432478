/* eslint-disable consistent-return */
import React, { useState, useRef } from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

// This is used for tabs that contains Formsy components. Formsy fields
// needs to be on the DOM, but hidden to work correctly

const isActive = (index, activeIndex) => (index === activeIndex)

const TabContents = ({ activeIndex, tabs }) => (
  tabs.map(({ title, TabContent, props = {} }, index) => (
    <div key={title} className={clsx(!isActive(index, activeIndex) && "hidden")}>
      <TabContent {...props} />
    </div>
  )))

const HiddenTabs = ({ className = "", tabs, initialActiveIndex = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex)
  const tabRef = useRef([])

  const activeTab = tabs[activeIndex]

  return (
    <div>
      <div className={clsx(className, "w-screen bg-primary-light z-20 flex items-center")}>
        <ol className="tabs">
          {
            tabs.map(({ iconName, title }, index) => (
              <li
                key={title}
                ref={(el) => {
                  tabRef.current[index] = el
                }}
                onClickCapture={() => setActiveIndex(index)}
                className={clsx(isActive(index, activeIndex) && "active", "tab-title-token")}
              >
                {
                  (iconName) && (
                    <SvgSpriteIcon iconName={iconName} className="mr-2" />
                  )
                }
                { title }
              </li>
            ))
          }
        </ol>
        {
          activeTab.additionalControls && (
            <div className="absolute right-4 z-30">
              {activeTab.additionalControls}
            </div>
          )
        }
      </div>
      <TabContents tabs={tabs} activeIndex={activeIndex} />
    </div>
  )
}

HiddenTabs.propTypes = {
  className: types.string,
  tabs: types.arrayOf(types.object).isRequired,
  initialActiveIndex: types.number,
}

export default HiddenTabs
