import React from "react"
import { useModal } from "hooks"
import types from "prop-types"
import { FormSubmissionExportContextProvider } from "contexts/FormSubmissionExportContext"
import { CustomIconButton } from "shared/buttons"
import DataExportModal from "../../DataExportModal"

const FormSubmissionExport = ({ exportFormSubmissionData }) => {
  const { modalIsOpen, openModal, closeModal } = useModal()

  return (
    <FormSubmissionExportContextProvider>
      <CustomIconButton
        className="mr-4 mb-3 border border-light text-sm font-semibold"
        icon="export"
        onClick={openModal}
        text="Export"
      />
      <DataExportModal
        exportFormSubmissionData={exportFormSubmissionData}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />
    </FormSubmissionExportContextProvider>
  )
}

FormSubmissionExport.propTypes = {
  exportFormSubmissionData: types.func.isRequired,
}

export default FormSubmissionExport
