import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormIcon,
  getFormIconColor,
  getFormIsSubform,
  getFormSlug,
  setFormIcon,
} from "reduxSlices/formBuilderSlice"
import * as API from "services/api"
import { FormIcon, TaskSubformIcon } from "shared/icons"
import { errorToast } from "shared/toast"
import IconSelector from "./IconSelector"

const FORM_ICON_STYLE = "mb-4 w-14 h-14"

const IconSetting = ({ className }) => {
  const dispatch = useDispatch()
  const formIcon = useSelector(getFormIcon)
  const formIconColor = useSelector(getFormIconColor)
  const formIsSubform = useSelector(getFormIsSubform)
  const formSlug = useSelector(getFormSlug)

  const updateIcon = async ({ icon, iconColor }) => {
    const response = await API.updateFormIcon({ formSlug, icon, iconColor })
    if (response.ok) {
      dispatch(setFormIcon({ icon, iconColor }))
    } else {
      errorToast("Something went wrong.  Unable to update icon.")
    }
  }

  if (formIsSubform) {
    return (
      <TaskSubformIcon className={clsx(FORM_ICON_STYLE, "mx-auto")} />
    )
  }

  return (
    <div className={clsx(className, "flex flex-col items-center")}>
      <FormIcon form={{ icon: formIcon, iconColor: formIconColor }} className={FORM_ICON_STYLE} />
      <p>Select an icon to represent the form on the landing page.</p>
      <IconSelector
        icon={formIcon}
        iconColor={formIconColor}
        updateIcon={updateIcon}
      />
    </div>
  )
}

IconSetting.defaultProps = {
  className: "",
}

IconSetting.propTypes = {
  className: types.string,
}

export default IconSetting
