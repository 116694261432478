import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  formSubmissionSlug as getFormSubmissionSlug,
  setRelatedFormSubmissions,
} from "reduxSlices/formSubmissionSlice"
import * as API from "services/api"
import { RemoveButton } from "components/shared/buttons"
import { errorToast } from "components/shared/toast"
import FormIcon from "components/shared/icons/FormIcon"
import DateDataElement from "components/shared/Table/TableDataElement/DateDataElement"
import { activityHubSubmissionPath, redirectTo } from "utils/routeHelpers"

const RelatedFormSubmission = ({ submission }) => {
  const formSubmissionSlug = useSelector(getFormSubmissionSlug)
  const dispatch = useDispatch()

  const redirectToRelatedFormSubmission = () => redirectTo(activityHubSubmissionPath(submission.slug))

  const deleteFormSubmissionRelationship = async (relatedFormSubmissionId) => {
    const response = await API.deleteFormSubmissionRelationship({
      formSubmissionSlug, relatedFormSubmissionId,
    })

    if (response.ok) {
      dispatch(setRelatedFormSubmissions(response.data))
    } else {
      errorToast(response.data.errors.join(", "))
    }
  }

  return (
    <div
      className="flex items-center"
    >
      <div
        className="cursor-pointer flex-grow list-row mb-6 py-3"
        onClickCapture={redirectToRelatedFormSubmission}
      >
        <p className="w-1/5">{submission.slug}</p>
        <div className="flex items-center w-2/5">
          <FormIcon className="search-result-icon mr-2" form={submission.form} />
          <p>{submission.form.title}</p>
        </div>
        <DateDataElement className="w-1/5" value={submission.createdAt} />
        <p className="text-right w-1/5">{submission.statusName}</p>
      </div>
      <RemoveButton
        className="absolute mb-3 -right-8"
        onClick={() => deleteFormSubmissionRelationship(submission.id)}
      />
    </div>
  )
}

RelatedFormSubmission.propTypes = {
  submission: types.object.isRequired,
}

export default RelatedFormSubmission
