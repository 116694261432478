import React from "react"
import types from "prop-types"
import clsx from "clsx"

const DetailBannerInfo = ({
  name, hideName, className, children,
}) => (
  <div className={clsx("mx-4 my-3", className)}>
    <div className="text-sm font-medium text-dark mb-0.5">
      <span className={clsx(hideName && "invisible")}>{ name }</span>
    </div>
    <div>
      { children }
    </div>
  </div>
)

DetailBannerInfo.defaultProps = {
  className: "",
  hideName: false,
}

DetailBannerInfo.propTypes = {
  className: types.string,
  name: types.string.isRequired,
  hideName: types.bool,
  children: types.node.isRequired,
}

export default DetailBannerInfo
