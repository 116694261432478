import React from "react"
import types from "prop-types"
import clsx from "clsx"
import Popup from "reactjs-popup";
import { ensureDropdownVisible } from "utils/reactSelectHelpers"
import { CustomIconButton } from "shared/buttons"
import { Select } from "shared/selects"
import FacilityDepartmentDropdown from "./FacilityDepartmentDropdown"

const departmentsToOptions = (departments) => departments.map(({ name, id, ...rest }) => (
  {
    label: name,
    value: id,
    ...rest,
  }
))

const TaskDepartmentSelector = ({ className, departments, onChange }) => (
  <div className={clsx("flex", className)}>
    <Popup
      trigger={
        (
          <div>
            <CustomIconButton
              className="border border-light text-sm"
              icon="add-department"
              text="Add Departments"
            />
          </div>
        )
      }
      position="bottom right"
    >
      <div className="h-0 invisible">
        <div className="w-96">
          <Select
            backspaceDelete={false}
            className="react-dropdown-select base-input component-border-thin rounded-md -top-11"
            dropdownGap={0}
            dropdownRenderer={FacilityDepartmentDropdown}
            keepOpen
            labelField="label"
            multi={false}
            onChange={onChange}
            onDropdownOpen={ensureDropdownVisible}
            options={departmentsToOptions(departments)}
            placeholder="Add departments by name ..."
            searchable
            style={{ width: "24rem" }}
          />
        </div>
      </div>
    </Popup>
  </div>
)

TaskDepartmentSelector.defaultProps = {
  className: "",
}

TaskDepartmentSelector.propTypes = {
  className: types.string,
  departments: types.arrayOf(types.shape({
    id: types.number.isRequired,
    name: types.string.isRequired,
  })).isRequired,
  onChange: types.func.isRequired,
}

export default TaskDepartmentSelector
