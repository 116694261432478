import React from "react"
import types from "prop-types"
import clsx from "clsx"

const TaskDueDate = ({ dueDate, isOverdue }) => (
  <span className={clsx(
    isOverdue && "overdue",
    "mr-4 text-token px-3 text-dark font-semibold",
  )}
  >
    { dueDate }
  </span>
)

TaskDueDate.propTypes = {
  dueDate: types.string.isRequired,
  isOverdue: types.bool.isRequired,
}

export default TaskDueDate
