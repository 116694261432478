import React from "react"
import { MediumWrap } from "shared/wraps"
import RelatedFormSubmissions from "./RelatedFormSubmissions"
import SurveyDetails from "./SurveyDetails"

const SupplementalContent = () => (
  <MediumWrap className="mt-20 relative">
    <div className="text-center">
      <div className="py-6 header-title">Supplemental</div>
    </div>
    <SurveyDetails className="mb-16" />
    <RelatedFormSubmissions />
  </MediumWrap>
)

export default SupplementalContent
