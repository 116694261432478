import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { formSubmissionData, updateFormSubmissionAnswers } from "reduxSlices/formSubmissionSlice"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import * as API from "services/api"
import { errorToast, successToast } from "components/shared/toast"
import SubmissionEditForm from "./SubmissionEditForm"

const SubmissionEdit = ({ onCancel, onSuccess }) => {
  const dispatch = useDispatch()

  const { answers, slug } = useSelector(formSubmissionData)

  const { currentFormVersion, setCurrentFormVersionAsSubmittedVersion } = useFormSubmissionFormContext()
  const { id: currentFormVersionId, sections: currentFormVersionSections } = currentFormVersion

  const updateSubmission = async (data) => {
    const response = await API.updateFormSubmissionAnswers({
      answers: data,
      formVersionId: currentFormVersionId,
      slug,
    })

    if (response.ok) {
      successToast("Form submission successfully updated.")

      // Send the new answers, so that they can be compared
      // to the old answers.
      onSuccess({ answers: data })

      // Set the new answers in redux
      dispatch(updateFormSubmissionAnswers(response.data))

      // Set the current version of the form as the submitted version of the form
      setCurrentFormVersionAsSubmittedVersion()

      onCancel()
    } else {
      errorToast("Something went wrong. Unable to update form submission.", response)
    }
  }

  return (
    <div className="px-10 pt-12 pb-6">
      <SubmissionEditForm
        answers={answers}
        sections={currentFormVersionSections}
        onCancel={onCancel}
        onSubmit={updateSubmission}
      />
    </div>
  )
}

SubmissionEdit.propTypes = {
  onCancel: types.func.isRequired,
  onSuccess: types.func.isRequired,
}

export default SubmissionEdit
