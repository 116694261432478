import React from "react"
import clsx from "clsx"
import Copyright from "shared/Copyright"

const ActivityHubFooter = () => (
  <div className={clsx(
    "absolute bottom-0 right-0 left-0",
    "pb-8 text-sm text-center text-dark -z-10",
  )}
  >
    <Copyright />
  </div>
)

export default ActivityHubFooter
