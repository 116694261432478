import React from "react"
import { taskShape } from "utils/propTypeShapes"
import TaskGeneralResponse from "./TaskGeneralResponse"
import TaskSubform from "./TaskSubform"

const TaskResponse = ({ task }) => {
  const { subform } = task

  if (subform) {
    return (
      <TaskSubform task={task} />
    )
  }

  return (
    <TaskGeneralResponse task={task} />
  )
}

TaskResponse.propTypes = {
  task: taskShape.isRequired,
}

export default TaskResponse
