import React from "react"
import types from "prop-types"
import { RemoveButton } from "shared/buttons"
import { Accordion, useAccordion } from "shared/accordions"
import GroupSearchGroupMemberList from "./GroupSearchGroupMemberList"

const SearchSelectedGroup = ({
  children = null,
  group,
  deselect,
  showDeselectButton = true,
}) => {
  const [isExpanded, toggleAccordion] = useAccordion()

  return (
    <Accordion
      className="mb-2"
      isExpanded={isExpanded}
      headerClassName="relative"
      headerColor="bg-default"
      toggleAccordion={toggleAccordion}
      header={(
        <div className="w-full mr-3 flex-center">
          <span className="font-bold">
            { group.name }
          </span>
          { children }
          {
            showDeselectButton && (
              <RemoveButton
                className="absolute -right-10"
                onClick={deselect}
              />
            )
          }
        </div>
      )}
      content={<GroupSearchGroupMemberList members={group.members} />}
    />
  )
}

SearchSelectedGroup.propTypes = {
  children: types.node,
  showDeselectButton: types.bool,
  deselect: types.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
}

export default SearchSelectedGroup
