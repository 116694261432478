import React from "react"
import types from "prop-types"
import { SurveyBatchContextProvider } from "contexts/SurveyBatchContext"
import { MediumWrap } from "shared/wraps"
import SurveyBatchHeader from "./SurveyBatchHeader"

const SurveyBatchShow = ({ surveyBatch, configRoot = false }) => (
  <SurveyBatchContextProvider surveyBatch={surveyBatch}>
    <MediumWrap>
      <SurveyBatchHeader className="mb-4" configRoot={configRoot} />
    </MediumWrap>
  </SurveyBatchContextProvider>
)

SurveyBatchShow.propTypes = {
  surveyBatch: types.object.isRequired,
  configRoot: types.bool,
}

export default SurveyBatchShow
