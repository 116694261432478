import React from "react"
import { IconButton } from "shared/buttons"

const TaskLockedIcon = () => (
  <IconButton
    className="ml-3 rounded-full"
    background="default"
    icon="fas fa-lock"
    onClick={null}
    disabled
  />

)

export default TaskLockedIcon
