import React from "react"
import types from "prop-types"
import { useRemoveQueryStringFromLocation } from "hooks"
import PublicFooter from "shared/footers/PublicFooter"
import CategoryList from "./CategoryList"

const PublicCategoryList = ({ categories }) => {
  useRemoveQueryStringFromLocation()

  return (
    <div className="mb-20 bg-primary-light">
      <div className="text-lg text-center opacity-60">Welcome!</div>
      <div className="pb-6 text-2xl sm:text-4xl mx-6 font-medium text-center">
        What would you like to do today?
      </div>
      <CategoryList categories={categories} />
      <PublicFooter />
    </div>
  )
}

PublicCategoryList.propTypes = {
  categories: types.arrayOf(types.object).isRequired,
}

export default PublicCategoryList
