import React from "react"
import types from "prop-types"
import { RemoveButton } from "shared/buttons"
import Switch from "shared/Switch"

const TaskDepartment = ({
  departmentSelection,
  removeFromTask,
  toggleNotify,
  toggleNotifyOnComplete,
  labelComponent: DepartmentLabel,
  segmentsComponent: DepartmentSegments,
}) => (
  <>
    <div className="flex mb-2">
      <div className="flex flex-col flex-grow items-center bg-primary-light rounded-md p-2 mr-2">
        <div className="flex w-full mb-4">
          <DepartmentLabel departmentOption={departmentSelection} />
          <div className="flex gap-x-2">
            <div className="flex justify-center items-center w-20">
              <Switch
                checked={departmentSelection.notify}
                onChange={toggleNotify}
              />
            </div>
            <div className="flex justify-center items-center w-20">
              <Switch
                checked={departmentSelection.notifyOnComplete}
                onChange={toggleNotifyOnComplete}
              />
            </div>
          </div>
        </div>
        <DepartmentSegments department={departmentSelection} />
      </div>
      <RemoveButton className="self-center justify-self-end" onClick={removeFromTask} />
    </div>
  </>
)

TaskDepartment.propTypes = {
  removeFromTask: types.func.isRequired,
  toggleNotify: types.func.isRequired,
  toggleNotifyOnComplete: types.func.isRequired,
  departmentSelection: types.object.isRequired,
  labelComponent: types.elementType.isRequired,
  segmentsComponent: types.elementType.isRequired,
}

export default TaskDepartment
