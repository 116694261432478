import React, { useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import { addOnFeatureDisplayName } from "utils/addOnFeatureHelpers"
import { addOnFeatureSubscriptionsOverviewShape } from "utils/propTypeShapes"
import { errorToast, successToast } from "shared/toast"
import OrganizationAddOnFeatureCheckbox from "./OrganizationAddOnFeatureCheckbox"

const OrganizationAddOnFeatureSubscriptions = ({
  organizationSubdomain,
  subscriptionsOverview: initialSubscriptionOverview,
}) => {
  // This component is currently rendered directly from the Rails view,
  // passing the organization's add-on feature subscriptions overview as a prop.
  // Coming from the view, that prop will not change.  We want the data to change
  // as the organization's subscriptions change, so we store the data in state
  // that we can update.  The state will get out of sync with the prop value.
  const [subscriptionsOverview, setSubscriptionOverview] = useState(initialSubscriptionOverview)

  const updateSubscriptionOverview = (name, subscribed) => {
    const updatedSubscriptions = {
      ...subscriptionsOverview,
      [name]: subscribed,
    }

    setSubscriptionOverview(updatedSubscriptions)
  }

  const subscribe = (name) => async () => {
    const response = await API.subscribeOrganizationToAddOnFeature({
      organizationSubdomain,
      addOnFeatureName: name,
    })

    if (response.ok) {
      updateSubscriptionOverview(name, true)
      successToast(`Organization successfully subscribed to ${addOnFeatureDisplayName(name)}.`)
    } else {
      errorToast(`Something went wrong.  Unable to subscribe organization to ${addOnFeatureDisplayName(name)}.`)
    }
  }

  const unsubscribe = (name) => async () => {
    const response = await API.unsubscribeOrganizationFromAddOnFeature({
      organizationSubdomain,
      addOnFeatureName: name,
    })

    if (response.ok) {
      updateSubscriptionOverview(name, false)
      successToast(`Organization successfully unsubscribed from ${addOnFeatureDisplayName(name)}.`)
    } else {
      errorToast(`Something went wrong.  Unable to unsubscribe organization from ${addOnFeatureDisplayName(name)}.`)
    }
  }

  const toggleSubscription = (name) => (
    subscriptionsOverview[name] ? unsubscribe(name) : subscribe(name)
  )

  return (
    <div>
      {
        Object.keys(subscriptionsOverview).map((name) => (
          <OrganizationAddOnFeatureCheckbox
            key={name}
            addOnFeatureName={name}
            subscribed={subscriptionsOverview[name]}
            onChange={toggleSubscription(name)}
          />
        ))
      }
    </div>
  )
}

OrganizationAddOnFeatureSubscriptions.propTypes = {
  organizationSubdomain: types.string.isRequired,
  subscriptionsOverview: addOnFeatureSubscriptionsOverviewShape.isRequired,
}

export default OrganizationAddOnFeatureSubscriptions
