import { createSlice } from "@reduxjs/toolkit"
import {
  GROUP_BY_DATE_CREATED, getGroupingMethod,
} from "FormSubmission/FormSubmissionTabs/AttachmentsContent/attachmentGroupingHelper"

const INITIAL_STATE = {
  attachments: [],
  selectedGrouping: GROUP_BY_DATE_CREATED,
  fileTypesToFilterBy: [],
  isUploading: false,
}

export const formSubmissionAttachmentsGroupingSlice = createSlice({
  name: "formSubmissionAttachmentsGrouping",
  initialState: INITIAL_STATE,
  reducers: {
    setAttachments: (state, action) => {
      state.attachments = action.payload
    },
    setSelectedGrouping: (state, action) => {
      state.selectedGrouping = action.payload
    },
    setFileTypesToFilterBy: (state, action) => {
      state.fileTypesToFilterBy = action.payload
    },
    setUploadingStart: (state) => {
      state.isUploading = true
    },
    setUploadingComplete: (state) => {
      state.isUploading = false
    },
  },
})

export const {
  setAttachments,
  setSelectedGrouping,
  setFormActiveStatus,
  setFormBuilderPermissions,
  setFileTypesToFilterBy,
  setUploadingStart,
  setUploadingComplete,
} = formSubmissionAttachmentsGroupingSlice.actions

export const getAttachmentGrouping = (state) => state.formSubmissionAttachmentsGrouping

export const getFilteredGroupedAttachments = ({ formSubmissionAttachmentsGrouping }) => {
  const {
    selectedGrouping,
    attachments,
    fileTypesToFilterBy,
  } = formSubmissionAttachmentsGrouping

  const filteredAttachments = attachments.filter((attachment) => fileTypesToFilterBy.includes(attachment.fileType))

  const groupingMethod = getGroupingMethod(selectedGrouping)

  return groupingMethod(filteredAttachments, selectedGrouping)
}

export const getIsUploading = (state) => state.formSubmissionAttachmentsGrouping.isUploading

export default formSubmissionAttachmentsGroupingSlice.reducer
