import React, { useState, useEffect, useRef } from "react"
import types from "prop-types"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionPrompt, getFormQuestionPromptCount, getFormQuestionRequired, setFormQuestionPrompt,
} from "reduxSlices/formBuilderSlice"
import FormsyValidation from "shared/FormsyValidation"
import { TextInput } from "shared/inputs"

const QuestionPrompt = ({ sectionIndex, questionIndex, textBoxId }) => {
  const dispatch = useDispatch()

  const value = useSelector(getFormQuestionPrompt({ sectionIndex, questionIndex }))
  const promptCount = useSelector(getFormQuestionPromptCount({ sectionIndex, questionIndex }))
  const questionRequired = useSelector(getFormQuestionRequired({ sectionIndex, questionIndex }))

  const updateQuestionPrompt = (newPrompt) => dispatch(setFormQuestionPrompt({ sectionIndex, questionIndex, newPrompt }))

  const inputRef = useRef(null)
  const [requiredAsteriskPosition, setRequiredAsteriskPosition] = useState(0)

  // Adds a red floating asterisk at the end of the text input
  useEffect(() => {
    (async () => {
      await document.fonts.ready

      // Create a div element
      const fakeEl = document.createElement("div")
      const inputEl = inputRef.current

      if (inputEl && questionRequired) {
        fakeEl.style.position = "absolute"
        fakeEl.style.left = "-9999px"

        const styles = window.getComputedStyle(inputEl)
        // Copy font styles
        fakeEl.style.fontFamily = styles.fontFamily
        fakeEl.style.fontSize = styles.fontSize
        fakeEl.style.letterSpacing = styles.letterSpacing
        fakeEl.style.paddingLeft = styles.paddingLeft
        // Append the fake element to body
        document.body.appendChild(fakeEl)

        const getWidth = (newValue) => {
          fakeEl.innerHTML = newValue
          return fakeEl.clientWidth + 5
        }
        const promptWidth = getWidth(value)

        const position = () => {
          if (!textBoxId) return promptWidth

          const maxWidth = document.getElementById(textBoxId)?.clientWidth - 80
          return (promptWidth < maxWidth) ? promptWidth : maxWidth
        }

        setRequiredAsteriskPosition(position())
      }
    })()
  }, [questionRequired, value, textBoxId])

  return (
    <>
      <div className="relative text-xl">
        <TextInput
          className="!text-xl px-0"
          required
          ref={inputRef}
          placeholder="Prompt"
          value={value}
          onChange={updateQuestionPrompt}
        />
        {
          (!!requiredAsteriskPosition && questionRequired) && (
            <span
              className={clsx(questionRequired && "required pl-1", !value && "hidden")}
              style={{ left: `${requiredAsteriskPosition}px` }}
            />
          )
        }
      </div>
      <FormsyValidation
        name={`sections[${sectionIndex}].questions[${questionIndex}].prompt`}
        value={value}
        validations={{
          isNotBlankString: true,
          isUniqueFormQuestionPrompt: { count: promptCount },
        }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
    </>
  )
}

QuestionPrompt.defaultProps = {
  textBoxId: "",
}

QuestionPrompt.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
  textBoxId: types.string,
}

export default QuestionPrompt
