import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { taskShape } from "utils/propTypeShapes"
import { EditTaskForm } from "FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms"
import { CheckmarkCircleIcon } from "shared/icons"
import * as API from "services/api"
import {
  toggleTaskExpanded,
  submissionPermissions as getSubmissionPermissions,
} from "reduxSlices/formSubmissionSlice"
import TaskHeader from "./TaskHeader"
import TaskPanel from "./TaskPanel"
import TaskMenu from "./TaskMenu"
import RecoverTaskMenu from "./RecoverTaskMenu"

const Task = ({ task }) => {
  const dispatch = useDispatch()

  // Unfurl and furl the task
  const toggleAccordion = () => {
    dispatch(toggleTaskExpanded(task.id))
  }

  // When the task is unfurled, create an audit trail entry
  // to record that the user has viewed the task
  const { id, expanded } = task
  useEffect(() => {
    if (expanded) API.viewTask({ taskId: id })
  }, [id, expanded])

  const [taskFormIsOpen, setIsOpen] = useState(false)
  const openForm = () => setIsOpen(true)
  const closeForm = () => setIsOpen(false)

  const { canDelete: canDeleteTask } = task.userTaskPermissions
  const { canAssignTaskWithSubform, canAssignTask } = useSelector(getSubmissionPermissions)

  const canDeleteAndRecoverTask = (
    canDeleteTask
    && (task.subform ? canAssignTaskWithSubform : canAssignTask)
  )

  if (taskFormIsOpen) {
    return (
      <EditTaskForm
        onCancel={closeForm}
        onSave={closeForm}
        task={task}
        taskFormIsOpen={taskFormIsOpen}
      />
    )
  }

  return (
    <>
      <div className="grid mb-4" style={{ gridTemplateColumns: "30px 100% 5%" }}>
        {
          !task.isOpen ? (
            <CheckmarkCircleIcon className="w-5 h-5 self-start mt-5" />
          ) : (<div />)
        }
        <div id={`task-${task.id}`} className="light-shadow rounded-lg border border-lightgray-2 bg-white">
          <TaskHeader
            task={task}
            expanded={task.expanded}
            toggleAccordion={toggleAccordion}
          />
          <TaskPanel task={task} expanded={task.expanded} />
        </div>
        {
          task.isDeleted ? (
            <RecoverTaskMenu
              task={task}
              taskIsRecoverable={canDeleteAndRecoverTask}
            />
          ) : (
            <TaskMenu
              task={task}
              openForm={openForm}
              taskIsDeletable={canDeleteAndRecoverTask}
            />
          )
        }
      </div>
    </>
  )
}

Task.propTypes = {
  task: taskShape.isRequired,
}

export default Task
