import React from "react"
import { useSelector } from "react-redux"
import { category, submissionPermissions } from "reduxSlices/formSubmissionSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { GENERAL_TASK_OPTION } from "utils/taskHelpers"
import { FormsyHiddenInput } from "shared/inputs"
import { Select } from "shared/selects"
import SubformDropdown from "shared/selects/SubformDropdown"

const subformsToOptions = (subforms) => subforms.map(({ id, title }) => (
  { label: title, value: id }
))

const subformOptions = (subforms) => (
  [
    { ...GENERAL_TASK_OPTION },
    ...subformsToOptions(subforms),
  ]
)

const TaskSubformSelectInput = () => {
  const { taskSubformId, updateTaskSubformId } = useTaskFormContext()

  const { canAssignTaskWithSubform } = useSelector(submissionPermissions)

  const { subforms } = useSelector(category)

  if (!canAssignTaskWithSubform) return null

  return (
    <div className="w-max">
      <span className="block mb-1 font-semibold required-prompt">Task Form</span>
      <Select
        backspaceDelete={false}
        dropdownRenderer={SubformDropdown}
        onChange={updateTaskSubformId}
        options={subformOptions(subforms)}
        value={taskSubformId}
      />
      <FormsyHiddenInput
        name="task.subform_id"
        required
        showErrors
        validationErrors={{ isDefaultRequiredValue: "Selection required" }}
        value={taskSubformId}
      />
    </div>
  )
}

export default TaskSubformSelectInput
