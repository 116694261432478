import React, { Component } from "react"
import types from "prop-types"
import { isEmpty } from "lodash-es"
import Formsy from "formsy-react"
import Modal from "shared/Modal"
import PencilIcon from "shared/icons/PencilIcon"
import { XIcon } from "shared/icons"
import { PrimaryButton, Button } from "shared/buttons"
import * as API from "services/api"
import { successToast, errorToast } from "shared/toast"
import { connect } from "react-redux"
import { SCIM_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import { adminFacilityDepartmentShape } from "utils/propTypeShapes"
import ExternalIdentifier from "./Department/Fields/ExternalIdentifier"
import Name from "./Department/Fields/Name"

class EditDepartmentModal extends Component {
  state = { isFormValid: false, modalIsOpen: false }

  closeModal = () => this.setState({ modalIsOpen: false })

  openModal = () => this.setState({ modalIsOpen: true })

  enableSubmit = () => this.setState({ isFormValid: true })

  disableSubmit = () => this.setState({ isFormValid: false })

  onSubmit = async (model) => {
    const { onDepartmentUpdated, facilityId, department } = this.props
    const { id: departmentId } = department
    const response = await API.updateFacilityDepartment({
      department: model,
      departmentId,
      facilityId,
    })
    if (response.ok) {
      const { name, externalIdentifier } = response.data

      onDepartmentUpdated({ departmentId, name, externalIdentifier })
      this.closeModal()
      successToast("Department updated successfully!")
    } else {
      console.error("Error updating department: ", response)
      errorToast("Something went wrong. Unable to update department.", response)
    }
  }

  render() {
    const { isFormValid, modalIsOpen } = this.state
    const { facilityId, department, showExternalId } = this.props

    if (!facilityId || isEmpty(department)) return null

    return (
      <>
        <div className="flex p-1">
          <button onClick={this.openModal}>
            <PencilIcon className="w-4 h-4 cursor-pointer" />
          </button>
        </div>
        <Modal isOpen={modalIsOpen}>
          <div className="text-center text-xl font-semibold mb-8 relative">
            Edit Department
            <XIcon
              className="absolute -top-4 right-4 rounded-full bg-primary-light p-2 h-8 w-8"
              onClick={this.closeModal}
            />
          </div>
          <Formsy
            onSubmit={this.onSubmit}
            onValid={this.enableSubmit}
            onInvalid={this.disableSubmit}
          >
            <div className="w-3/4 mx-auto grid gap-3">
              <Name department={department} />
              {
                showExternalId && <ExternalIdentifier department={department} />
              }
              <div className="flex justify-center mt-8">
                <Button
                  text="Cancel"
                  onClick={this.closeModal}
                  className="border border-light-300 mr-2"
                />
                <PrimaryButton
                  text="Update"
                  type="submit"
                  disabled={!isFormValid}
                />
              </div>
            </div>
          </Formsy>
        </Modal>
      </>
    )
  }
}

EditDepartmentModal.defaultProps = {
  facilityId: undefined,
  showExternalId: false,
}

EditDepartmentModal.propTypes = {
  facilityId: types.number,
  department: adminFacilityDepartmentShape.isRequired,
  onDepartmentUpdated: types.func.isRequired,
  showExternalId: types.bool,
}

const mapStateToProps = (state) => (
  {
    showExternalId: state.addOnFeatureSubscriptions[SCIM_FEATURE_NAME],
  }
)

export default connect(mapStateToProps)(EditDepartmentModal)
