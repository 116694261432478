import React from "react"
import types from "prop-types"
import { questionShape } from "utils/propTypeShapes"
import FormattedNoteAnswer from "./FormattedNoteAnswer"

const NoteAnswer = ({ question, isNewQuestion }) => {
  if (isNewQuestion) return null

  const { attributes: { note } } = question

  return (
    <span className="font-medium text-lg bg-lightgray py-1.5 px-3 w-full">
      <FormattedNoteAnswer note={note} />
    </span>
  )
}

NoteAnswer.defaultProps = {
  isNewQuestion: false,
}

NoteAnswer.propTypes = {
  isNewQuestion: types.bool,
  question: questionShape.isRequired,
}

export default NoteAnswer
