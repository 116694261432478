import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { TaskAnswerEditorsContextProvider } from "contexts/TaskAnswerEditorsContext"
import { taskShape } from "utils/propTypeShapes"
import TaskPanelAssignees from "./TaskPanelAssignees"
import TaskPanelMembers from "./TaskPanelMembers"
import TaskPanelNotes from "./TaskPanelNotes"
import TaskStatusControl from "./TaskStatusControl"
import TaskResponse from "./TaskResponse"

const TaskPanel = ({ expanded, task }) => {
  if (!expanded) return null

  const { description, id } = task

  return (
    <TaskAnswerEditorsContextProvider taskId={id}>
      <div className="flex flex-col items-center justify-center px-6 rounded-b-lg bg-white">
        <div className="w-full mb-12">
          <TaskPanelAssignees className="mb-4" task={task} />
          <TaskPanelMembers className={clsx(description && "mb-4")} task={task} />
          <TaskPanelNotes task={task} />
        </div>
        <TaskResponse task={task} />
        <TaskStatusControl task={task} />
      </div>
    </TaskAnswerEditorsContextProvider>
  )
}

TaskPanel.defaultProps = {
  expanded: false,
}

TaskPanel.propTypes = {
  expanded: types.bool,
  task: taskShape.isRequired,
}

export default TaskPanel
