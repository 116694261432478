import React, { useState } from "react"
import types from "prop-types"
import { Provider } from "react-redux"
import { store } from "store"
import { MediumWrap } from "shared/wraps"
import { categoryShape } from "utils/propTypeShapes"
import { ADMIN_SOLUTIONS_PATH } from "utils/routeHelpers"
import CategoryTabs from "./CategoryTabs"

const CategoryForm = ({ category: categoryProp, accessLevelOptions }) => {
  const [category, setCategory] = useState(categoryProp)

  const tabProps = {
    category,
    setCategory,
    accessLevelOptions,
  }

  return (
    <Provider store={store}>
      <MediumWrap>
        <div className="admin-header">
          <a className="header-link" href={ADMIN_SOLUTIONS_PATH}>
            Solutions
          </a>
          <div className="header-title">{ category.name }</div>
        </div>
        <CategoryTabs tabProps={tabProps} />
      </MediumWrap>
    </Provider>
  )
}

CategoryForm.propTypes = {
  category: categoryShape.isRequired,
  accessLevelOptions: types.arrayOf(types.string).isRequired,
  // redirect: types.string,
}

export default CategoryForm
