import React from "react"
import types from "prop-types"
import { IconSelect } from "shared/selects"
import { snakeToCapitalCase } from "utils/stringHelpers"
import FormsyValidation from "shared/FormsyValidation"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import { TASK_FORM_TRIGGERS } from "utils/workflowHelpers"

const renderContentIcon = ({ icon }) => <SvgSpriteIcon iconName={icon} />
const itemRenderer = ({ item, methods }) => (
  <div
    className="flex items-center py-2.5"
    onClickCapture={() => methods.addItem(item)}
  >
    <SvgSpriteIcon iconName={item.icon} className="mx-2" />
    <span className="text-sm font-medium">{ item.label }</span>
  </div>
)

const options = TASK_FORM_TRIGGERS.map((trigger) => ({
  value: trigger,
  label: snakeToCapitalCase(trigger),
  icon: "workflow-trigger-task-closed",
}))

const TaskFormTrigger = ({ trigger, setWorkflow }) => (
  <>
    <IconSelect
      placeholder="Select trigger... *"
      required
      value={trigger}
      style={{ height: "32px", width: "max-content" }}
      options={options}
      renderContentIcon={renderContentIcon}
      itemRenderer={itemRenderer}
      onChange={(value) => setWorkflow((wf) => ({ ...wf, trigger: value }))}
    />
    <FormsyValidation
      name="trigger"
      value={trigger}
      validations={{ isNotBlankString: true }}
      validationErrors={{ isDefaultRequiredValue: "required" }}
    />
  </>
)

TaskFormTrigger.defaultProps = {
  trigger: "",
}

TaskFormTrigger.propTypes = {
  trigger: types.string,
  setWorkflow: types.func.isRequired,
}

export default TaskFormTrigger
