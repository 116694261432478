import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { Wrap } from "shared/wraps"
import { useSelector } from "react-redux"
import { getIsUploading, getFilteredGroupedAttachments } from "reduxSlices/formSubmissionAttachmentsGroupingSlice"
import Modal from "shared/Modal"
import EmptyAttachments from "./EmptyAttachments"
import AttachmentsHeader from "./AttachmentsHeader"
import AttachmentGroupingList from "./AttachmentGroupingList"
import AttachmentUploadInstructions from "./AttachmentUploadInstructions"
import AttachmentDropzone from "./AttachmentDropzone"
import UploadingFormSubmissionAttachments from "./UploadingFormSubmissionAttachments"

const AttachmentsList = ({
  attachments,
  hiddenSidebar,
  toggleSidebar,
}) => {
  const attachmentGroups = useSelector(getFilteredGroupedAttachments)

  const isUploading = useSelector(getIsUploading)

  return (
    <>
      { attachments.length === 0
        ? (
          <AttachmentDropzone>
            <EmptyAttachments />
          </AttachmentDropzone>
        )
        : (
          <Wrap className={clsx("relative", !hiddenSidebar && "pl-96")}>
            <AttachmentsHeader
              hiddenSidebar={hiddenSidebar}
              toggleSidebar={toggleSidebar}
            />
            <AttachmentDropzone>
              <AttachmentGroupingList attachmentGroups={attachmentGroups} />
              <AttachmentUploadInstructions />
            </AttachmentDropzone>
          </Wrap>
        )}
      <Modal isOpen={isUploading}>
        <UploadingFormSubmissionAttachments />
      </Modal>
    </>
  )
}

AttachmentsList.propTypes = {
  attachments: types.arrayOf(types.object).isRequired,
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default AttachmentsList
