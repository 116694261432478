import React from "react"
import types from "prop-types"
import { PrimaryButton } from "shared/buttons"

const ReopenTaskButton = ({ reopenTask }) => (
  <PrimaryButton
    text="Reopen"
    className="my-6"
    onClick={reopenTask}
  />
)

ReopenTaskButton.propTypes = {
  reopenTask: types.func.isRequired,
}

export default ReopenTaskButton
