import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormQuestionTypeByUuid } from "reduxSlices/formBuilderSlice"
import { conditionOperators } from "Forms/FormElementTypes"
import { ListSelect } from "shared/selects"

const ConditionOperatorField = ({
  conditionQuestionUuid,
  setConditionOperator,
  value,
}) => {
  const conditionQuestionType = useSelector(getFormQuestionTypeByUuid(conditionQuestionUuid))

  if (!conditionQuestionType) return null

  const options = conditionOperators({ questionType: conditionQuestionType })

  return (
    <ListSelect
      className="text-sm mb-2"
      style={{ height: "32px", minWidth: "135px" }}
      options={options}
      value={value}
      onChange={setConditionOperator}
      backspaceDelete={false}
    />
  )
}

ConditionOperatorField.defaultProps = {
  value: null,
}

ConditionOperatorField.propTypes = {
  conditionQuestionUuid: types.string.isRequired,
  setConditionOperator: types.func.isRequired,
  value: types.string,
}

export default ConditionOperatorField
