import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { PencilIcon } from "shared/icons"
import Tooltip from "shared/Tooltip"
import {
  formSubmissionData,
} from "reduxSlices/formSubmissionSlice"

const DEFAULT_STYLES = "rounded-full h-8 w-8 flex justify-center items-center"
const OPEN_STYLES = "bg-light-300 cursor-pointer"
const CLOSED_STYLES = "bg-light-200 cursor-not-allowed"
const TOOLTIP_TEXT = "Submission must be reopened before editing"

const EditSubmissionButton = ({ className, onClick }) => {
  const { isClosed } = useSelector(formSubmissionData)

  return (
    <button className={clsx(className, DEFAULT_STYLES, isClosed ? CLOSED_STYLES : OPEN_STYLES)} onClick={onClick} disabled={isClosed}>
      <Tooltip text={isClosed ? TOOLTIP_TEXT : ""}>
        <PencilIcon className="h-5 w-5" />
      </Tooltip>
    </button>
  )
}

EditSubmissionButton.defaultProps = {
  className: "",
}

EditSubmissionButton.propTypes = {
  className: types.string,
  onClick: types.func.isRequired,
}

export default EditSubmissionButton
