import React from "react"
import types from "prop-types"
import {
  EXCEL_FORMULA_METACHARACTER_START_OF_STRING_MATCHER,
  EXCEL_FORMULA_METACHARACTER_POST_DELIMITER_MATCHER,
  EXCEL_FORMULA_METACHARACTER_START_OF_STRING_ERROR_MESSAGE,
  EXCEL_FORMULA_METACHARACTER_POST_DELIMITER_ERROR_MESSAGE,
} from "utils/customFormsyValidations"
import FormsyValidation from "shared/FormsyValidation"

const NoOptionsWithExcelMetacharactersValidation = ({ value, sectionIndex, questionIndex }) => (
  <FormsyValidation
    name={`sections[${sectionIndex}].questions[${questionIndex}].noOptionsWithExcelMetacharactersValidation`}
    value={value}
    validations={{
      noOptionsWithExcelMetacharactersValidation(_model, val) {
        let errorMessage

        for (let i = 0; i < val.length; i += 1) {
          if (val[i]) {
            if (EXCEL_FORMULA_METACHARACTER_START_OF_STRING_MATCHER.test(val[i])) {
              errorMessage = EXCEL_FORMULA_METACHARACTER_START_OF_STRING_ERROR_MESSAGE
            } else if (EXCEL_FORMULA_METACHARACTER_POST_DELIMITER_MATCHER.test(val[i])) {
              errorMessage = EXCEL_FORMULA_METACHARACTER_POST_DELIMITER_ERROR_MESSAGE
            }

            if (errorMessage) break
          }
        }

        return errorMessage || true
      },
    }}
  />
)

NoOptionsWithExcelMetacharactersValidation.defaultProps = {
  value: [],
}

NoOptionsWithExcelMetacharactersValidation.propTypes = {
  value: types.arrayOf(types.string),
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default NoOptionsWithExcelMetacharactersValidation
