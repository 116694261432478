import React from "react"
import types from "prop-types"
import { categoryShape } from "utils/propTypeShapes"
import { SearchSelectedGroup } from "shared/searches"
import AccessLevelIdentifier from "./AccessLevelIdentifier"

const GroupGranteeList = ({
  category, groups, updateAccessGranteeLists, deleteAccessLevelGrant,
}) => {
  if (!groups.length) {
    return <div className="m-5">No Group with Grants</div>
  }

  return (
    groups.map(({ id, grantee, accessLevel }) => (
      <SearchSelectedGroup
        key={grantee.id}
        group={grantee}
        deselect={deleteAccessLevelGrant(id)}
      >
        <AccessLevelIdentifier
          grantId={id}
          category={category}
          updateAccessGranteeLists={updateAccessGranteeLists}
          accessLevel={accessLevel}
          granteeType="Group"
          granteeId={grantee.id}
        />
      </SearchSelectedGroup>
    ))
  )
}

GroupGranteeList.propTypes = {
  category: categoryShape.isRequired,
  groups: types.arrayOf(types.object).isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  deleteAccessLevelGrant: types.func.isRequired,
}

export default GroupGranteeList
