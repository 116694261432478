import React from "react"
import { useSelector } from "react-redux"
import { getTasks } from "reduxSlices/formSubmissionSlice"
import TaskShortcut from "./TaskShortcut"

const TaskNav = () => {
  const tasks = useSelector(getTasks)

  return (
    <div>
      {
        tasks.map((task) => (
          <TaskShortcut key={task.id} task={task} />
        ))
      }
    </div>
  )
}

export default TaskNav
