import React from "react"
import types from "prop-types"
import { ChevronUp, ChevronDown } from "shared/icons"

const ExpandIconButton = ({ expanded }) => {
  const Chevron = expanded ? ChevronUp : ChevronDown

  return (
    <div className="rounded p-1 bg-lightgray cursor-pointer">
      <Chevron className="h-4 w-4" />
    </div>
  )
}

ExpandIconButton.defaultProps = {
  expanded: false,
}

ExpandIconButton.propTypes = {
  expanded: types.bool,
}

export default ExpandIconButton
