import React from "react"
import types from "prop-types"
import {
  commentCopy, formFillingRemindersCopy, formFillingSurveyDetailsCopy,
} from "utils/surveyTokenPublicDetailsHelpers"
import { surveyTokenPublicDetailsShape } from "utils/propTypeShapes"
import ContentBar from "shared/lists/ContentBar"

const SurveyDetails = ({ className, surveyDetails }) => {
  const { comment, reminderCCEmail, surveyBatchAnonymous } = surveyDetails

  const showComment = !surveyBatchAnonymous && comment
  const showReminderCCEmail = !surveyBatchAnonymous && reminderCCEmail

  return (
    <div className={className}>
      <ContentBar
        title="Details"
        description={formFillingSurveyDetailsCopy(surveyDetails)}
      />
      {
        showComment && (
          <ContentBar
            title="Additional Context"
            description={commentCopy(surveyDetails)}
          />
        )
      }
      {
        showReminderCCEmail && (
          <ContentBar
            title="Reminders"
            description={formFillingRemindersCopy(surveyDetails)}
          />
        )
      }
    </div>
  )
}

SurveyDetails.defaultProps = {
  className: "",
}

SurveyDetails.propTypes = {
  className: types.string,
  surveyDetails: surveyTokenPublicDetailsShape.isRequired,
}

export default SurveyDetails
