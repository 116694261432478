import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { getQuestionTag } from "reduxSlices/questionTagsSlice"
import { questionShape } from "utils/propTypeShapes"
import { questionTagAnswer } from "utils/formDataHelpers"
import {
  answerField,
  newSubmissionAnswerFieldClassName,
  editSubmissionAnswerFieldClassName,
} from "Forms/FormElementTypes"

const UnimplementedTypeAnswerField = ({ type }) => (
  `I am a ${type} answer field`
)

const QuestionAnswerField = ({
  question,
  formAnswers,
  disabled,
  edit,
}) => {
  const {
    attributes: { questionTagId },
    description,
    type,
    uuid,
  } = question

  const questionTag = useSelector(getQuestionTag({ questionTagId }))

  const TypeSpecificAnswerField = answerField({ questionType: type })

  // The initial value for the question's answer
  // should be the current answer entered in the form.
  // If there's no current answer, then we want to check
  // for an answer provided via question tags (FHIR data or query params).
  let initialValue = formAnswers[uuid]

  if (!initialValue && !Object.hasOwn(formAnswers, uuid) && questionTag) {
    initialValue = questionTagAnswer(questionTag.name)
  }

  const className = (
    edit
      ? editSubmissionAnswerFieldClassName({ questionType: type })
      : newSubmissionAnswerFieldClassName({ questionType: type })
  )

  const noteQuestionType = type === "note"

  return (
    TypeSpecificAnswerField
      ? (
        <TypeSpecificAnswerField
          className={clsx(className, "type-specific-answer-field")}
          question={question}
          formAnswers={formAnswers}
          hasBaseInputStyle={false}
          isFullWidth
          // pass a different saved data point (question.note || question.attributes.note) as initial value if it exists
          initialValue={noteQuestionType ? description : initialValue}
          disabled={disabled}
          edit={edit}
        />
      )
      : <UnimplementedTypeAnswerField type={type} />
  )
}

QuestionAnswerField.defaultProps = {
  disabled: false,
  edit: false,
}

QuestionAnswerField.propTypes = {
  question: questionShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formAnswers: types.object.isRequired,
  disabled: types.bool,
  edit: types.bool,
}

export default QuestionAnswerField
