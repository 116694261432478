import React from "react"
import types from "prop-types"
import clsx from "clsx"

const Wrap = ({ children = null, className = "" }) => (
  <div
    className={clsx(
      "mx-auto max-w-screen-xl w-11/12",
      className,
    )}
  >
    {children}
  </div>
)

Wrap.propTypes = {
  children: types.node,
  className: types.string,
}

export default Wrap
