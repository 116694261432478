import React from "react"
import { useSelector } from "react-redux"
import { getFormBuilderStateReady, getFormIsSubform } from "reduxSlices/formBuilderSlice"
import { HiddenTabs } from "shared/tabs"
import DesignTab from "./DesignTab"
import DesignControls from "./DesignTab/DesignControls"
import SubmissionAccessTab from "./SubmissionAccessTab"
import FormSettingsTab from "./FormSettingsTab"
import WorkflowsTab from "./WorkflowsTab"

const DESIGN_TAB = {
  title: "Design",
  TabContent: DesignTab,
  additionalControls: <DesignControls />,
}

const SUBMISSION_ACCESS_TAB = {
  title: "Submission Access",
  TabContent: SubmissionAccessTab,
  additionalControls: null,
}

const WORKFLOW_TAB = {
  title: "Workflow",
  TabContent: WorkflowsTab,
  additionalControls: null,
}

const FORM_SETTINGS_TAB = {
  title: "Form Settings",
  TabContent: FormSettingsTab,
  additionalControls: null,
}

const REGULAR_FORM_TABS = [
  DESIGN_TAB,
  SUBMISSION_ACCESS_TAB,
  WORKFLOW_TAB,
  FORM_SETTINGS_TAB,
]

const TASK_FORM_TABS = [
  DESIGN_TAB,
  WORKFLOW_TAB,
  FORM_SETTINGS_TAB,
]

const FormBuilderTabs = () => {
  const ready = useSelector(getFormBuilderStateReady)
  const formIsSubform = useSelector(getFormIsSubform)

  if (!ready) return null

  const tabs = formIsSubform ? TASK_FORM_TABS : REGULAR_FORM_TABS

  return (
    <div>
      <HiddenTabs className="fixed left-0 top-14" tabs={tabs} />
    </div>
  )
}

export default FormBuilderTabs
