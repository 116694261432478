/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getCategoryName, getCategorySlug, getFormSlug } from "reduxSlices/formBuilderSlice"
import * as API from "services/api"
import { MediumWrap } from "shared/wraps"
import { GroupAndUserSearch } from "shared/searches"
import StrikeAround from "shared/StrikeAround"
import GroupGranteeList from "./GroupGranteeList"
import UserGranteeList from "./UserGranteeList"

const SubmissionAccessTab = () => {
  const categoryName = useSelector(getCategoryName)
  const categorySlug = useSelector(getCategorySlug)
  const formSlug = useSelector(getFormSlug)

  const [groupGranteeList, setGroupGranteeList] = useState([])
  const [userGranteeList, setUserGranteeList] = useState([])
  const updateAccessGranteeLists = (data) => {
    setGroupGranteeList(data.groups)
    setUserGranteeList(data.users)
  }

  const fetchAccessGrantees = async () => {
    const response = await API.getFormAccessGrantList({ formSlug })
    if (response.ok) {
      updateAccessGranteeLists(response.data)
    } else {
      console.error("Error fetching form access grants: ", response)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchAccessGrantees() }, [categorySlug])

  const createAccessLevelGrant = async (value) => {
    const [grantee_type, grantee_id] = value.split("-")
    const access_level_grant = { grantee_id, grantee_type }

    const response = await API.manageFormAccessGrant({
      formSlug,
      access_level_grant,
    })
    if (response.ok) {
      updateAccessGranteeLists(response.data)
    } else {
      console.error("Error creating access level grant", response)
    }
  }

  const deleteFormGrant = (grantId) => async () => {
    const response = await API.deleteFormAccessGrant({ formSlug, grantId })
    if (response.ok) {
      updateAccessGranteeLists(response.data)
    } else {
      console.error("Error deleting form grant: ", response);
    }
  }

  return (
    <MediumWrap className="mt-32">
      <div className="text-center">
        <h1 className="py-3 header-title">Submission Access</h1>
        <h4>
          {
            `This form belongs to the ${categoryName} Solution. You can add a member or change
             their access levels here and it won't affect other forms in the other solutions.`
          }
        </h4>
      </div>
      <div className="my-6 w-4/5 mx-auto">
        <GroupAndUserSearch
          onSearchSelected={createAccessLevelGrant}
          selectedGroups={groupGranteeList}
          selectedUsers={userGranteeList}
        />
      </div>
      <StrikeAround>
        <span className="my-3 text-xl font-medium">Groups</span>
      </StrikeAround>
      <GroupGranteeList
        formSlug={formSlug}
        groups={groupGranteeList}
        deleteFormGrant={deleteFormGrant}
        updateAccessGranteeLists={updateAccessGranteeLists}
      />
      <StrikeAround>
        <span className="my-3 text-xl font-medium">Individuals</span>
      </StrikeAround>
      <UserGranteeList
        formSlug={formSlug}
        users={userGranteeList}
        deleteFormGrant={deleteFormGrant}
        updateAccessGranteeLists={updateAccessGranteeLists}
      />
    </MediumWrap>
  )
}

export default SubmissionAccessTab
