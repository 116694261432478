import React from "react"
import { useSelector } from "react-redux"
import { facilityName } from "reduxSlices/adminFacilitySlice"
import { ADMIN_FACILITIES_PATH } from "utils/routeHelpers"

const FacilityAdminHeader = () => {
  const name = useSelector(facilityName)

  return (
    <div className="admin-header">
      <a className="header-link" href={ADMIN_FACILITIES_PATH}>Facilities</a>
      <div className="header-title">{name}</div>
    </div>
  )
}

export default FacilityAdminHeader
