import React from "react"
import types from "prop-types"

const EMPTY_ANSWER = []

const MultiSelectAnswer = ({ rawAnswer, isNewQuestion }) => {
  if (isNewQuestion) return null

  return (
    <span className="font-medium text-lg bg-lightgray py-1.5 px-3 w-1/2">
      { rawAnswer.join(", ") }
    </span>
  )
}

MultiSelectAnswer.defaultProps = {
  rawAnswer: EMPTY_ANSWER,
  isNewQuestion: false,
}

MultiSelectAnswer.propTypes = {
  rawAnswer: types.arrayOf(
    types.oneOfType([types.string, types.number]),
  ),
  isNewQuestion: types.bool,
}

export default MultiSelectAnswer
