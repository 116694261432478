import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { SidebarVisibilityControl } from "shared/Sidebar"
import OptionsSidebar from "./OptionsSidebar"

const AuditsHeader = ({ hiddenSidebar, toggleSidebar }) => (
  <>
    <OptionsSidebar
      hiddenSidebar={hiddenSidebar}
    />
    <div className={clsx("flex-center relative", !hiddenSidebar && "ml-80")}>
      <div className="flex justify-start items-center">
        <SidebarVisibilityControl
          hiddenSidebar={hiddenSidebar}
          toggleSidebar={toggleSidebar}
        />
      </div>
      <h1 className="header-title tracking-tight my-0 absolute top-0 left-1/2 transform -translate-x-1/2">
        Audit Trail
      </h1>
    </div>
  </>
)

AuditsHeader.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default AuditsHeader
