import React, { Component, createRef } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import { connect } from "react-redux"
import { taskShape } from "utils/propTypeShapes"
import * as API from "services/api"
import { updateTask } from "reduxSlices/formSubmissionSlice"
import { TaskFormContextProvider } from "contexts/TaskFormContext"
import { RemoveButton } from "shared/buttons"
import TaskTokenTitle from "../ActivitiesMain/Task/TaskTokenTitle"
import { CancelButton, SaveTaskButton } from "./TaskButtons"
import * as TaskFields from "./TaskFields"

class EditTaskForm extends Component {
  state = { canSubmit: false }

  formRef = createRef()

  enableSubmit = () => this.setState({ canSubmit: true })

  disableSubmit = () => this.setState({ canSubmit: false })

  currentTaskData = () => this.formRef.current?.getModel() || { task: {} }

  cancel = () => {
    const { onCancel } = this.props

    this.formRef.current?.reset()
    if (onCancel) onCancel()
  }

  saveTask = async (model) => {
    const { canSubmit } = this.state

    if (!canSubmit) return

    const {
      formSubmissionSlug,
      onSave,
      updateTask: updateSavedTask,
      task: { id: taskId },
    } = this.props

    const response = await API.updateTask({ formSubmissionSlug, taskId, taskData: model })

    if (!response.ok) {
      console.error("ERROR UPDATING TASK: ", response.data)
      return
    }

    const { data: updatedTasks } = response

    updatedTasks.forEach((task) => updateSavedTask(task))
    this.formRef.current?.reset()
    if (onSave) onSave()
  }

  render() {
    const { task, taskFormIsOpen } = this.props
    const { subform, title } = task

    if (!taskFormIsOpen) return null

    return (
      <TaskFormContextProvider task={task}>
        <div
          className="mb-6 grid"
          style={{ gridTemplateColumns: "30px 100%" }}
        >
          <div />
          <div className="light-shadow rounded-lg border border-lightgray-2 bg-white py-6 px-8">
            <div className="flex-center mb-4">
              <TaskTokenTitle subform={subform} title={title} />
              <h3>Edit Task</h3>
              <RemoveButton onClick={this.cancel} />
            </div>
            <Formsy
              id="task-form"
              onValidSubmit={this.saveTask}
              onValid={this.enableSubmit}
              onInvalid={this.disableSubmit}
              ref={this.formRef}
            >
              <TaskFields.Title className="mb-8" />
              <TaskFields.Description className="mb-8" />
              <TaskFields.DueDate className="mb-8" />
              <TaskFields.NotifyGroupIds />
              <TaskFields.NotifyOnCompleteGroupIds />
              <TaskFields.NotifyIds />
              <TaskFields.NotifyOnCompleteIds />
              <TaskFields.Assignees className="mb-8" />
              <TaskFields.Members className="mb-8" />
              <TaskFields.OtherTaskAccess className="mb-8" />
              <div className="flex flex-col items-center mb-8">
                <div>
                  <CancelButton cancel={this.cancel} />
                  <SaveTaskButton text="Save task" />
                </div>
              </div>
            </Formsy>
          </div>
        </div>
      </TaskFormContextProvider>
    )
  }
}

EditTaskForm.defaultProps = {
  onCancel: undefined,
  onSave: undefined,
}

EditTaskForm.propTypes = {
  formSubmissionSlug: types.string.isRequired,
  onCancel: types.func,
  onSave: types.func,
  task: taskShape.isRequired,
  taskFormIsOpen: types.bool.isRequired,
  updateTask: types.func.isRequired,
}

const mapStateToProps = (state) => (
  {
    formSubmissionSlug: state.formSubmission.data.slug,
  }
)

export default connect(mapStateToProps, { updateTask })(EditTaskForm)
