/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react"
import clsx from "clsx"
import types from "prop-types"

const Tabs = ({ className = "", initialActiveIndex = 0, children }) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex)

  const tabRef = useRef([])

  const { props: { children: activeTabContent } } = children[activeIndex] || children[0]

  useEffect(() => {
    setActiveIndex(initialActiveIndex)
  }, [initialActiveIndex])

  return (
    <div>
      <ol className={clsx(className, "tabs")}>
        {
          children.map(({ props }, index) => {
            const { title, NotificationBadge } = props

            return (
              <li
                key={title}
                ref={(el) => {
                  tabRef.current[index] = el
                }}
                onClickCapture={() => setActiveIndex(index)}
                className={clsx((index === activeIndex) && "active", "tab-title-token", "items-center")}
              >
                <span className={clsx(NotificationBadge && "mr-2")}>{ title }</span>
                {NotificationBadge && <NotificationBadge />}
              </li>
            )
          })
        }
      </ol>
      { activeTabContent }
    </div>
  )
}

Tabs.propTypes = {
  initialActiveIndex: types.number,
  className: types.string,
  children: types.node.isRequired,
}

export default Tabs
