import React from "react"
import types from "prop-types"
import AttachmentCard from "./AttachmentCard"

const style = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 3fr))",
  gridGap: "40px",
}

const Attachments = ({ attachments }) => (
  <div style={style}>
    {
      attachments.map((attachment) => (
        <AttachmentCard key={attachment.id} attachment={attachment} />
      ))
    }
  </div>
)

Attachments.propTypes = {
  attachments: types.arrayOf(types.object).isRequired,
}

export default Attachments
